import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  currencyOperation,
  currencyName,
  getSystemSettings,
  modalLoading,
  currencyNameSecondary,
  currencyOperationSecondary,
  currencySymbolLeftSecondary,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import "moment/locale/es-us";
import { useReactToPrint } from "react-to-print";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { FoodContext } from "../../../../contexts/Food";
import Calculator from "./calc/Calculator";

const Pos = () => {
  //getting context values here
  const history = useHistory();
  const {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);
  const {
    authUserInfo,
    //customer
    customerForSearch,
    setCustomerForSearch,
    //waiter
    waiterForSearch,
  } = useContext(UserContext);

  const {
    getcaja,
    foodForSearch,
    //food group
    foodGroupForSearch,
    cajadetalle,
    //property group
    propertyGroupForSearch,
  } = useContext(FoodContext);

  const {
    //work period
    workPeriodForSearch,
    //branch
    branchForSearch,
    //table
    tableForSearch,
    //dept-tag
    deptTagForSearch,
    //payment-type
    paymentTypeForSearch,
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // State hooks here
  const [foodItem, setFoodItem] = useState({
    foodGroup: null,
    items: null,
    selectedItem: null,
    variations: null,
    properties: null,
  });
  const [exisnewitems, newitems] = useState(false);
  const [barcocina, SetBarCocina] = useState({
    bar: false,
    cocina: false
  });
  //calculator
  const [calculatorMenu, setCalculatorMenu] = useState(false);
  const [updateproduct, setupdateproduct] = useState(true);
  const [btnupdatesave, btnupdatsav] = useState("create");
  //new order
  const [newOrder, setNewOrder] = useState(null);
  //active index of order list
  const [activeItemInOrder, setActiveItemInOrder] = useState(null);
  //checked variations
  const [selectedVariation, setSelectedVariation] = useState([]);
  //selected groups
  const [selectedPropertyGroup, setSelectedPropertyGroup] = useState([]);
  //checked properties
  const [selectedProperties, setSelectedProperties] = useState([]);

  //the sub total
  const [theSubTotal, setTheSubTotal] = useState(0);
  //total payable
  const [totalPayable, setTotalPaybale] = useState(0);
  // paidMoney
  const [paidMoney, setPaidMoney] = useState(0);
  //return
  const [returnMoneyUsd, setReturnMoneyUsd] = useState(0);

  //vat
  const [theVat, setTheVat] = useState(0);

  //vat settings
  const [newSettings, setNewSettings] = useState(null);

  //order details users
  const [orderDetailUsers, setOrderDetailusers] = useState({
    theCustomers: null,
    theTables: null,
    theWaiters: null,
  });
  const [orderspos, setorders] = useState({
    orderspos: null
  });

  //order details
  const [orderDetails, setOrderDetails] = useState({
    branch: null,
    customer: null,
    table: null,
    waiter: null,
    dept_tag: null,
    payment_type: null,
    payment_amount: null,
    total_guest: 1,
    newCustomer: false,
    newCustomerInfo: {
      name: "",
      number: "",
      address: "",
    },
    token: null,
    serviceCharge: 0,
    discount: 0,
    propina_: generalSettings && getSystemSettings(generalSettings, "propina"),
    propina_value: 0,
  });

  const [checkOrderDetails, setCheckOrderDetails] = useState({
    item: null,
    settle: false,
    uploading: false,
    payment_type: null,
    payment_amount: null,
  });

  //useEffect- to get data on render
  useEffect(() => {
    getcaja();
    if (cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined) {
      toast.error(
        `${_t(
          t("Aun no se a abierto caja")
        )}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
      history.replace("/dashboard");
    } else {
      getOrders();
      if (foodGroupForSearch) {
        let tempItems =
          foodForSearch &&
          foodForSearch.filter((tempItem) => {
            return parseInt(tempItem.food_group_id) === foodGroupForSearch[0].id;
          });
        if (tempItems && tempItems.length > 0) {
          // initial group & item being active here, variations, properties
          setFoodItem({
            foodGroup: foodGroupForSearch[0],
            items: tempItems,
            selectedItem: tempItems[0],
            variations:
              parseInt(tempItems[0].has_variation) === 1
                ? tempItems[0].variations
                : null,
            properties:
              parseInt(tempItems[0].has_property) === 1
                ? tempItems[0].properties
                : null,
          });
        }
      }

      if (authUserInfo.details) {
        let theBranch =
          branchForSearch &&
          branchForSearch.find((branch) => {
            return parseInt(authUserInfo.details.branch_id) === branch.id;
          });
        setOrderDetails({
          ...orderDetails,
          branch: theBranch !== undefined ? theBranch : null,
        });

        //set work period
        let theWorkPeriod = null;
        if (theBranch !== undefined && theBranch !== null) {
          theWorkPeriod =
            workPeriodForSearch &&
            workPeriodForSearch.filter((tempWorkPeriod) => {
              return theBranch.id === parseInt(tempWorkPeriod.branch_id);
            });

          //filter it with ended_at === null;
          theWorkPeriod =
            theWorkPeriod &&
            theWorkPeriod.find((endAtNullItem) => {
              return endAtNullItem.ended_at === null;
            });
        }
        //set work period here
        setNewSettings({
          ...newSettings,
          vat: generalSettings && getSystemSettings(generalSettings, "type_vat"),
          workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
        });
      } else {
        // user type admin
        setNewSettings({
          ...newSettings,
          vat: generalSettings && getSystemSettings(generalSettings, "type_vat"),
        });
      }
      setOrderDetailusers({
        theCustomers: customerForSearch ? customerForSearch : null,
        theTables: tableForSearch ? tableForSearch : null,
        theWaiters: waiterForSearch ? waiterForSearch : null,
      });
    }
  }, [
    authUserInfo,
    workPeriodForSearch,
    customerForSearch,
    tableForSearch,
    waiterForSearch,
    generalSettings,
    foodGroupForSearch,
    foodForSearch,
  ]);

  //add new item to order list
  const handleOrderItem = (tempFoodItem) => {
    let oldOrderItems = [];
    let newOrderItem = null;
    let tempSelectedVariations = [];
    if (newOrder) {
      newOrder.map((eachOldOrderItem) => {
        //push all old items to new array to setNewOrder
        oldOrderItems.push(eachOldOrderItem);

        //set selected variations of each order item
        let tempArray = [];
        if (eachOldOrderItem.variation !== null) {
          tempArray.push(eachOldOrderItem.variation.food_with_variation_id);
        } else {
          tempArray.push(null);
        }
        tempSelectedVariations.push(tempArray);
      });
      //add new order item
      newOrderItem = {
        item: tempFoodItem,
        variation:
          parseInt(tempFoodItem.has_variation) === 1
            ? tempFoodItem.variations[0]
            : null,
        quantity: 1,
      };
      //set selected variations of new item
      let tempArray = [];
      if (parseInt(tempFoodItem.has_variation) === 1) {
        tempArray.push(tempFoodItem.variations[0].food_with_variation_id);
      } else {
        tempArray.push(null);
      }
      tempSelectedVariations.push(tempArray);
      //push new item to new array to setNewOrder
      oldOrderItems.push(newOrderItem);
    } else {
      //if no item in newOrder List
      setOrderDetails({
        //set token here on first order item add,
        ...orderDetails,
        token: {
          time: new Date().getTime(),
          id: Math.floor(1000 + Math.random() * 9000),
        },
      });
      newOrderItem = {
        //add new order item
        item: tempFoodItem,
        variation:
          parseInt(tempFoodItem.has_variation) === 1
            ? tempFoodItem.variations[0]
            : null,
        quantity: 1,
      };

      //set selected variations of new item
      let tempArray = [];
      if (parseInt(tempFoodItem.has_variation) === 1) {
        tempArray.push(tempFoodItem.variations[0].food_with_variation_id);
      } else {
        tempArray.push(null);
      }
      tempSelectedVariations.push(tempArray);

      //push new item to new array to setNewOrder
      oldOrderItems.push(newOrderItem);
    }

    //set new order list with new array of all order items
    setNewOrder(oldOrderItems);

    //set order list active item index to add class "actve"
    setActiveItemInOrder(oldOrderItems.length - 1);

    //set selected variations
    setSelectedVariation(tempSelectedVariations);

    //calculate subTotalPrice
    totalPrice(oldOrderItems);

    //VERIFICAR SI HAY NUEVOS PARA COMANDA
    let count_existe_id_item = 0
    oldOrderItems.map((eachOldOrderItem) => {
      if (eachOldOrderItem.item_order_id === undefined) {
        count_existe_id_item++;
      }
    });
    if (count_existe_id_item == oldOrderItems.length) {
      newitems(false);
    } else {
      newitems(true);
    }
    let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
    let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
    SetBarCocina({
      ...barcocina,
      bar: filter_bar && filter_bar.length > 0 ? true : false,
      cocina: filter_cocina && filter_cocina.length > 0 ? true : false
    });
  };

  //set order item's variation on change of variation
  const handleOrderItemVariation = (tempFoodItemVariation) => {
    if (activeItemInOrder !== null) {
      if (newOrder) {
        let oldOrderItems = []; //array to push order items
        let newOrderItemTemp = null; //to edit selected item
        let tempSelectedVariations = []; //to set selected variations array for order items
        newOrder.map((newOrderItem, index) => {
          let tempArray = [];
          if (index === activeItemInOrder) {
            //changing variation of selected food item
            newOrderItemTemp = {
              ...newOrderItem,
              variation: tempFoodItemVariation,
            };
            //push updated item to orderlist
            oldOrderItems.push(newOrderItemTemp);

            //set updated variation for selected variation
            tempArray.push(tempFoodItemVariation.food_with_variation_id);
          } else {
            //set other items as it was which are not selected to edit
            newOrderItemTemp = newOrderItem;
            oldOrderItems.push(newOrderItemTemp);
            if (newOrderItemTemp.variation) {
              //set updated variation for selected variations
              tempArray.push(newOrderItemTemp.variation.food_with_variation_id);
            }
          }

          //push to the array to set selectedVariations
          tempSelectedVariations.push(tempArray);
        });
        //set variations here
        setSelectedVariation(tempSelectedVariations);

        //set updated order list
        setNewOrder(oldOrderItems);

        //calculate subTotalPrice
        totalPrice(oldOrderItems);
        let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
        let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
        SetBarCocina({
          ...barcocina,
          bar: filter_bar && filter_bar.length > 0 ? true : false,
          cocina: filter_cocina && filter_cocina.length > 0 ? true : false
        });
      }
    }
  };

  //to check which variation is selected
  const checkChecked = (variationItem) => {
    //if variationItem.food_with_variation_id of selected item exist in selectedVariation - return true
    if (selectedVariation[activeItemInOrder] !== undefined) {
      if (
        selectedVariation[activeItemInOrder][0] ===
        variationItem.food_with_variation_id
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //delete confirmation modal of item from order list
  const handleDeleteConfirmation = (removeIndex) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body">
            <h1>{_t(t("Are you sure?"))}</h1>
            <p className="text-center">{_t(t("You want to delete this?"))}</p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleRemoveItemFromOrderList(removeIndex);
                  onClose();
                }}
              >
                {_t(t("Yes, delete it!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //handle order item quantity
  const handleOrderItemQty = (action) => {
    if (activeItemInOrder !== null) {
      if (newOrder) {
        let newOrderItemTemp = null; //to edit selected item
        let oldOrderItems = []; //array to push order items
        newOrder.map((newOrderItem, index) => {
          if (index === activeItemInOrder) {
            //update qty here
            if (action === "+") {
              //increase qty
              newOrderItemTemp = {
                ...newOrderItem,
                quantity: newOrderItem.quantity + 1,
              };
            } else {
              //if qty !==1 decrease
              if (newOrderItem.quantity !== 1) {
                newOrderItemTemp = {
                  ...newOrderItem,
                  quantity: newOrderItem.quantity - 1,
                };
              } else {
                newOrderItemTemp = {
                  ...newOrderItem,
                  quantity: newOrderItem.quantity,
                };
              }
            }
            oldOrderItems.push(newOrderItemTemp);
          } else {
            // set other items as it was which are not selected to edit
            oldOrderItems.push(newOrderItem);
          }
        });

        //calculate subTotalPrice
        totalPrice(oldOrderItems);
        //set updated order list
        setNewOrder(oldOrderItems);
        let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
        let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
        SetBarCocina({
          ...barcocina,
          bar: filter_bar && filter_bar.length > 0 ? true : false,
          cocina: filter_cocina && filter_cocina.length > 0 ? true : false
        });
      }
    }
  };

  //cancel order confirmation
  const handleCancelConfirmation = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-primary text-white">
            <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("You want to cancel this order?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark"
                onClick={() => {
                  handleCancel();
                  onClose();
                }}
              >
                {_t(t("Yes!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  //cancel order
  const handleCancel = () => {
    SetBarCocina({
      bar: false,
      cocina: false
    });
    newitems(false);
    btnupdatsav("create");
    setLoading(true);
    setNewOrder(null);
    setActiveItemInOrder(null);
    setSelectedVariation([]);
    setSelectedPropertyGroup([]);
    setSelectedProperties([]);
    setTheSubTotal(0);
    setTheVat(0);
    setTotalPaybale(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    setOrderDetails({
      branch: orderDetails.branch,
      customer: null,
      table: null,
      waiter: null,
      dept_tag: null,
      payment_type: null,
      payment_amount: null,
      total_guest: 1,
      newCustomer: false,
      newCustomerInfo: {
        name: "",
        number: "",
        address: "",
      },
      token: null,
      serviceCharge: 0,
      discount: 0,
      propina_: generalSettings && getSystemSettings(generalSettings, "propina"),
      propina_value: 0,
    });
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  //add properties
  const handleAddProperties = (property) => {
    let propertyItem = {
      item: property,
      quantity: 1,
    };
    if (activeItemInOrder !== null) {
      if (newOrder) {
        let oldOrderItems = []; //array to push order items
        let newOrderItemTemp = null; //to edit selected item

        let tempSelectedProperties = []; //to set selected properties array for order items
        let tempSelectedPropertyGroup = []; //to set selected property group array for order items properties

        newOrder.map((newOrderItem, index) => {
          let tempArray = []; //for selected properties
          let tempArrayPropertyGroup = []; //for selected property groups

          if (index === activeItemInOrder) {
            let tempPropertyArray = []; //for property items
            if (newOrderItem.properties) {
              newOrderItem.properties.map((eachPropertyItem) => {
                tempPropertyArray.push(eachPropertyItem);
                //set updated property for selected variation
                tempArray.push(eachPropertyItem.item.id);

                //handle selected property group
                tempArrayPropertyGroup.push(
                  parseInt(eachPropertyItem.item.property_group_id)
                );
              });
              tempPropertyArray.push(propertyItem);
              //for selected properties
              tempArray.push(propertyItem.item.id);
              //handle selected property group
              tempArrayPropertyGroup.push(
                parseInt(propertyItem.item.property_group_id)
              );
            } else {
              tempPropertyArray.push(propertyItem);
              //for selected properties
              tempArray.push(propertyItem.item.id);
              //handle selected property group
              tempArrayPropertyGroup.push(
                parseInt(propertyItem.item.property_group_id)
              );
            }

            //changing properties of selected food item
            newOrderItemTemp = {
              ...newOrderItem,
              properties: tempPropertyArray,
            };
            //push updated item to orderlist
            oldOrderItems.push(newOrderItemTemp);
          } else {
            // set other items as it was which are not selected to edit
            newOrderItemTemp = newOrderItem;
            oldOrderItems.push(newOrderItemTemp);
            if (newOrderItem.properties) {
              newOrderItem.properties.map((eachPropertyItem) => {
                //set updated properties for selected properties
                tempArray.push(eachPropertyItem.item.id);

                //set updated property groups for selected groups
                tempArrayPropertyGroup.push(
                  parseInt(eachPropertyItem.item.property_group_id)
                );
              });
            }
          }
          //push to the array to set selected properties
          tempSelectedProperties.push(tempArray);
          //push to the array to set selected property groups
          tempSelectedPropertyGroup.push(tempArrayPropertyGroup);
        });

        //set selected properties here
        setSelectedProperties(tempSelectedProperties);

        //handle selected property groups
        let newSelectedPropertyGroup = [];
        tempSelectedPropertyGroup.map((eachSelectedGroupArray) => {
          let unique = [...new Set(eachSelectedGroupArray)];
          newSelectedPropertyGroup.push(unique);
        });
        //set selected property groups here
        setSelectedPropertyGroup(newSelectedPropertyGroup);

        //calculate subTotalPrice
        totalPrice(oldOrderItems);
        //set updated order list
        setNewOrder(oldOrderItems);
        let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
        let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
        SetBarCocina({
          ...barcocina,
          bar: filter_bar && filter_bar.length > 0 ? true : false,
          cocina: filter_cocina && filter_cocina.length > 0 ? true : false
        });
      }
    }
  };

  //remove properties
  const handleRemoveProperties = (propertyItem) => {
    if (activeItemInOrder !== null) {
      if (newOrder) {
        let oldOrderItems = []; //array to push order items
        let newOrderItemTemp = null; //to edit selected item
        let newSelectedProperties = []; //for selected properties
        let allPropertyGroups = []; //for selected property groups

        newOrder.map((newOrderItem, index) => {
          if (index === activeItemInOrder) {
            let tempPropertyArray = []; //for property items
            if (newOrderItem.properties) {
              newOrderItem.properties.map((eachPropertyItem) => {
                if (eachPropertyItem.item.id !== propertyItem.id) {
                  tempPropertyArray.push(eachPropertyItem);
                }
              });

              //removing properties from selected properties array
              selectedProperties.map((selectedProperty, propertyIndex) => {
                if (propertyIndex !== activeItemInOrder) {
                  //pushing other order items properties in selected array
                  newSelectedProperties.push(selectedProperty);
                } else {
                  let theProperties = selectedProperty.filter((filterThis) => {
                    return filterThis !== propertyItem.id;
                  });
                  //pushing this order items properties in selected array or [] if not a single property item is selected
                  newSelectedProperties.push(theProperties);
                }
              });
            }

            //changing properties of selected food item
            newOrderItemTemp = {
              ...newOrderItem,
              properties: tempPropertyArray,
            };

            //set selected properties here
            setSelectedProperties(newSelectedProperties);
            //push updated item to orderlist
            oldOrderItems.push(newOrderItemTemp);

            //property group for selected property group array
            selectedPropertyGroup.map((groupItem, groupItemIndex) => {
              if (index === groupItemIndex) {
                let tempGroupArray = []; // for selected group of each order item (index of array wise new array)
                groupItem.map((filterThisItem) => {
                  if (
                    filterThisItem !== parseInt(propertyItem.property_group_id)
                  ) {
                    //push if removable property item's group !== groupItem
                    tempGroupArray.push(filterThisItem);
                  }
                });

                //push all groups of modified new properties array of this item
                tempPropertyArray.map((pushThis) => {
                  tempGroupArray.push(
                    parseInt(pushThis.item.property_group_id)
                  );
                });

                //remove duplicate groups
                let unique = [...new Set(tempGroupArray)];
                allPropertyGroups.push(unique);
              } else {
                //keep other selected groups as it was
                allPropertyGroups.push(groupItem);
              }
            });
          } else {
            // set other items as it was which are not selected to edit
            newOrderItemTemp = newOrderItem;
            oldOrderItems.push(newOrderItemTemp);
          }
        });

        //set new selected property groups
        setSelectedPropertyGroup(allPropertyGroups);

        //calculate subTotalPrice
        totalPrice(oldOrderItems);

        //set updated order list
        setNewOrder(oldOrderItems);
        let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
        let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
        SetBarCocina({
          ...barcocina,
          bar: filter_bar && filter_bar.length > 0 ? true : false,
          cocina: filter_cocina && filter_cocina.length > 0 ? true : false
        });
      }
    }
  };

  //to check which property is selected
  const checkCheckedProperties = (propertyItem) => {
    //if property.id of selected item exist in selected properties - return true
    if (selectedProperties[activeItemInOrder] !== undefined) {
      if (selectedProperties[activeItemInOrder].includes(propertyItem.id)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  //handle property quantity
  const handlePropertyQty = (propertyItem, action) => {
    if (activeItemInOrder !== null) {
      if (newOrder) {
        let newOrderItemTemp = null; //to edit selected item
        let oldOrderItems = []; //array to push order items
        let tempPropertyItemsArray = []; //for each property items
        newOrder.map((newOrderItem, index) => {
          if (index === activeItemInOrder) {
            //if has property items
            if (newOrderItem.properties) {
              newOrderItem.properties.map((eachPropertyItem) => {
                //loop through the properties to change the specific one's quantity and keep the rest as it was
                if (eachPropertyItem.item.id !== propertyItem.id) {
                  //keep others as it was
                  tempPropertyItemsArray.push(eachPropertyItem);
                } else {
                  //coming here if the specific item's id === selected property item's id
                  let newPropertyItemForQtyUpdate = null;
                  //update qty here
                  if (action === "+") {
                    //increase qty
                    newPropertyItemForQtyUpdate = {
                      ...eachPropertyItem,
                      quantity: eachPropertyItem.quantity + 1,
                    };
                    tempPropertyItemsArray.push(newPropertyItemForQtyUpdate);
                  } else {
                    //if qty !==1 decrease
                    if (eachPropertyItem.quantity !== 1) {
                      newPropertyItemForQtyUpdate = {
                        ...eachPropertyItem,
                        quantity: eachPropertyItem.quantity - 1,
                      };
                      tempPropertyItemsArray.push(newPropertyItemForQtyUpdate);
                    } else {
                      tempPropertyItemsArray.push(eachPropertyItem);
                    }
                  }
                }
              });
              //changing properties of selected food item
              newOrderItemTemp = {
                ...newOrderItem,
                properties: tempPropertyItemsArray,
              };
              //push updated item to orderlist
              oldOrderItems.push(newOrderItemTemp);
            } else {
              //push updated item to orderlist
              oldOrderItems.push(newOrderItem);
            }
          } else {
            // set other items as it was which are not selected to edit
            oldOrderItems.push(newOrderItem);
          }
        });

        //calculate subTotalPrice
        totalPrice(oldOrderItems);
        //set updated order list
        setNewOrder(oldOrderItems);
        let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
        let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
        SetBarCocina({
          ...barcocina,
          bar: filter_bar && filter_bar.length > 0 ? true : false,
          cocina: filter_cocina && filter_cocina.length > 0 ? true : false
        });
      }
    }
  };

  //to check selected property item's quantity
  const checkCheckedPropertyQuantity = (propertyItem) => {
    //if propertyItem.id of selected item exist in selectedProperties - return the quantity of that property item from the order list item
    if (selectedProperties[activeItemInOrder] !== undefined) {
      if (selectedProperties[activeItemInOrder].includes(propertyItem.id)) {
        //get order of activeItemInOrder index
        if (newOrder) {
          if (newOrder[activeItemInOrder] !== undefined) {
            let orderItem = newOrder[activeItemInOrder];
            let theItem = orderItem.properties.find((theItemTemp) => {
              return theItemTemp.item.id === propertyItem.id;
            });
            return theItem.quantity;
          } else {
            return 1;
          }
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    } else {
      return 1;
    }
  };

  //show price of each item in order list
  const showPriceOfEachOrderItem = (itemIndex) => {
    if (newOrder) {
      let price = 0;
      let orderItem = newOrder[itemIndex];
      //check price * quantity (variation price / item price)
      if (parseInt(orderItem.item.has_variation) === 1) {
        price = parseFloat(orderItem.variation.food_with_variation_price);
      } else {
        price = parseFloat(orderItem.item.price);
      }

      //calculate total price of properties
      if (orderItem.properties) {
        if (orderItem.properties.length > 0) {
          orderItem.properties.map((getEachItemPrice) => {
            let totalPropertyPrice =
              parseFloat(getEachItemPrice.item.extra_price) *
              getEachItemPrice.quantity;
            price = price + totalPropertyPrice;
          });
        }
      }

      let formattedPrice = formatPrice(price * orderItem.quantity);
      return formattedPrice;
    }
  };

  //show total price
  const totalPrice = (allOrderItems) => {
    let subTotal = 0;
    allOrderItems.map((orderItem) => {
      let price = 0;
      //check price * quantity (variation price / item price)
      if (parseInt(orderItem.item.has_variation) === 1) {
        price = parseFloat(orderItem.variation.food_with_variation_price);
      } else {
        price = parseFloat(orderItem.item.price);
      }
      //calculate total price of properties
      if (orderItem.properties) {
        if (orderItem.properties.length > 0) {
          orderItem.properties.map((getEachItemPrice) => {
            let totalPropertyPrice =
              parseFloat(getEachItemPrice.item.extra_price) *
              getEachItemPrice.quantity;
            price = price + totalPropertyPrice;
          });
        }
      }
      subTotal = subTotal + price * orderItem.quantity;
    });

    setTheSubTotal(subTotal);

    let tempVat = 0;
    if (newSettings) {
      tempVat = (subTotal * parseFloat(newSettings.vat)) / 100;
      setTheVat(tempVat);
    }

    let totalPayable = 0;
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let usdServiceCharge =
      parseFloat(orderDetails.serviceCharge) / localCurrency.rate;
    let usdDiscount = parseFloat(orderDetails.discount) / localCurrency.rate;
    //let propina_value = parseFloat(orderDetails.propina_);


    let calpro = 0;
    if (parseFloat(orderDetails.propina_) != Infinity) {
      calpro = ((parseFloat(orderDetails.propina_) / 100) * subTotal).toFixed(2);
    }

    orderDetails.propina_value = calpro;

    totalPayable = subTotal + tempVat + parseFloat(calpro) + usdServiceCharge - usdDiscount;

    setTotalPaybale(totalPayable);

    //calculate paid amount to set return amount
    handleCalculatePaid(orderDetails.payment_amount, orderDetails.payment_type);
  };

  //search food here
  const handleSearch = (e) => {
    setActiveItemInOrder(null);
    let searchInput = e.target.value.toLowerCase();
    let searchedList = foodForSearch.filter((item) => {
      let lowerCaseItemName = item.name.toLowerCase();
      let lowerCaseItemGroup = item.food_group.toLowerCase();
      return (
        lowerCaseItemName.includes(searchInput) ||
        lowerCaseItemGroup.includes(searchInput)
      );
    });
    setFoodItem({
      foodGroup: null,
      items: searchedList.length > 0 ? searchedList : null,
      selectedItem: null,
      variations: null,
      properties: null,
    });
  };

  //set order detals additional info here
  //set branch
  const handleSetBranch = (branch) => {
    setLoading(true);
    let tempCustomers =
      customerForSearch !== null &&
      customerForSearch.filter((eachCustomer) => {
        return parseInt(eachCustomer.branch_id) === branch.id;
      });
    let tempTables =
      tableForSearch !== null &&
      tableForSearch.filter((eachTable) => {
        return parseInt(eachTable.branch_id) === branch.id;
      });
    let tempWaiters =
      waiterForSearch !== null &&
      waiterForSearch.filter((eachWaiter) => {
        return parseInt(eachWaiter.branch_id) === branch.id;
      });
    setOrderDetailusers({
      theCustomers: tempCustomers,
      theTables: tempTables,
      theWaiters: tempWaiters,
    });

    setNewOrder(null);
    setActiveItemInOrder(null);
    setSelectedVariation([]);
    setSelectedPropertyGroup([]);
    setSelectedProperties([]);
    setTheSubTotal(0);
    setTheVat(0);
    setTotalPaybale(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    setOrderDetails({
      branch: branch,
      customer: null,
      table: null,
      waiter: null,
      dept_tag: null,
      payment_type: null,
      payment_amount: null,
      total_guest: 1,
      newCustomer: false,
      newCustomerInfo: {
        name: "",
        number: "",
        address: "",
      },
      token: null,
      serviceCharge: 0,
      discount: 0,
    });
    // set work period according to branch for admin on branch change
    let theWorkPeriod = null;
    theWorkPeriod =
      workPeriodForSearch &&
      workPeriodForSearch.filter((tempWorkPeriod) => {
        return branch.id === parseInt(tempWorkPeriod.branch_id);
      });

    theWorkPeriod = theWorkPeriod.find((endAtNullItem) => {
      return endAtNullItem.ended_at === null;
    });
    setNewSettings({
      ...newSettings,
      workPeriod: theWorkPeriod !== undefined ? theWorkPeriod : null,
      vat: newSettings.vat,
    });

    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  //customer
  const handleSetCustomer = (customer) => {
    setOrderDetails({
      ...orderDetails,
      customer,
    });
  };

  //new Customer
  const handleNewCustomer = (e) => {
    //if name is not there, set new customer === false
    if (e.target.name === "name") {
      if (e.target.value.length > 0) {
        setOrderDetails({
          ...orderDetails,
          newCustomer: true,
          newCustomerInfo: {
            ...orderDetails.newCustomerInfo,
            [e.target.name]: e.target.value,
          },
        });
      } else {
        setOrderDetails({
          ...orderDetails,
          newCustomer: false,
          newCustomerInfo: {
            ...orderDetails.newCustomerInfo,
            [e.target.name]: e.target.value,
          },
        });
      }
    } else {
      //set phn number here
      setOrderDetails({
        ...orderDetails,
        newCustomerInfo: {
          ...orderDetails.newCustomerInfo,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  //  table
  const handleSetTable = (table) => {
    setOrderDetails({
      ...orderDetails,
      table,
    });
  };

  // waiter
  const handleSetWaiter = (waiter) => {
    setOrderDetails({
      ...orderDetails,
      waiter,
    });
  };

  // department tag elder
  const handleSetDeptTag = (dept_tag) => {
    if (dept_tag.name != "En Mesa") {
      let localCurrency = JSON.parse(
        localStorage.getItem("currency")
      );
      setOrderDetails({
        ...orderDetails,
        dept_tag,
        propina_: 0,
        propina_value: 0,
      });
      let usdServiceCharge =
        parseFloat(
          orderDetails.serviceCharge
        ) / localCurrency.rate;
      let usdDiscount =
        parseFloat(orderDetails.discount) /
        localCurrency.rate;

      let totalPayable = 0;
      let calpro = ((parseFloat(0) / 100) * theSubTotal).toFixed(2);

      totalPayable =
        theSubTotal +
        theVat +
        parseFloat(calpro) +
        usdServiceCharge -
        usdDiscount;
      setTotalPaybale(totalPayable);
      setReturnMoneyUsd(
        totalPayable - paidMoney
      );
    } else {
      let totalPayable = 0;
      let calpro = ((parseFloat(generalSettings && getSystemSettings(generalSettings, "propina")) / 100) * theSubTotal).toFixed(2);
      setOrderDetails({
        ...orderDetails,
        dept_tag,
        propina_: parseFloat(
          generalSettings && getSystemSettings(generalSettings, "propina")
        ),
        propina_value: parseFloat(calpro)
      });
      let localCurrency = JSON.parse(
        localStorage.getItem("currency")
      );
      let usdServiceCharge =
        parseFloat(
          orderDetails.serviceCharge
        ) / localCurrency.rate;
      let usdDiscount =
        parseFloat(orderDetails.discount) /
        localCurrency.rate;

      totalPayable =
        theSubTotal +
        theVat +
        parseFloat(calpro) +
        usdServiceCharge -
        usdDiscount;

      setTotalPaybale(totalPayable);
      setReturnMoneyUsd(
        totalPayable - paidMoney
      );

    }
  };

  //payment type
  const handleSetpaymentType = (payment_type) => {
    setOrderDetails({
      ...orderDetails,
      payment_type,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(orderDetails.payment_amount, payment_type);
  };

  //payment type
  const handleTotalGuest = (e) => {
    setOrderDetails({
      ...orderDetails,
      total_guest: e.target.value,
    });
  };

  //payment type amount
  const handlePaymentTypeAmount = (e) => {

    let tempPaymentAmount = {
      ...orderDetails.payment_amount,
      [e.target.name]: e.target.value,
    };

    setOrderDetails({
      ...orderDetails,
      payment_amount: tempPaymentAmount,
    });

    //calculate paid amount to set return amount
    handleCalculatePaid(tempPaymentAmount, orderDetails.payment_type);
  };

  //calculate paid amount
  const handleCalculatePaid = (paymentAmount, paymentType) => {
    let paidAmount = 0;
    if (paymentAmount !== null && paymentType !== null) {
      let thePaymentArray = [];
      if (paymentAmount) {
        thePaymentArray = Object.entries(paymentAmount);
      }
      thePaymentArray.map((eachPaymentItem) => {
        let thePaymentType = paymentType.find((paymentTypeItem) => {
          return paymentTypeItem.id === parseInt(eachPaymentItem[0]);
        });
        if (thePaymentType.name == currencyNameSecondary()) {
          if (eachPaymentItem[1] !== "") {
            if (
              thePaymentType &&
              thePaymentType.id === parseInt(eachPaymentItem[0])
            ) {
              console.log(paidAmount, eachPaymentItem[1], currencyOperationSecondary(), currencyNameSecondary());
              paidAmount = paidAmount + (currencyOperationSecondary() == "/" ? parseFloat(eachPaymentItem[1] * cajadetalle.tasa_cambio) : parseFloat(eachPaymentItem[1] / cajadetalle.tasa_cambio));
            }
          }
        } else {
          if (eachPaymentItem[1] !== "") {
            if (
              thePaymentType &&
              thePaymentType.id === parseInt(eachPaymentItem[0])
            ) {
              paidAmount = paidAmount + parseFloat(eachPaymentItem[1]);
            }
          }
        }

      });
      let localCurrency = JSON.parse(localStorage.getItem("currency"));
      paidAmount = paidAmount / localCurrency.rate;

      let theReturnMoney = totalPayable - paidAmount;

      setReturnMoneyUsd(theReturnMoney);
    } else {
      setReturnMoneyUsd(0);
    }
    setPaidMoney(paidAmount);
  };

  //send server req
  const handleSubmitOrder = (e) => {
    e.preventDefault();
    if (newOrder && newOrder.length > 0) {
      if (paidMoney !== 0) {
        toast.error(
          `${_t(
            t("Settle order to add payments, or remove payment's amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        axiosRequest();
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //axios request for submit
  const axiosRequest = () => {
    let url = BASE_URL + "/settings/new-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    var new_propina = 0;
    if (orderDetails.propina_value == 0 && orderDetails.propina_value == 0) {
      var calpro = ((parseFloat(orderDetails.propina_) / 100) * theSubTotal).toFixed(2);
      new_propina = calpro;
    } else {
      new_propina = orderDetails.propina_value;
    }
    let formData = {
      branch: orderDetails.branch,
      customer: orderDetails.customer,
      table: orderDetails.table,
      waiter: orderDetails.waiter,
      dept_tag: orderDetails.dept_tag,
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      newCustomer: orderDetails.newCustomer ? 1 : 0,
      newCustomerInfo: orderDetails.newCustomerInfo,
      token: orderDetails.token,
      total_guest: orderDetails.total_guest,
      orderItems: newOrder,
      serviceCharge: orderDetails.serviceCharge,
      discount: orderDetails.discount,
      subTotal: theSubTotal,
      totalPayable: totalPayable,
      propina_: orderDetails.propina_,
      propina_value: new_propina,
      paidMoney: paidMoney,
      theVat: theVat,
      localCurrency: localCurrency,
      workPeriod: newSettings.workPeriod,
    };
    if (orderDetails.customer == null && orderDetails.newCustomer == false) {
      toast.error(`${_t(t("Debe seleccionar o agregar un cliente"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      setLoading(true);
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data !== "ended") {

            setOrderDetails({
              ...orderDetails,
              token: {
                time: new Date().getTime(),
                id: res.data[1],
              },
              propina_value: new_propina,
              dept_tag: orderDetails.dept_tag,
              table_na: orderDetails && orderDetails.table != null ? orderDetails.table.name : null,
              parat: orderDetails && orderDetails.dept_tag != null ? orderDetails.dept_tag.name : null,
              user_name: authUserInfo.details.name,
              item_order_id: res.data[1],
            });

            setCustomerForSearch(res.data[0][1]);
            setOrderDetailusers({
              ...orderDetailUsers,
              theCustomers: res.data[0][1],
            });
            if (!barcocina.cocina) {
              if (barcocina.bar) {
                handleOnlyPrint3();
              }
            } else {
              handleOnlyPrint2();
            }
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(`${_t(t("Please restart the work period"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    }
  };

  //handle settle order
  const handleSettleOrder = (e) => {
    e.preventDefault();
    if (newOrder && newOrder.length > 0) {
      if (paidMoney < totalPayable) {
        toast.error(
          `${_t(
            t("Please enter paid amount atleast equal to the total bill amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        if (orderDetails.id_order) {
          handleSettleOrderAxiosReq();
        } else {
          if (orderDetails.customer == null && orderDetails.newCustomer == false) {
            toast.error(`${_t(t("Debe seleccionar o agregar un cliente"))}`, {
              position: "bottom-center",
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          } else {
            axiosRequestForSettle();
          }
        }
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //settle order server request
  const handleSettleOrderAxiosReq = () => {
    var new_propina = 0;
    if (orderDetails.propina_value == 0 && orderDetails.propina_value == 0) {
      var calpro = ((parseFloat(orderDetails.propina_) / 100) * theSubTotal).toFixed(2);

      new_propina = calpro;
    } else {
      new_propina = orderDetails.propina_value;
    }

    let url = BASE_URL + "/settings/settle-submitted-order2";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let formData = {
      order_group_id: orderDetails.id_order,
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      paidMoney: paidMoney,
      localCurrency: localCurrency,
      propina_: orderDetails.propina_,
      propina_value: new_propina,
      discount: orderDetails.discount,
      serviceCharge: orderDetails.serviceCharge,
      totalPayable: totalPayable,
      theVat: theVat,
      subTotal: theSubTotal,
      total_guest: orderDetails.total_guest,
    };

    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "paymentIssue") {
          setOrderDetails({
            ...orderDetails,
            token: {
              time: new Date().getTime(),
              id: orderDetails.id_order,
              propina_value: new_propina
            },
            parat: orderDetails && orderDetails.dept_tag != null ? orderDetails.dept_tag.name : null,

          });
          handlePrint();
          /*setCustomerForSearch(res.data[0][1]);
          setOrderDetailusers({
            ...orderDetailUsers,
            theCustomers: res.data[0][1],
          });*/
          getOrders();
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(
            `${_t(
              t(
                "Please enter paid amount atleast equal to the total due amount"
              )
            )}`,
            {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            }
          );
        }
        var modal = document.getElementById('closemodaldeleteorder');
        modal.click();
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  //axios request for settlement
  const axiosRequestForSettle = () => {
    let url = BASE_URL + "/settings/settle-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    var new_propina = 0;
    if (orderDetails.propina_value == 0 && orderDetails.propina_value == 0) {
      var calpro = ((parseFloat(orderDetails.propina_) / 100) * theSubTotal).toFixed(2);
      new_propina = calpro;
    } else {
      new_propina = orderDetails.propina_value;
    }
    if (orderDetails.dept_tag != null) {
      let formData = {
        branch: orderDetails.branch,
        customer: orderDetails.customer,
        table: orderDetails.table,
        waiter: orderDetails.waiter,
        dept_tag: orderDetails.dept_tag,
        payment_type: orderDetails.payment_type,
        payment_amount: orderDetails.payment_amount,
        newCustomer: orderDetails.newCustomer ? 1 : 0,
        newCustomerInfo: orderDetails.newCustomerInfo,
        token: orderDetails.token,
        total_guest: orderDetails.total_guest,

        propina_: orderDetails.propina_,
        propina_value: new_propina,
        discount: orderDetails.discount,
        serviceCharge: orderDetails.serviceCharge,

        orderItems: newOrder,
        subTotal: theSubTotal,
        totalPayable: totalPayable,
        paidMoney: paidMoney,
        theVat: theVat,
        localCurrency: localCurrency,
        workPeriod: newSettings.workPeriod,
      };
      setLoading(true);
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          if (res.data !== "ended") {
            if (res.data !== "paymentIssue") {
              setOrderDetails({
                ...orderDetails,
                token: {
                  time: new Date().getTime(),
                  id: res.data[1],
                  propina_value: new_propina,
                  dept_tag: orderDetails.dept_tag,
                  item_order_id: res.data[1],
                  parat: orderDetails && orderDetails.dept_tag != null ? orderDetails.dept_tag.name : null,

                },
                user_name: authUserInfo.details.name,
                parat: orderDetails && orderDetails.dept_tag != null ? orderDetails.dept_tag.name : null,
              });

              setCustomerForSearch(res.data[0][1]);
              setOrderDetailusers({
                ...orderDetailUsers,
                theCustomers: res.data[0][1],
              });
              handlePrint();
              setLoading(false);
              getOrders();
            } else {
              setLoading(false);
              toast.error(
                `${_t(
                  t(
                    "Please enter paid amount atleast equal to the total bill amount"
                  )
                )}`,
                {
                  position: "bottom-center",
                  closeButton: false,
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  className: "text-center toast-notification",
                }
              );
            }
          } else {
            setLoading(false);
            toast.error(`${_t(t("Please restart the work period"))}`, {
              position: "bottom-center",
              closeButton: false,
              autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              className: "text-center toast-notification",
            });
          }
        })
        .catch(() => {
          setLoading(false);
          toast.error(`${_t(t("Please try again"))}`, {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        });
    } else {
      setLoading(false);
      toast.error("seleccione tipo de orden", {
        position: "bottom-center",
        closeButton: false,
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }

  };

  //print bills
  const componentRef = useRef();
  const component2Ref = useRef();
  const component3Ref = useRef();
  // only print bill
  //for pos manager
  const handleOnlyPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        if (!barcocina.cocina) {
          if (barcocina.bar) {
            handleOnlyPrint3();
          }
        } else {
          handleOnlyPrint2();
        }
      }
    },
  });

  //for kithcen
  const handleOnlyPrint2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      if (barcocina.bar) {
        handleOnlyPrint3();
      } else {
        handleOrderSubmitSuccessful();
      }
    },
  });

  const handleOnlyPrint3 = useReactToPrint({
    onBeforePrint: () => {

    },
    content: () => component3Ref.current,
    onAfterPrint: () => {
      handleOrderSubmitSuccessful();
    },
  });

  //after order submit or settle
  //for pos manager
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
        if (!barcocina.cocina) {
          if (barcocina.bar) {
            handleOnlyPrint3();
          }
        } else {
          handleOnlyPrint2();
        }
      } else {
        handleOrderSubmitSuccessful();
      }
    },
  });

  //for kithcen
  const handlePrint2 = useReactToPrint({
    content: () => component2Ref.current,
    onAfterPrint: () => {
      handleOrderSubmitSuccessful();
    },
  });

  //call after successful order submit and settle
  const handleOrderSubmitSuccessful = () => {
    SetBarCocina({
      bar: false,
      cocina: false
    });
    newitems(false);
    setupdateproduct(true);
    btnupdatsav("create");
    setNewOrder(null);
    setActiveItemInOrder(null);
    setSelectedVariation([]);
    setSelectedPropertyGroup([]);
    setSelectedProperties([]);
    setTheSubTotal(0);
    setTheVat(0);
    setTotalPaybale(0);
    setReturnMoneyUsd(0);
    setPaidMoney(0);
    setOrderDetails({
      branch: orderDetails.branch,
      customer: null,
      table: null,
      waiter: null,
      dept_tag: null,
      payment_type: null,
      payment_amount: null,
      total_guest: 1,
      newCustomer: false,
      newCustomerInfo: {
        name: "",
        number: "",
        address: "",
      },
      token: null,
      serviceCharge: 0,
      discount: 0,
      propina_: generalSettings && getSystemSettings(generalSettings, "propina"),
      propina_value: 0,
    });
    toast.success(`${_t(t("Orden enviada"))}`, {
      position: "bottom-center",
      closeButton: false,
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      className: "text-center toast-notification",
    });
    //sound
    /*if (getSystemSettings(generalSettings, "play_sound") === "1") {
      let beep = document.getElementById("myAudio");
      beep.play();
    }*/
  };
  const formartid = (id_order) => {
    var str = "" + id_order;
    var pad = "0000000";
    return pad.substring(0, pad.length - str.length) + str;
  };

  const prevgetorderitems = (row) => {
    //console.log(row);
    btnupdatsav("update");
    if (newOrder) {
      handleCancelConfirmation2();
    } else {
      getorderitems(row);
    }
  };
  const handleCancelConfirmation2 = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-primary text-white">
            <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("Estas seguro de limpiar?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark"
                onClick={() => {
                  handleCancel();
                  onClose();
                }}
              >
                {_t(t("Si!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const getorderitems = (row) => {
    let refrechproperties;
    let oldOrderItems = [];
    let tempSelectedVariations = [];
    let newSelectedPropertyGroup = [];
    let tempselectpropiedades = [];
    row.orderedItems.map((dataitem, indexx) => {

      let newOrderItem = null;

      let tempSelectedPropertyGroup = []; //to set selected property group array for order items properties
      let tempArrayPropertyGroup = []; //for selected property groups
      if (newOrder) {
        newOrder.map((eachOldOrderItem) => {
          //push all old items to new array to setNewOrder

          oldOrderItems.push(eachOldOrderItem);

          //set selected variations of each order item
          let tempArray = [];
          if (eachOldOrderItem.variation !== null) {
            tempArray.push(eachOldOrderItem.variation.food_with_variation_id);
          } else {
            tempArray.push(null);
          }
          tempSelectedVariations.push(tempArray);
        });
        //recoriendo propiedades

        let propertyItem = [];
        if (dataitem.properties !== null) {
          JSON.parse(dataitem.properties).map((dbpropertiesorder) => {
            dataitem.item_food.properties.map((dbproperties) => {
              dbproperties.map((dbproperties2) => {
                if (dbpropertiesorder.property == dbproperties2.name) {
                  //tempArray.push(null);
                  propertyItem.push({ item: dbproperties2, quantity: 1 });
                  refrechproperties = dbproperties2;
                }
              });
            });
          });
        }
        //add new order item
        newOrderItem = {
          item: dataitem.item_food,
          item_order_id: dataitem.id,
          variation:
            parseInt(dataitem.item_food.has_variation) === 1
              ? dataitem.item_food.variations[0]
              : null,
          quantity: dataitem.quantity,
          destine: dataitem.destine,
          destine_bar: dataitem.destine_bar,
          properties: propertyItem
        };
        //set selected variations of new item
        let tempArray = [];
        if (parseInt(dataitem.item_food.has_variation) === 1) {
          tempArray.push(dataitem.item_food.variations[0].food_with_variation_id);
        } else {
          tempArray.push(null);
        }
        tempSelectedVariations.push(tempArray);
        //push new item to new array to setNewOrder
        oldOrderItems.push(newOrderItem);
      } else {
        //console.log(JSON.parse(dataitem.properties));
        let propertyItem = [];


        let propertyseleccionados = [];

        if (dataitem.properties !== null) {
          JSON.parse(dataitem.properties).map((dbpropertiesorder) => {
            dataitem.item_food.properties.map((dbproperties) => {
              dbproperties.map((dbproperties2) => {
                if (dbpropertiesorder.property == dbproperties2.name) {
                  //tempArray.push(null);
                  propertyItem.push({ item: dbproperties2, quantity: 1 });
                  refrechproperties = dbproperties2;
                  //handle selected property group
                  tempArrayPropertyGroup.push(
                    parseInt(dbproperties2.property_group_id)
                  );

                  propertyseleccionados.push(dbproperties2.id);
                }
              });

            });
          });
        }

        tempselectpropiedades.push(propertyseleccionados);
        let temp_pro = parseFloat((100 * row.propina_) / row.order_bill).toFixed(2);

        //if no item in newOrder List
        setOrderDetails({
          //set token here on first order item add,
          ...orderDetails,
          id_order: row.id,
          token: row.token,
          serviceCharge: row.service_charge,
          discount: row.discount,
          propina_: temp_pro == "NaN" ? 0 : temp_pro,
          propina_value: row.propina_,
          table_na: row.table_name,
          table_na: row.table_name,
          table_na: row.table_name,
          customer: { name: row.customer_name, address: row.customer_address, phn_no: row.customer_phn_no },
          table: { name: row.table_name },
          dept_tag: { name: row.dept_tag_name }
        });


        //set selected variations of new item
        let tempArray = [];
        let dbvariation = [];
        if (parseInt(dataitem.item_food.has_variation) === 1) {
          for (var e = 0; e <= dataitem.item_food.variations.length - 1; e++) {

            if (dataitem.item_food.variations[e].variation_name == dataitem.variation) {
              //console.log(dataitem.item_food.variations[e]);
              tempArray.push(dataitem.item_food.variations[e].food_with_variation_id);
              dbvariation = dataitem.item_food.variations[e];

            }
          }

        } else {
          tempArray.push(null);
        }
        tempSelectedVariations.push(tempArray);

        newOrderItem = {
          //add new order item
          item: dataitem.item_food,
          variation:
            parseInt(dataitem.item_food.has_variation) === 1
              ? dbvariation
              : null,
          quantity: dataitem.quantity,
          destine: dataitem.destine,
          destine_bar: dataitem.destine_bar,
          item_order_id: dataitem.id,
          properties: propertyItem
        };

        //push new item to new array to setNewOrder
        oldOrderItems.push(newOrderItem);

        //setSelectedProperties(tempSelectedProperties);
      }
      //handle selected property groups
      tempSelectedPropertyGroup.push(tempArrayPropertyGroup);

      tempSelectedPropertyGroup.map((eachSelectedGroupArray) => {
        let unique = [...new Set(eachSelectedGroupArray)];
        newSelectedPropertyGroup.push(unique);
      });
    });

    //set selected property groups here
    setSelectedPropertyGroup(newSelectedPropertyGroup);
    //set new order list with new array of all order items
    setNewOrder(oldOrderItems);

    //set order list active item index to add class "actve"
    //setActiveItemInOrder(oldOrderItems.length - 1);

    //set selected variations
    setSelectedVariation(tempSelectedVariations);

    //set properties
    setSelectedProperties(tempselectpropiedades);


    //calculate subTotalPrice
    totalPrice(oldOrderItems);
    let totalPayable =
      parseFloat(row.order_bill) +
      parseFloat(row.vat) +
      parseFloat(row.propina_) +
      parseFloat(row.service_charge) -
      parseFloat(row.discount);

    setTotalPaybale(totalPayable);
    setReturnMoneyUsd(
      totalPayable - paidMoney
    );
    let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.destine == 1);
    let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.destine_bar == 1);
    SetBarCocina({
      ...barcocina,
      bar: filter_bar && filter_bar.length > 0 ? true : false,
      cocina: filter_cocina && filter_cocina.length > 0 ? true : false
    });
  };

  //send server req
  const handleEditOrder = (e) => {
    e.preventDefault();
    if (newOrder && newOrder.length > 0) {
      if (paidMoney !== 0) {
        toast.error(
          `${_t(
            t("Settle order to add payments, or remove payment's amount")
          )}`,
          {
            position: "bottom-center",
            closeButton: false,
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          }
        );
      } else {
        axiosRequestedit();
      }
    } else {
      toast.error(`${_t(t("Please add items in order list"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }
  };

  //axios request for submit
  const axiosRequestedit = () => {
    var mesa = "-";
    if (orderDetails.table) {
      mesa = orderDetails.table.name;
    } else {
      mesa = orderDetails.table_na;
    }
    let url = BASE_URL + "/settings/edit-order";
    let localCurrency = JSON.parse(localStorage.getItem("currency"));
    let formData = {
      id_order: orderDetails.id_order,
      branch: orderDetails.branch,
      customer: orderDetails.customer,
      table: orderDetails.table,
      mesa: mesa,
      waiter: orderDetails.waiter,
      dept_tag: orderDetails.dept_tag,
      payment_type: orderDetails.payment_type,
      payment_amount: orderDetails.payment_amount,
      newCustomer: orderDetails.newCustomer ? 1 : 0,
      newCustomerInfo: orderDetails.newCustomerInfo,
      token: orderDetails.token,
      propina_: orderDetails.propina_value,
      total_guest: orderDetails.total_guest,
      orderItems: newOrder,
      serviceCharge: orderDetails.serviceCharge,
      discount: orderDetails.discount,
      subTotal: theSubTotal,
      totalPayable: totalPayable,
      paidMoney: paidMoney,
      theVat: theVat,
      localCurrency: localCurrency,
      workPeriod: newSettings.workPeriod,
    };
    setLoading(true);
    axios
      .post(url, formData, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        if (res.data !== "ended") {
          // handlePrint();
          setCustomerForSearch(res.data[0][1]);
          setOrderDetailusers({
            ...orderDetailUsers,
            theCustomers: res.data[0][1],
          });
          setLoading(false);
          getOrders();
          if (!barcocina.cocina) {
            if (barcocina.bar) {
              handleOnlyPrint3();
            }
          } else {
            handleOnlyPrint2();
          }
          //handleOrderSubmitSuccessful();
        } else {
          setLoading(false);
          toast.error(`${_t(t("Please restart the work period"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(`${_t(t("Please try again"))}`, {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
      });
  };
  //get customer
  const getOrders = () => {
    setLoading(true);
    const customerUrl = BASE_URL + "/settings/get-submitted-orders-pos";
    return axios
      .get(customerUrl, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setorders({
          orderspos: res.data
        });
        /*setCustomerList(res.data[0]);
        setCustomerForSearch(res.data[1]);*/
        setLoading(false);
      });
  };
  const handleconfirmdeleteitem = (row, index) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="card card-body bg-primary text-white">
            <h1 className="text-white">{_t(t("Are you sure?"))}</h1>
            <p className="text-center">
              {_t(t("Quieres remover item de la orden?"))}
            </p>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-warning text-dark"
                onClick={() => {
                  handledeleteitemnotcooking(row, index);
                  onClose();
                }}
              >
                {_t(t("Yes!"))}
              </button>
              <button className="btn btn-success ml-2 px-3" onClick={onClose}>
                {_t(t("No"))}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  //remove item from order list
  const handleRemoveItemFromOrderList = (removeIndex) => {

    if (updateproduct) {
      let oldOrderItems = []; //array to push order items
      newOrder.map((newOrderItem, index) => {
        if (index !== removeIndex) {
          oldOrderItems.push(newOrderItem);

        } else {
          if (newOrderItem.item_order_id) {
            handleconfirmdeleteitem(newOrderItem, removeIndex);
            /*toast.error(
              `${_t(
                t("No se puede eliminar un item enviado")
              )}`,
              {
                position: "bottom-center",
                closeButton: false,
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              }
            );
            oldOrderItems.push(newOrderItem);*/
            oldOrderItems.push(newOrderItem);
          }
        }
      });

      //delete variations here
      let tempSelectedVariations = selectedVariation.filter(
        (selectedVariationItem, variationIndex) => {
          return variationIndex !== removeIndex;
        }
      );
      //delete properties here
      let tempSelectedProperties = selectedProperties.filter(
        (selectedPropertyItem, propertyIndex) => {
          return propertyIndex !== removeIndex;
        }
      );

      //delete property group here
      let tempSelectedPropertyGroup = selectedPropertyGroup.filter(
        (selectedPropertyGroupItem, propertyGroupIndex) => {
          return propertyGroupIndex !== removeIndex;
        }
      );

      //set property group here
      setSelectedPropertyGroup(tempSelectedPropertyGroup);

      //set variations here
      setSelectedVariation(tempSelectedVariations);

      //set properties here
      setSelectedProperties(tempSelectedProperties);

      //set updated order list
      setNewOrder(oldOrderItems);

      //set active item in order list
      setActiveItemInOrder(null);

      //calculate subTotalPrice
      totalPrice(oldOrderItems);
      //VERIFICAR SI HAY NUEVOS PARA COMANDA
      let count_existe_id_item = oldOrderItems.length;
      oldOrderItems.map((eachOldOrderItem) => {
        if (eachOldOrderItem.item_order_id === undefined) {
          count_existe_id_item--;
        }
      });
      if (count_existe_id_item == oldOrderItems.length) {
        newitems(false);
      } else {
        newitems(true);
      }
      let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
      let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
      SetBarCocina({
        ...barcocina,
        bar: filter_bar && filter_bar.length > 0 ? true : false,
        cocina: filter_cocina && filter_cocina.length > 0 ? true : false
      });
    } else {
      handleDeleteConfirmation();
    }
  };
  const handledeleteitemnotcooking = (row, index) => {
    let dt = {
      user_id: authUserInfo.details.id,
      branch_id: authUserInfo.details.branch_id,
      item_id: row.item_order_id,
    }
    const foodUnitUrl = BASE_URL + `/settings/post-delete-item2`;

    return axios
      .post(foodUnitUrl, dt, {
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        //setbtncreate(false);
        //getSubmittedOrders();

        toast.info(res.data[0], {
          position: "bottom-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        });
        if (res.data[0] == 'Item removido de la orden') {
          handleRemoveItemFromOrderList2(index)
        }
        getOrders();
      })
      .catch((error) => {

      });
  };

  //remove item from order list
  const handleRemoveItemFromOrderList2 = (removeIndex) => {
    if (updateproduct) {
      let oldOrderItems = []; //array to push order items
      newOrder.map((newOrderItem, index) => {
        if (index !== removeIndex) {
          oldOrderItems.push(newOrderItem);
        }
      });

      //delete variations here
      let tempSelectedVariations = selectedVariation.filter(
        (selectedVariationItem, variationIndex) => {
          return variationIndex !== removeIndex;
        }
      );
      //delete properties here
      let tempSelectedProperties = selectedProperties.filter(
        (selectedPropertyItem, propertyIndex) => {
          return propertyIndex !== removeIndex;
        }
      );

      //delete property group here
      let tempSelectedPropertyGroup = selectedPropertyGroup.filter(
        (selectedPropertyGroupItem, propertyGroupIndex) => {
          return propertyGroupIndex !== removeIndex;
        }
      );

      //set property group here
      setSelectedPropertyGroup(tempSelectedPropertyGroup);

      //set variations here
      setSelectedVariation(tempSelectedVariations);

      //set properties here
      setSelectedProperties(tempSelectedProperties);

      //set updated order list
      setNewOrder(oldOrderItems);

      //set active item in order list
      setActiveItemInOrder(null);

      //calculate subTotalPrice
      totalPrice(oldOrderItems);

      if (oldOrderItems.length == 0) {
        handleCancel();
      }
      //VERIFICAR SI HAY NUEVOS PARA COMANDA
      let count_existe_id_item = oldOrderItems.length;
      oldOrderItems.map((eachOldOrderItem) => {
        if (eachOldOrderItem.item_order_id === undefined) {
          count_existe_id_item--;
        }
      });
      if (count_existe_id_item == oldOrderItems.length) {
        newitems(false);
      } else {
        newitems(true);
      }
      let filter_cocina = oldOrderItems && oldOrderItems.filter((item) => item.item.destine == 1);
      let filter_bar = oldOrderItems && oldOrderItems.filter((item) => item.item.destine_bar == 1);
      SetBarCocina({
        ...barcocina,
        bar: filter_bar && filter_bar.length > 0 ? true : false,
        cocina: filter_cocina && filter_cocina.length > 0 ? true : false
      });
    } else {
      handleDeleteConfirmation();
    }

  };
  return (
    <>
      <Helmet>
        <title>{_t(t("POS"))}</title>
      </Helmet>

      {/* beep sound */}
      <audio id="myAudio">
        <source src="/assets/beep/beep.mp3" type="audio/mpeg" />
      </audio>
      {/* Settle modal */}
      <div className="modal fade" id="orderDetails" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {/* show order token on modal header */}
                  {_t(t("Order details, Orden"))}: #
                  {checkOrderDetails.item && formartid(checkOrderDetails.item.id)}
                </h5>
              </div>
              <button
                type="button"
                id="closemodaldeleteorder"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {/* if loading true show loading effect */}
            {loading ? (
              <div className="modal-body">{modalLoading(5)}</div>
            ) : (
              <div className="modal-body">
                <br />
                {checkOrderDetails.item &&
                  parseInt(checkOrderDetails.item.is_settled) === 0 ? (
                  // if this item is not settled then show settle-cancel button
                  <>
                    {checkOrderDetails.item &&
                      parseInt(checkOrderDetails.item.is_cancelled) !== 1 && (
                        <div className="text-right">
                          {checkOrderDetails.settle ? (
                            <button
                              className="btn btn-primary px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: false,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Cancel"))}
                            </button>
                          ) : (
                            <button
                              className="btn btn-success px-3 rounded-md text-uppercase"
                              onClick={() => {
                                setCheckOrderDetails({
                                  ...checkOrderDetails,
                                  settle: true,
                                  payment_amount: null,
                                  payment_type: null,
                                });
                                setReturnMoneyUsd(0);
                                setPaidMoney(0);
                              }}
                            >
                              {_t(t("Pagar"))}
                            </button>
                          )}
                        </div>

                      )}
                  </>
                ) : (
                  // if this item is not settled then show settle-cancel button else, show this notification
                  <div className="text-center bg-success text-white py-2">
                    {_t(t("La orden a sido pagada, puede cerrar ahora"))}
                  </div>
                )}
                {/* show this if order settle is not true, if true show payment input field */}
                {!checkOrderDetails.settle ? (
                  <div className="col-12 filtr-item">
                    <div className="fk-order-token t-bg-white">
                      <div className="fk-order-token__body">
                        <div className="fk-addons-table">
                          <div className="fk-addons-table__head text-center">
                            {_t(t("orden "))}: #
                            {checkOrderDetails.item &&
                              formartid(checkOrderDetails.item.id)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="my-2 payment-type-parent">
                    <Select
                      options={paymentTypeForSearch && paymentTypeForSearch}
                      components={makeAnimated()}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      classNamePrefix="select"
                      className="xsm-text"
                      backspaceRemovesValue={false}
                      onChange={handleSetpaymentType}
                      maxMenuHeight="200px"
                      isMulti
                      clearIndicator={null}
                      placeholder={_t(t("Select payment methods")) + ".."}
                    />
                    {checkOrderDetails.payment_type !== null && (
                      <form
                        className="border my-2 change-background rounded-lg"
                        onSubmit={handleSettleOrder}
                      >
                        <div className="sm-text text-center text-white py-2">
                          {_t(t("Amount"))}
                        </div>
                        {checkOrderDetails.payment_type.map(
                          (eachPaymentType, paymentTypeIndex) => {
                            return (
                              <div className="addons-list__item mx-2 mb-1">
                                <input
                                  type="number"
                                  min="0"
                                  step="0.01"
                                  name={eachPaymentType.id}
                                  autoComplete="off"
                                  className="form-control xsm-text pl-2"
                                  onChange={handlePaymentTypeAmount}
                                  placeholder={eachPaymentType.name}
                                  value={
                                    checkOrderDetails.payment_amount &&
                                    checkOrderDetails.payment_amount[
                                    eachPaymentType.id
                                    ]
                                  }
                                />
                              </div>
                            );
                          }
                        )}
                        <div className="pb-2 pl-2 my-2">
                          <button
                            className="btn btn-sm btn-warning text-dark px-3 text-uppercase"
                            type="submit"
                          >
                            {_t(t("Pagar"))}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                )}
                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Detalle Pago"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkOrderDetails.payment_type ? (

                      checkOrderDetails.payment_type.map((item) => {
                        return (

                          <tr>
                            {item.name == currencyNameSecondary() ?
                              checkOrderDetails.payment_amount ? (
                                <td >
                                  {item.name + "(" + currencySymbolLeftSecondary() + " "
                                    (
                                      checkOrderDetails.payment_amount[item.id] == undefined ?
                                        0
                                        :
                                        checkOrderDetails.payment_amount[item.id]
                                    )
                                    + ")"}
                                </td>

                              ) : (
                                <td >
                                  {item.name + "(" + currencySymbolLeftSecondary() + " 0)"}
                                </td>
                              )
                              :
                              <td>
                                {item.name}
                              </td>
                            }
                            {checkOrderDetails.payment_amount ?
                              checkOrderDetails.payment_amount[item.id] == undefined ?
                                < td>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    Math.abs(0)
                                  )}
                                  {currencySymbolRight()}
                                </td>

                                :
                                item.name == currencyNameSecondary() ?
                                  < td>
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      Math.abs(currencyOperationSecondary() == "/" ? checkOrderDetails.payment_amount[item.id] * cajadetalle.tasa_cambio : checkOrderDetails.payment_amount[item.id] / cajadetalle.tasa_cambio)
                                    )}
                                    {currencySymbolRight()}
                                  </td>


                                  :
                                  < td>
                                    {currencySymbolLeft()}
                                    {formatPrice(
                                      Math.abs(checkOrderDetails.payment_amount[item.id])
                                    )}
                                    {currencySymbolRight()}
                                  </td>

                              :
                              < td>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  Math.abs(0)
                                )}
                                {currencySymbolRight()}
                              </td>
                            }
                          </tr>
                        );
                      }
                      )
                    ) : (
                      null
                    )}
                    <tr>
                      <td className="text-capitalized">Total desglose</td>
                      <td>

                        <>
                          {currencySymbolLeft()}
                          {formatPrice(paidMoney)}
                          {currencySymbolRight()}
                        </>

                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">
                        {checkOrderDetails.settle &&
                          paidMoney >=
                          parseFloat(
                            checkOrderDetails.item.total_payable
                          ) ?
                          (
                            <span className="mr-2 text-secondary font-weight-bold">
                              Cambio
                            </span>
                          )
                          :

                          <span className="mr-2 text-secondary font-weight-bold">
                            Saldo Pendiente
                          </span>

                        }

                      </td>
                      <td>
                        {checkOrderDetails.settle &&
                          paidMoney >=
                          parseFloat(
                            checkOrderDetails.item.total_payable
                          ) ?
                          (
                            <span className="mr-2 text-secondary font-weight-bold">
                              {currencySymbolLeft()}
                              {formatPrice(returnMoneyUsd)}
                              {currencySymbolRight()}{" "}
                            </span>
                          )
                          :
                          checkOrderDetails.settle && checkOrderDetails.item.total_payable != null ?
                            (
                              <span className="mr-2 text-secondary font-weight-bold">
                                {currencySymbolLeft()}
                                {formatPrice(parseFloat(checkOrderDetails.item.total_payable) - paidMoney)}
                                {currencySymbolRight()}{" "}
                              </span>
                            ) : null

                        }


                      </td>
                    </tr>
                  </tbody>
                </table>


                <table className="table table-striped table-sm text-center mt-3">
                  <thead className="bg-info text-white text-uppercase">
                    <tr>
                      <th scope="col" colSpan="2">
                        {_t(t("Order details"))}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Received by"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.user_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Customer"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.customer_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Sucursal"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.branch_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Department"))}
                      </td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.dept_tag_name}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Table"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.table_name}
                      </td>
                    </tr>

                    {/* <tr>
                      <td className="text-capitalized">{_t(t("Waiter"))}</td>
                      <td>
                        {checkOrderDetails.item &&
                          checkOrderDetails.item.waiter_name}
                      </td>
                        </tr>*/}

                    <tr>
                      <td className="text-capitalized">{_t(t("Subtotal"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.order_bill)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Vat"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.vat)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Service charge"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.service_charge)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">{_t(t("Discount"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.discount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-capitalized">{_t(t("Propina"))}</td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.propina_)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Total bill"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.total_payable)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="text-capitalized">
                        {_t(t("Paid amount"))}
                      </td>
                      <td>
                        {checkOrderDetails.item && (
                          <>
                            {currencySymbolLeft()}
                            {formatPrice(checkOrderDetails.item.paid_amount)}
                            {currencySymbolRight()}
                          </>
                        )}
                      </td>
                    </tr>

                    {checkOrderDetails.item &&
                      parseFloat(
                        checkOrderDetails.item.total_payable -
                        checkOrderDetails.item.paid_amount
                      ) >= 0 ? (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Due amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.total_payable -
                                  checkOrderDetails.item.paid_amount
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td className="text-capitalized">
                          {_t(t("Return amount"))}
                        </td>
                        <td>
                          {checkOrderDetails.item && (
                            <>
                              {currencySymbolLeft()}
                              {formatPrice(
                                parseFloat(
                                  checkOrderDetails.item.paid_amount -
                                  checkOrderDetails.item.total_payable
                                )
                              )}
                              {currencySymbolRight()}
                            </>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Settle modal Ends*/}
      {/* Print bill */}
      <div className="d-none">
        <div ref={componentRef}>
          {newOrder && (
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {getSystemSettings(generalSettings, "siteName")}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {getSystemSettings(generalSettings, "address")}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text text-center">
                      {_t(t("Teléfono"))}:{" "}
                      {getSystemSettings(generalSettings, "phnNo")}
                    </p>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Orden"))}: {orderDetails && formartid(orderDetails.token.id)}
                    </span>
                    <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                      {_t(t("Ticket"))}
                    </span>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {/*_t(t("Vat reg"))}: {_t(t("Applied"))*/}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Fecha"))}: <Moment format="DD/MM/YYYY">{new Date()}</Moment>
                      {", "}
                      {orderDetails && (
                        <Moment format="hh:mm A">{orderDetails.token.time}</Moment>
                      )}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Total Invitados"))}:{" "}
                      {orderDetails && orderDetails.total_guest}
                    </p>
                    <p className="mb-0 xxsm-text fk-print-text">
                      {_t(t("Elaborada por"))}:{" "}
                      {authUserInfo.details.name}
                    </p>
                    {orderDetails && orderDetails.newCustomer == 1 ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente"))}:{" "}
                        {orderDetails.newCustomerInfo.name}
                      </p>
                      : null}
                    {orderDetails && orderDetails.newCustomer == 1 ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Num"))}:{" "}
                        {orderDetails.newCustomerInfo.number}
                      </p>
                      : null}
                    {orderDetails && orderDetails.newCustomer == 1 ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Dirección"))}:{" "}
                        {orderDetails.newCustomerInfo.address}
                      </p>
                      : null}

                    {orderDetails && orderDetails.customer !== null ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente"))}:{" "}
                        {orderDetails.customer.name}
                      </p>
                      : null}
                    {orderDetails && orderDetails.customer !== null ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Num"))}:{" "}
                        {orderDetails.customer.phn_no}
                      </p>
                      : null}
                    {orderDetails && orderDetails.customer !== null ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Cliente Dirección"))}:{" "}
                        {orderDetails.customer.address}
                      </p>
                      : null}
                    {orderDetails && orderDetails.table !== null ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Mesa"))}:{" "}
                        {orderDetails.table.name}
                      </p>
                      : null}
                    {orderDetails && orderDetails.dept_tag !== null ?
                      <p className="mb-0 xxsm-text fk-print-text">
                        {_t(t("Tipo de Orden"))}:{" "}
                        {orderDetails.dept_tag.name}
                      </p>
                      : null}



                    <table>
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text"
                          >
                            {_t(t("Platillo"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-center"
                          >
                            {_t(t("Cantidad"))}
                          </th>
                          <th
                            scope="col"
                            className="mb-0 xxsm-text fk-print-text text-right"
                          >
                            {_t(t("Precio"))}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {newOrder.map((printItem, printItemIndex) => {
                          return (
                            <tr style={{ borderBottom: "solid 1px black" }}>
                              <th className="mb-0 xxsm-text fk-print-text">
                                <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>
                                {/* properties */}
                                {printItem.properties &&
                                  printItem.properties.length > 0 &&
                                  selectedPropertyGroup[printItemIndex] !==
                                  undefined &&
                                  selectedPropertyGroup[printItemIndex].map(
                                    (thisIsGroup) => {
                                      let theGroup =
                                        propertyGroupForSearch &&
                                        propertyGroupForSearch.find(
                                          (theItem) => {
                                            return theItem.id === thisIsGroup;
                                          }
                                        );
                                      return (
                                        <div className="d-block xxsm-text fk-print-text">
                                          <span className="d-block xxsm-text fk-print-text d-inline-block mr-1">
                                            {theGroup && theGroup.name} :
                                          </span>
                                          {printItem.properties.map(
                                            (propertyName, propertyIndex) => {
                                              if (
                                                parseInt(
                                                  propertyName.item
                                                    .property_group_id
                                                ) === theGroup.id
                                              ) {
                                                return (
                                                  <span className="d-block xxsm-text fk-print-text d-inline-block mr-1">
                                                    {propertyName.item.name}{" "}
                                                    <span>
                                                      {" "}
                                                      {propertyName.quantity >
                                                        1 &&
                                                        "(" +
                                                        propertyName.quantity +
                                                        ")"}
                                                    </span>
                                                    {printItem.properties
                                                      .length -
                                                      1 !==
                                                      propertyIndex && ","}
                                                  </span>
                                                );
                                              } else {
                                                return true;
                                              }
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                              </th>
                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                {printItem.quantity}
                              </td>
                              <td className="mb-0 xxsm-text fk-print-text text-right">
                                {currencySymbolLeft()}
                                {showPriceOfEachOrderItem(printItemIndex)}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Total"))}</span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(theSubTotal)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("VAT"))}
                              {" ("}
                              {newSettings && newSettings.vat}%{")"}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(theVat)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("Delivery"))}
                            </span>
                          </th>

                          {orderDetails && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(orderDetails.serviceCharge)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Descuento"))}</span>
                          </th>
                          {orderDetails && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {formatPrice(orderDetails.discount)}
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">{_t(t("Propina"))}</span>
                          </th>
                          {orderDetails && (
                            <td className="mb-0 xxsm-text fk-print-text text-right">
                              {currencySymbolLeft()}
                              {

                                orderDetails.propina_ != 0 || orderDetails.propina_value == 0 ? formatPrice((parseFloat(orderDetails.propina_) / 100) * theSubTotal) :
                                  formatPrice(orderDetails.propina_value)

                              }
                              {currencySymbolRight()}
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("Grand total"))}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeft()}
                            {formatPrice(totalPayable)}
                            {currencySymbolRight()}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {orderDetails.payment_type ? (
                      orderDetails.payment_type.map((item) => {
                        return (
                          <table style={{ width: "100%" }}>
                            <tbody>
                              <tr>
                                <th className="mb-0 xxsm-text fk-print-text">
                                  {item.name == currencyNameSecondary() ?
                                    orderDetails.payment_amount ? (

                                      item.name + "(" + currencySymbolLeftSecondary() +
                                      (
                                        orderDetails.payment_amount[item.id] == undefined ?
                                          0
                                          :
                                          orderDetails.payment_amount[item.id]
                                      )
                                      + ")"
                                    ) : (
                                      item.name + "(" + currencySymbolLeftSecondary() + " 0)"
                                    )
                                    :
                                    item.name
                                  }

                                </th>
                                <td className="mb-0 xxsm-text fk-print-text text-right">
                                  {orderDetails.payment_amount ?
                                    orderDetails.payment_amount[item.id] == undefined ?

                                      currencySymbolLeft() +
                                      formatPrice(
                                        Math.abs(0)
                                      ) +
                                      currencySymbolRight()


                                      :
                                      item.name == currencyNameSecondary() ?
                                        currencySymbolLeft() + " " +
                                        formatPrice(
                                          Math.abs(currencyOperationSecondary() == "/" ? orderDetails.payment_amount[item.id] * cajadetalle.tasa_cambio : orderDetails.payment_amount[item.id] / cajadetalle.tasa_cambio)
                                        )
                                        :
                                        currencySymbolLeft() +
                                        formatPrice(
                                          Math.abs(orderDetails.payment_amount[item.id])
                                        ) +
                                        currencySymbolRight()

                                    :
                                    currencySymbolLeft() +
                                    formatPrice(
                                      Math.abs(0)
                                    ) +
                                    currencySymbolRight()
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        );
                      })
                    ) : (
                      null
                    )}
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {_t(t("Cambio"))}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {totalPayable <= paidMoney ? (

                              currencySymbolLeft() +
                              formatPrice(
                                Math.abs(returnMoneyUsd)
                              ) +
                              currencySymbolRight()

                            ) : (
                              currencySymbolLeft() +
                              formatPrice(
                                Math.abs(0)
                              ) +
                              currencySymbolRight()
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th className="mb-0 xxsm-text fk-print-text">
                            <span className="d-block xxsm-text fk-print-text">
                              {currencyNameSecondary()}
                            </span>
                          </th>
                          <td className="mb-0 xxsm-text fk-print-text text-right">
                            {currencySymbolLeftSecondary() + " " +
                              formatPrice(
                                Math.abs(currencyOperationSecondary() == "/" ? parseFloat(totalPayable / cajadetalle.tasa_cambio).toFixed(2) : parseFloat(totalPayable * cajadetalle.tasa_cambio).toFixed(2))
                              )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/*<span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              En Café Alicia tu opinión es importante!
            </span>
            <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
              <img src={`${BASE_URL}` + "/assets/img/qr.jpeg"} alt="Logo" width="170" height="175" />
            </span>*/}

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Print bill kitchen */}
      <div className="d-none">
        <div ref={component2Ref}>
          {newOrder && (
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">

                    {
                      barcocina.cocina ?
                        <>
                          <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                            {_t(t("Orden"))}: {orderDetails && formartid(orderDetails.token.id)}
                          </span>
                          <p className="mb-0 xxsm-text fk-print-text">
                            {_t(t("Fecha"))}: <Moment format="DD/MM/YYYY HH:mm A">{new Date()}</Moment>
                          </p>

                          {orderDetails && orderDetails.user_name ?
                            <p className="mb-0 xxsm-text fk-print-text">
                              {_t("Mesero")}:{" "}{orderDetails && orderDetails.user_name}
                            </p> : null
                          }
                          {orderDetails && orderDetails.parat !== null ?
                            <p className="mb-0 xxsm-text fk-print-text">
                              {_t(t("Tdo."))}:{" "}
                              {orderDetails.parat}
                            </p>
                            : null}
                          {orderDetails && orderDetails.table !== null ?
                            <p className="mb-0 xxsm-text fk-print-text">
                              {_t(t("Mesa"))}:{" "}
                              {orderDetails.table.name}
                            </p>
                            : null}
                          <table >
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="mb-0 xxsm-text fk-print-text"
                                >
                                  {_t(t("Platillo"))}
                                </th>
                                <th
                                  scope="col"
                                  className="mb-0 xxsm-text fk-print-text text-center"
                                >
                                  {_t(t("Cantidad"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="mb-0 xxsm-text fk-print-text text-center">
                                <span className="mb-0 xxsm-text fk-print-text fk-print-text--bold text-uppercase">
                                  {'Destino Cocina'}
                                </span>
                              </tr>
                              {
                                btnupdatesave == "create" ?
                                  newOrder.map((printItem, printItemIndex) => {
                                    return (
                                      printItem.item && printItem.item.destine == 1 ?
                                        <tr style={{ borderBottom: "solid 1px black" }}>
                                          <td className="mb-0 xxsm-text fk-print-text">
                                            <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>


                                            {/* properties */}
                                            {printItem.properties &&
                                              printItem.properties.length > 0 &&
                                              selectedPropertyGroup[printItemIndex] !==
                                              undefined &&
                                              selectedPropertyGroup[printItemIndex].map(
                                                (thisIsGroup) => {
                                                  let theGroup =
                                                    propertyGroupForSearch &&
                                                    propertyGroupForSearch.find(
                                                      (theItem) => {
                                                        return theItem.id === thisIsGroup;
                                                      }
                                                    );
                                                  return (
                                                    <div className="">
                                                      <span className="">
                                                        {theGroup && theGroup.name} :
                                                      </span>
                                                      {printItem.properties.map(
                                                        (propertyName, propertyIndex) => {
                                                          if (
                                                            parseInt(
                                                              propertyName.item
                                                                .property_group_id
                                                            ) === theGroup.id
                                                          ) {
                                                            return (
                                                              <span className="">
                                                                {propertyName.item.name}{" "}
                                                                <span>
                                                                  {" "}
                                                                  {propertyName.quantity >
                                                                    1 &&
                                                                    "(" +
                                                                    propertyName.quantity +
                                                                    ")"}
                                                                </span>
                                                                {printItem.properties
                                                                  .length -
                                                                  1 !==
                                                                  propertyIndex && ","}
                                                              </span>
                                                            );
                                                          } else {
                                                            return true;
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </td>
                                          <td className="mb-0 xxsm-text fk-print-text text-center">
                                            {printItem.quantity}
                                          </td>
                                        </tr>
                                        :
                                        printItem.destine == 1 ?
                                          <tr style={{ borderBottom: "solid 1px black" }}>
                                            <td className="mb-0 xxsm-text fk-print-text">
                                              <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>
                                              {/* properties */}
                                              {printItem.properties &&
                                                printItem.properties.length > 0 &&
                                                selectedPropertyGroup[printItemIndex] !==
                                                undefined &&
                                                selectedPropertyGroup[printItemIndex].map(
                                                  (thisIsGroup) => {
                                                    let theGroup =
                                                      propertyGroupForSearch &&
                                                      propertyGroupForSearch.find(
                                                        (theItem) => {
                                                          return theItem.id === thisIsGroup;
                                                        }
                                                      );
                                                    return (
                                                      <div className="d-block xxsm-text fk-print-text">
                                                        <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                          {theGroup && theGroup.name} :
                                                        </span>
                                                        {printItem.properties.map(
                                                          (propertyName, propertyIndex) => {
                                                            if (
                                                              parseInt(
                                                                propertyName.item
                                                                  .property_group_id
                                                              ) === theGroup.id
                                                            ) {
                                                              return (
                                                                <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                  {propertyName.item.name}{" "}
                                                                  <span>
                                                                    {" "}
                                                                    {propertyName.quantity >
                                                                      1 &&
                                                                      "(" +
                                                                      propertyName.quantity +
                                                                      ")"}
                                                                  </span>
                                                                  {printItem.properties
                                                                    .length -
                                                                    1 !==
                                                                    propertyIndex && ","}
                                                                </span>
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </td>
                                            <td className="mb-0 xxsm-text fk-print-text text-center">
                                              {printItem.quantity}
                                            </td>
                                          </tr>
                                          :
                                          null
                                    )
                                  })
                                  :
                                  exisnewitems ?
                                    newOrder.map((printItem, printItemIndex) => {
                                      return (
                                        printItem.item && printItem.item.destine == 1 && !printItem.item_order_id ?
                                          <tr style={{ borderBottom: "solid 1px black" }}>
                                            <td className="mb-0 xxsm-text fk-print-text">
                                              <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                              {/* properties */}
                                              {printItem.properties &&
                                                printItem.properties.length > 0 &&
                                                selectedPropertyGroup[printItemIndex] !==
                                                undefined &&
                                                selectedPropertyGroup[printItemIndex].map(
                                                  (thisIsGroup) => {
                                                    let theGroup =
                                                      propertyGroupForSearch &&
                                                      propertyGroupForSearch.find(
                                                        (theItem) => {
                                                          return theItem.id === thisIsGroup;
                                                        }
                                                      );
                                                    return (
                                                      <div className="d-block xxsm-text fk-print-text">
                                                        <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                          {theGroup && theGroup.name} :
                                                        </span>
                                                        {printItem.properties.map(
                                                          (propertyName, propertyIndex) => {
                                                            if (
                                                              parseInt(
                                                                propertyName.item
                                                                  .property_group_id
                                                              ) === theGroup.id
                                                            ) {
                                                              return (
                                                                <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                  {propertyName.item.name}{" "}
                                                                  <span>
                                                                    {" "}
                                                                    {propertyName.quantity >
                                                                      1 &&
                                                                      "(" +
                                                                      propertyName.quantity +
                                                                      ")"}
                                                                  </span>
                                                                  {printItem.properties
                                                                    .length -
                                                                    1 !==
                                                                    propertyIndex && ","}
                                                                </span>
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </td>
                                            <td className="mb-0 xxsm-text fk-print-text text-center">
                                              {printItem.quantity}
                                            </td>
                                          </tr>
                                          :
                                          printItem.destine == 1 && !printItem.item_order_id ?
                                            <tr style={{ borderBottom: "solid 1px black" }}>
                                              <td className="mb-0 xxsm-text fk-print-text">
                                                <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                                {/* properties */}
                                                {printItem.properties &&
                                                  printItem.properties.length > 0 &&
                                                  selectedPropertyGroup[printItemIndex] !==
                                                  undefined &&
                                                  selectedPropertyGroup[printItemIndex].map(
                                                    (thisIsGroup) => {
                                                      let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return theItem.id === thisIsGroup;
                                                          }
                                                        );
                                                      return (
                                                        <div className="d-block xxsm-text fk-print-text">
                                                          <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                            {theGroup && theGroup.name} :
                                                          </span>
                                                          {printItem.properties.map(
                                                            (propertyName, propertyIndex) => {
                                                              if (
                                                                parseInt(
                                                                  propertyName.item
                                                                    .property_group_id
                                                                ) === theGroup.id
                                                              ) {
                                                                return (
                                                                  <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                    {propertyName.item.name}{" "}
                                                                    <span>
                                                                      {" "}
                                                                      {propertyName.quantity >
                                                                        1 &&
                                                                        "(" +
                                                                        propertyName.quantity +
                                                                        ")"}
                                                                    </span>
                                                                    {printItem.properties
                                                                      .length -
                                                                      1 !==
                                                                      propertyIndex && ","}
                                                                  </span>
                                                                );
                                                              } else {
                                                                return true;
                                                              }
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </td>
                                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                                {printItem.quantity}
                                              </td>
                                            </tr>
                                            :
                                            null
                                      )
                                    })
                                    :
                                    newOrder.map((printItem, printItemIndex) => {
                                      return (
                                        printItem.item && printItem.item.destine == 1 ?
                                          <tr style={{ borderBottom: "solid 1px black" }}>
                                            <td className="mb-0 xxsm-text fk-print-text">
                                              <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                              {/* properties */}
                                              {printItem.properties &&
                                                printItem.properties.length > 0 &&
                                                selectedPropertyGroup[printItemIndex] !==
                                                undefined &&
                                                selectedPropertyGroup[printItemIndex].map(
                                                  (thisIsGroup) => {
                                                    let theGroup =
                                                      propertyGroupForSearch &&
                                                      propertyGroupForSearch.find(
                                                        (theItem) => {
                                                          return theItem.id === thisIsGroup;
                                                        }
                                                      );
                                                    return (
                                                      <div className="d-block xxsm-text fk-print-text">
                                                        <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                          {theGroup && theGroup.name} :
                                                        </span>
                                                        {printItem.properties.map(
                                                          (propertyName, propertyIndex) => {
                                                            if (
                                                              parseInt(
                                                                propertyName.item
                                                                  .property_group_id
                                                              ) === theGroup.id
                                                            ) {
                                                              return (
                                                                <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                  {propertyName.item.name}{" "}
                                                                  <span>
                                                                    {" "}
                                                                    {propertyName.quantity >
                                                                      1 &&
                                                                      "(" +
                                                                      propertyName.quantity +
                                                                      ")"}
                                                                  </span>
                                                                  {printItem.properties
                                                                    .length -
                                                                    1 !==
                                                                    propertyIndex && ","}
                                                                </span>
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </td>
                                            <td className="mb-0 xxsm-text fk-print-text text-center">
                                              {printItem.quantity}
                                            </td>
                                          </tr>
                                          :
                                          printItem.destine == 1 ?
                                            <tr style={{ borderBottom: "solid 1px black" }}>
                                              <td className="mb-0 xxsm-text fk-print-text">
                                                <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>
                                                {/* properties */}
                                                {printItem.properties &&
                                                  printItem.properties.length > 0 &&
                                                  selectedPropertyGroup[printItemIndex] !==
                                                  undefined &&
                                                  selectedPropertyGroup[printItemIndex].map(
                                                    (thisIsGroup) => {
                                                      let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return theItem.id === thisIsGroup;
                                                          }
                                                        );
                                                      return (
                                                        <div className="d-block xxsm-text fk-print-text">
                                                          <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                            {theGroup && theGroup.name} :
                                                          </span>
                                                          {printItem.properties.map(
                                                            (propertyName, propertyIndex) => {
                                                              if (
                                                                parseInt(
                                                                  propertyName.item
                                                                    .property_group_id
                                                                ) === theGroup.id
                                                              ) {
                                                                return (
                                                                  <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                    {propertyName.item.name}{" "}
                                                                    <span>
                                                                      {" "}
                                                                      {propertyName.quantity >
                                                                        1 &&
                                                                        "(" +
                                                                        propertyName.quantity +
                                                                        ")"}
                                                                    </span>
                                                                    {printItem.properties
                                                                      .length -
                                                                      1 !==
                                                                      propertyIndex && ","}
                                                                  </span>
                                                                );
                                                              } else {
                                                                return true;
                                                              }
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </td>
                                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                                {printItem.quantity}
                                              </td>
                                            </tr>
                                            :
                                            null
                                      )
                                    })
                              }
                            </tbody>
                          </table>
                        </>
                        :
                        null
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-none">
        <div ref={component3Ref}>
          {newOrder && (
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    {
                      barcocina.bar ?
                        <>
                          <span className="sm-text d-block fk-print-text fk-print-text--bold text-uppercase text-center">
                            {_t(t("Orden"))}: {orderDetails && formartid(orderDetails.token.id)}
                          </span>
                          <p className="mb-0 xxsm-text fk-print-text">
                            {_t(t("Fecha"))}: <Moment format="DD/MM/YYYY HH:mm A">{new Date()}</Moment>
                          </p>
                          {orderDetails && orderDetails.user_name ?
                            <p className="mb-0 xxsm-text fk-print-text">
                              {_t("Mesero")}:{" "}{orderDetails && orderDetails.user_name}
                            </p> : null
                          }
                          {orderDetails && orderDetails.parat ?
                            <p className="mb-0 xxsm-text fk-print-text">
                              {_t(t("Tdo."))}:{" "}{orderDetails.parat}
                            </p>
                            : null}
                          {orderDetails && orderDetails.table_na != "" ?
                            <p className="mb-0 xxsm-text fk-print-text">
                              {_t(t("Mesa"))}:{" "}{orderDetails.table_na}
                            </p>
                            : null}
                          <table >
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="mb-0 xxsm-text fk-print-text"
                                >
                                  {_t(t("Platillo"))}
                                </th>
                                <th
                                  scope="col"
                                  className="mb-0 xxsm-text fk-print-text text-center"
                                >
                                  {_t(t("Cantidad"))}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="mb-0 xxsm-text fk-print-text text-center">
                                <span className="mb-0 xxsm-text fk-print-text fk-print-text--bold text-uppercase">
                                  {'Destino Barra'}
                                </span>
                              </tr>
                              {
                                btnupdatesave == "create" ?
                                  newOrder.map((printItem, printItemIndex) => {
                                    return (
                                      printItem.item && printItem.item.destine_bar == 1 ?
                                        <tr style={{ borderBottom: "solid 1px black" }}>
                                          <td className="mb-0 xxsm-text fk-print-text">
                                            <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                            {/* properties */}
                                            {printItem.properties &&
                                              printItem.properties.length > 0 &&
                                              selectedPropertyGroup[printItemIndex] !==
                                              undefined &&
                                              selectedPropertyGroup[printItemIndex].map(
                                                (thisIsGroup) => {
                                                  let theGroup =
                                                    propertyGroupForSearch &&
                                                    propertyGroupForSearch.find(
                                                      (theItem) => {
                                                        return theItem.id === thisIsGroup;
                                                      }
                                                    );
                                                  return (
                                                    <div className="d-block xxsm-text fk-print-text">
                                                      <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                        {theGroup && theGroup.name} :
                                                      </span>
                                                      {printItem.properties.map(
                                                        (propertyName, propertyIndex) => {
                                                          if (
                                                            parseInt(
                                                              propertyName.item
                                                                .property_group_id
                                                            ) === theGroup.id
                                                          ) {
                                                            return (
                                                              <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                {propertyName.item.name}{" "}
                                                                <span>
                                                                  {" "}
                                                                  {propertyName.quantity >
                                                                    1 &&
                                                                    "(" +
                                                                    propertyName.quantity +
                                                                    ")"}
                                                                </span>
                                                                {printItem.properties
                                                                  .length -
                                                                  1 !==
                                                                  propertyIndex && ","}
                                                              </span>
                                                            );
                                                          } else {
                                                            return true;
                                                          }
                                                        }
                                                      )}
                                                    </div>
                                                  );
                                                }
                                              )}
                                          </td>
                                          <td className="mb-0 xxsm-text fk-print-text text-center">
                                            {printItem.quantity}
                                          </td>
                                        </tr>
                                        :
                                        printItem.destine_bar == 1 ?
                                          <tr style={{ borderBottom: "solid 1px black" }}>
                                            <td className="mb-0 xxsm-text fk-print-text">
                                              <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                              {/* properties */}
                                              {printItem.properties &&
                                                printItem.properties.length > 0 &&
                                                selectedPropertyGroup[printItemIndex] !==
                                                undefined &&
                                                selectedPropertyGroup[printItemIndex].map(
                                                  (thisIsGroup) => {
                                                    let theGroup =
                                                      propertyGroupForSearch &&
                                                      propertyGroupForSearch.find(
                                                        (theItem) => {
                                                          return theItem.id === thisIsGroup;
                                                        }
                                                      );
                                                    return (
                                                      <div className="d-block xxsm-text fk-print-text">
                                                        <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                          {theGroup && theGroup.name} :
                                                        </span>
                                                        {printItem.properties.map(
                                                          (propertyName, propertyIndex) => {
                                                            if (
                                                              parseInt(
                                                                propertyName.item
                                                                  .property_group_id
                                                              ) === theGroup.id
                                                            ) {
                                                              return (
                                                                <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                  {propertyName.item.name}{" "}
                                                                  <span>
                                                                    {" "}
                                                                    {propertyName.quantity >
                                                                      1 &&
                                                                      "(" +
                                                                      propertyName.quantity +
                                                                      ")"}
                                                                  </span>
                                                                  {printItem.properties
                                                                    .length -
                                                                    1 !==
                                                                    propertyIndex && ","}
                                                                </span>
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </td>
                                            <td className="mb-0 xxsm-text fk-print-text text-center">
                                              {printItem.quantity}
                                            </td>
                                          </tr>
                                          :
                                          null
                                    )
                                  })
                                  :
                                  exisnewitems ?
                                    newOrder.map((printItem, printItemIndex) => {
                                      return (
                                        printItem.item && printItem.item.destine_bar == 1 && !printItem.item_order_id ?
                                          <tr style={{ borderBottom: "solid 1px black" }}>
                                            <td className="fk-print-text text-capitalize">
                                              <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                              {/* properties */}
                                              {printItem.properties &&
                                                printItem.properties.length > 0 &&
                                                selectedPropertyGroup[printItemIndex] !==
                                                undefined &&
                                                selectedPropertyGroup[printItemIndex].map(
                                                  (thisIsGroup) => {
                                                    let theGroup =
                                                      propertyGroupForSearch &&
                                                      propertyGroupForSearch.find(
                                                        (theItem) => {
                                                          return theItem.id === thisIsGroup;
                                                        }
                                                      );
                                                    return (
                                                      <div className="d-block xxsm-text fk-print-text">
                                                        <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                          {theGroup && theGroup.name} :
                                                        </span>
                                                        {printItem.properties.map(
                                                          (propertyName, propertyIndex) => {
                                                            if (
                                                              parseInt(
                                                                propertyName.item
                                                                  .property_group_id
                                                              ) === theGroup.id
                                                            ) {
                                                              return (
                                                                <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                  {propertyName.item.name}{" "}
                                                                  <span>
                                                                    {" "}
                                                                    {propertyName.quantity >
                                                                      1 &&
                                                                      "(" +
                                                                      propertyName.quantity +
                                                                      ")"}
                                                                  </span>
                                                                  {printItem.properties
                                                                    .length -
                                                                    1 !==
                                                                    propertyIndex && ","}
                                                                </span>
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </td>
                                            <td className="mb-0 xxsm-text fk-print-text text-center">
                                              {printItem.quantity}
                                            </td>
                                          </tr>
                                          :
                                          printItem.destine_bar == 1 && !printItem.item_order_id ?
                                            <tr style={{ borderBottom: "solid 1px black" }}>
                                              <td className="mb-0 xxsm-text fk-print-text">
                                                <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                                {/* properties */}
                                                {printItem.properties &&
                                                  printItem.properties.length > 0 &&
                                                  selectedPropertyGroup[printItemIndex] !==
                                                  undefined &&
                                                  selectedPropertyGroup[printItemIndex].map(
                                                    (thisIsGroup) => {
                                                      let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return theItem.id === thisIsGroup;
                                                          }
                                                        );
                                                      return (
                                                        <div className="d-block xxsm-text fk-print-text">
                                                          <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                            {theGroup && theGroup.name} :
                                                          </span>
                                                          {printItem.properties.map(
                                                            (propertyName, propertyIndex) => {
                                                              if (
                                                                parseInt(
                                                                  propertyName.item
                                                                    .property_group_id
                                                                ) === theGroup.id
                                                              ) {
                                                                return (
                                                                  <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                    {propertyName.item.name}{" "}
                                                                    <span>
                                                                      {" "}
                                                                      {propertyName.quantity >
                                                                        1 &&
                                                                        "(" +
                                                                        propertyName.quantity +
                                                                        ")"}
                                                                    </span>
                                                                    {printItem.properties
                                                                      .length -
                                                                      1 !==
                                                                      propertyIndex && ","}
                                                                  </span>
                                                                );
                                                              } else {
                                                                return true;
                                                              }
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </td>
                                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                                {printItem.quantity}
                                              </td>
                                            </tr>
                                            :
                                            null
                                      )
                                    })
                                    :
                                    newOrder.map((printItem, printItemIndex) => {
                                      return (
                                        printItem.item && printItem.item.destine_bar == 1 ?
                                          <tr style={{ borderBottom: "solid 1px black" }}>
                                            <td className="mb-0 xxsm-text fk-print-text">
                                              <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                              {/* properties */}
                                              {printItem.properties &&
                                                printItem.properties.length > 0 &&
                                                selectedPropertyGroup[printItemIndex] !==
                                                undefined &&
                                                selectedPropertyGroup[printItemIndex].map(
                                                  (thisIsGroup) => {
                                                    let theGroup =
                                                      propertyGroupForSearch &&
                                                      propertyGroupForSearch.find(
                                                        (theItem) => {
                                                          return theItem.id === thisIsGroup;
                                                        }
                                                      );
                                                    return (
                                                      <div className="d-block xxsm-text fk-print-text">
                                                        <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                          {theGroup && theGroup.name} :
                                                        </span>
                                                        {printItem.properties.map(
                                                          (propertyName, propertyIndex) => {
                                                            if (
                                                              parseInt(
                                                                propertyName.item
                                                                  .property_group_id
                                                              ) === theGroup.id
                                                            ) {
                                                              return (
                                                                <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                  {propertyName.item.name}{" "}
                                                                  <span>
                                                                    {" "}
                                                                    {propertyName.quantity >
                                                                      1 &&
                                                                      "(" +
                                                                      propertyName.quantity +
                                                                      ")"}
                                                                  </span>
                                                                  {printItem.properties
                                                                    .length -
                                                                    1 !==
                                                                    propertyIndex && ","}
                                                                </span>
                                                              );
                                                            } else {
                                                              return true;
                                                            }
                                                          }
                                                        )}
                                                      </div>
                                                    );
                                                  }
                                                )}
                                            </td>
                                            <td className="mb-0 xxsm-text fk-print-text text-center">
                                              {printItem.quantity}
                                            </td>
                                          </tr>
                                          :
                                          printItem.destine_bar == 1 ?
                                            <tr style={{ borderBottom: "solid 1px black" }}>
                                              <td className="mb-0 xxsm-text fk-print-text">
                                                <span className="d-block xxsm-text fk-print-text">{printItem.item.name}</span>

                                                {/* properties */}
                                                {printItem.properties &&
                                                  printItem.properties.length > 0 &&
                                                  selectedPropertyGroup[printItemIndex] !==
                                                  undefined &&
                                                  selectedPropertyGroup[printItemIndex].map(
                                                    (thisIsGroup) => {
                                                      let theGroup =
                                                        propertyGroupForSearch &&
                                                        propertyGroupForSearch.find(
                                                          (theItem) => {
                                                            return theItem.id === thisIsGroup;
                                                          }
                                                        );
                                                      return (
                                                        <div className="d-block xxsm-text fk-print-text">
                                                          <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                            {theGroup && theGroup.name} :
                                                          </span>
                                                          {printItem.properties.map(
                                                            (propertyName, propertyIndex) => {
                                                              if (
                                                                parseInt(
                                                                  propertyName.item
                                                                    .property_group_id
                                                                ) === theGroup.id
                                                              ) {
                                                                return (
                                                                  <span className="xxsm-text fk-print-text d-inline-block mr-1">
                                                                    {propertyName.item.name}{" "}
                                                                    <span>
                                                                      {" "}
                                                                      {propertyName.quantity >
                                                                        1 &&
                                                                        "(" +
                                                                        propertyName.quantity +
                                                                        ")"}
                                                                    </span>
                                                                    {printItem.properties
                                                                      .length -
                                                                      1 !==
                                                                      propertyIndex && ","}
                                                                  </span>
                                                                );
                                                              } else {
                                                                return true;
                                                              }
                                                            }
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </td>
                                              <td className="mb-0 xxsm-text fk-print-text text-center">
                                                {printItem.quantity}
                                              </td>
                                            </tr>
                                            :
                                            null
                                      )
                                    })
                              }
                            </tbody>
                          </table>
                        </>
                        :
                        null
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Menu Addons  */}
      <div className="modal fade" id="menuAddons" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header align-items-start">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {foodItem.selectedItem !== null && foodItem.selectedItem.name}
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Variations */}
              <div className="fk-sm-card__container">
                <div className="fk-sm-card__content">
                  <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                    {_t(t("Base del platillo"))}
                  </h6>
                </div>
                {foodItem.variations && (
                  <span className="text-capitalize xxsm-text fk-badge fk-badge--secondary">
                    {_t(t("Required"))}
                  </span>
                )}
              </div>
              <hr />
              <ul className="t-list addons-list">
                {foodItem.variations ? (
                  <>
                    {foodItem.variations.map((variationItem) => {
                      return (
                        <li className="addons-list__item">
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="col">
                              <label className="mx-checkbox flex-grow-1">
                                <input
                                  type="checkbox"
                                  className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                  name="variation"
                                  onChange={() => {
                                    handleOrderItemVariation(variationItem);
                                  }}
                                  checked={checkChecked(variationItem)}
                                />
                                <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                  {variationItem.variation_name}
                                </span>
                              </label>
                            </div>
                            <div className="col text-right">
                              <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                {currencySymbolLeft()}
                                {formatPrice(
                                  variationItem.food_with_variation_price
                                )}
                                {currencySymbolRight()}
                              </span>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li className="addons-list__item">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="col text-center text-primary text-uppercase">
                        {_t(t("No hay bases del platillo"))}
                      </div>
                    </div>
                  </li>
                )}
                {/* Variations end*/}
              </ul>

              <div className={foodItem.properties ? "" : "d-none"}>
                {/* Property group and items */}
                {foodItem.properties && (
                  <>
                    {foodItem.properties.length > 0 ? (
                      foodItem.properties.map((propertyItem) => {
                        let selectedGroup = null;
                        //property group
                        if (propertyItem.length > 0) {
                          selectedGroup =
                            propertyGroupForSearch &&
                            propertyGroupForSearch.find(
                              (singlePropertyGroup) => {
                                return (
                                  singlePropertyGroup.id ===
                                  parseInt(propertyItem[0].property_group_id)
                                );
                              }
                            );
                        }

                        return (
                          <>
                            <div className="fk-sm-card__container t-mt-30">
                              <div className="fk-sm-card__content">
                                <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                                  {selectedGroup && selectedGroup.name}
                                </h6>
                              </div>
                              <span className="text-capitalize xxsm-text fk-badge fk-badge--secondary">
                                {_t(t("Optional"))}
                              </span>
                            </div>
                            <hr />
                            <ul className="t-list addons-list">
                              {propertyItem.map((eachItem) => {
                                return (
                                  <li className="addons-list__item">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <div className="col">
                                        <label className="mx-checkbox flex-grow-1">
                                          <input
                                            type="checkbox"
                                            className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm mt-0-kitchen"
                                            onChange={() => {
                                              newOrder &&
                                                newOrder.map(
                                                  (newOrderItem, index) => {
                                                    if (
                                                      index ===
                                                      activeItemInOrder
                                                    ) {
                                                      if (
                                                        newOrderItem.properties
                                                      ) {
                                                        let theItem = newOrderItem.properties.find(
                                                          (
                                                            eachPropertyItem
                                                          ) => {
                                                            return (
                                                              eachPropertyItem
                                                                .item.id ===
                                                              eachItem.id
                                                            );
                                                          }
                                                        );

                                                        if (
                                                          theItem === undefined
                                                        ) {
                                                          handleAddProperties(
                                                            eachItem
                                                          );
                                                        } else {
                                                          handleRemoveProperties(
                                                            eachItem
                                                          );
                                                        }
                                                      } else {
                                                        handleAddProperties(
                                                          eachItem
                                                        );
                                                      }
                                                    }
                                                  }
                                                );
                                            }}
                                            checked={checkCheckedProperties(
                                              eachItem
                                            )}
                                          />
                                          <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8">
                                            {eachItem.name}
                                          </span>
                                        </label>
                                      </div>
                                      <div className="col">
                                        <div className="fk-qty flex-grow-1 justify-content-end">
                                          {eachItem.allow_multi_quantity ===
                                            1 && (
                                              <span
                                                className="fk-qty__icon fk-qty__deduct"
                                                onClick={() => {
                                                  handlePropertyQty(
                                                    eachItem,
                                                    "-"
                                                  );
                                                }}
                                              >
                                                <i className="las la-minus"></i>
                                              </span>
                                            )}
                                          {eachItem.allow_multi_quantity ===
                                            1 ? (
                                            <input
                                              type="text"
                                              value={checkCheckedPropertyQuantity(
                                                eachItem
                                              )}
                                              className="fk-qty__input t-bg-clear"
                                              readOnly
                                            />
                                          ) : (
                                            "-"
                                          )}
                                          {eachItem.allow_multi_quantity ===
                                            1 && (
                                              <span
                                                className="fk-qty__icon fk-qty__add"
                                                onClick={() => {
                                                  handlePropertyQty(
                                                    eachItem,
                                                    "+"
                                                  );
                                                }}
                                              >
                                                <i className="las la-plus"></i>
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col text-right">
                                        <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                          {currencySymbolLeft()}
                                          {formatPrice(eachItem.extra_price)}
                                          {currencySymbolRight()}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                            <hr />
                          </>
                        );
                      })
                    ) : (
                      <li className="addons-list__item">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="col text-center text-primary text-uppercase">
                            {_t(t("No properties"))}
                          </div>
                        </div>
                      </li>
                    )}
                  </>
                )}
              </div>
              {/* Property group and items */}
            </div>
          </div>
        </div>
      </div>
      {/* Menu Addons End */}

      {/* Show Cart  */}
      <div className="modal fade" id="showCart" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <h3>{_t(t("Order details"))}</h3>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <span className="sm-text font-weight-bold text-uppercase font-italic">
                    {_t(t("Orden ticket"))}:{" "}
                    {newOrder ? (
                      <>
                        #{formartid(orderDetails.token.id)} -{" "}
                        <Moment format="LT">{orderDetails.token.time}</Moment>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div className="fk-sm-card__container t-mt-30">
                <div className="fk-sm-card__content">
                  <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                    {_t(t("order items"))}
                  </h6>
                </div>
              </div>
              <hr />
              <ul className="t-list addons-list">
                {/* loop through order list items */}
                {newOrder && newOrder.length > 0 ? (
                  newOrder.map((orderListItem, orderListItemIndex) => {
                    return (
                      <>
                        <li
                          className={`addons-list__item px-1 ${orderListItemIndex === activeItemInOrder && "active"
                            }`}
                        >
                          <div
                            className="d-flex align-items-center justify-content-between"
                            onClick={(e) => {
                              e.preventDefault();
                              //orderListItem's group wise all items
                              let tempItems =
                                foodForSearch &&
                                foodForSearch.filter((tempItem) => {
                                  return (
                                    tempItem.food_group_id ===
                                    orderListItem.item.food_group_id
                                  );
                                });

                              //orderListItem's group
                              let foodGroup =
                                foodGroupForSearch &&
                                foodGroupForSearch.find((groupItem) => {
                                  return (
                                    groupItem.id ===
                                    parseInt(orderListItem.item.food_group_id)
                                  );
                                });

                              // selected pos item
                              let selectedItemTemp =
                                tempItems &&
                                tempItems.find((tempSelectedItem) => {
                                  return (
                                    tempSelectedItem.id ===
                                    orderListItem.item.id
                                  );
                                });

                              // Set variations, properties, selected item
                              setFoodItem({
                                ...foodItem,
                                foodGroup: foodGroup,
                                items: tempItems,
                                selectedItem: selectedItemTemp,
                                variations:
                                  selectedItemTemp &&
                                    parseInt(selectedItemTemp.has_variation) === 1
                                    ? selectedItemTemp.variations
                                    : null,
                                properties:
                                  selectedItemTemp &&
                                    parseInt(selectedItemTemp.has_property) === 1
                                    ? selectedItemTemp.properties
                                    : null,
                              });

                              //set active order list index for background color of selected
                              setActiveItemInOrder(orderListItemIndex);
                            }}
                          >
                            <div className="col">
                              <span
                                className={`t-text-heading sm-text text-capitalize d-block ${orderListItemIndex === activeItemInOrder &&
                                  "text-white"
                                  }`}
                              >
                                {orderListItem.item.name}
                              </span>
                              {parseInt(orderListItem.item.has_variation) ===
                                1 &&
                                orderListItem.variation && (
                                  <span
                                    className={`xxsm-text text-capitalize d-block ${orderListItemIndex ===
                                      activeItemInOrder && "text-white"
                                      }`}
                                  >
                                    {_t(t("Bases del platillo"))}:{" "}
                                    {orderListItem.variation.variation_name}
                                  </span>
                                )}

                              {/* properties */}
                              {orderListItem.properties &&
                                orderListItem.properties.length > 0 &&
                                selectedPropertyGroup[orderListItemIndex] !==
                                undefined &&
                                selectedPropertyGroup[orderListItemIndex].map(
                                  (thisIsGroup) => {
                                    let theGroup =
                                      propertyGroupForSearch &&
                                      propertyGroupForSearch.find((theItem) => {
                                        return theItem.id === thisIsGroup;
                                      });
                                    return (
                                      <div className="d-block">
                                        <span className="text-capitalize xxsm-text d-inline-block mr-1">
                                          {theGroup && theGroup.name} :
                                        </span>
                                        {orderListItem.properties.map(
                                          (propertyName, propertyIndex) => {
                                            if (
                                              parseInt(
                                                propertyName.item
                                                  .property_group_id
                                              ) === theGroup.id
                                            ) {
                                              return (
                                                <span className="text-capitalize xxsm-text d-inline-block mr-1">
                                                  {propertyName.item.name}{" "}
                                                  <span>
                                                    {" "}
                                                    {propertyName.quantity >
                                                      1 &&
                                                      "(" +
                                                      propertyName.quantity +
                                                      ")"}
                                                  </span>
                                                  {orderListItem.properties
                                                    .length -
                                                    1 !==
                                                    propertyIndex && ","}
                                                </span>
                                              );
                                            } else {
                                              return true;
                                            }
                                          }
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                            </div>

                            {/* Quantity */}
                            <div className="col">
                              <div className="fk-qty flex-grow-1 justify-content-end">
                                <span
                                  className="fk-qty__icon"
                                  onClick={() => {
                                    handleOrderItemQty("-");
                                  }}
                                >
                                  <i className="las la-minus"></i>
                                </span>
                                <input
                                  type="text"
                                  value={orderListItem.quantity}
                                  className={`fk-qty__input t-bg-clear ${orderListItemIndex === activeItemInOrder &&
                                    "text-white"
                                    }`}
                                  readOnly
                                />
                                <span
                                  className="fk-qty__icon"
                                  onClick={() => {
                                    handleOrderItemQty("+");
                                  }}
                                >
                                  <i className="las la-plus"></i>
                                </span>
                              </div>
                            </div>

                            <div className="col text-right">
                              <span
                                className={`t-text-heading text-uppercase sm-text flex-grow-1 ${orderListItemIndex === activeItemInOrder &&
                                  "text-white"
                                  }`}
                              >
                                {parseInt(orderListItem.item.has_variation) ===
                                  1 ? (
                                  <>
                                    {currencySymbolLeft()}

                                    {showPriceOfEachOrderItem(
                                      orderListItemIndex
                                    )}
                                    {currencySymbolRight()}
                                  </>
                                ) : (
                                  <>
                                    {currencySymbolLeft()}
                                    {showPriceOfEachOrderItem(
                                      orderListItemIndex
                                    )}
                                    {currencySymbolRight()}
                                  </>
                                )}
                              </span>
                            </div>
                          </div>
                        </li>
                        <span className="text-capitalize  t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold make-this-relative-mobile">
                          <span
                            className="badge rounded-pill bg-secondary text-capitalize"
                            onClick={() => {
                              handleRemoveItemFromOrderList(orderListItemIndex);
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </span>
                      </>
                    );
                  })
                ) : (
                  <div className="text-primary text-center font-weight-bold pt-2 xsm-text text-uppercase">
                    {_t(t("Select food item to add to the list"))}
                  </div>
                )}
              </ul>
              <hr />
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text">
                        {" "}
                        {_t(t("sub total"))}{" "}
                      </span>
                    </div>
                    <div className="col text-center">:</div>
                    <div className="col text-right">
                      <span className="text-capitalize sm-text font-weight-bold">
                        {newOrder ? (
                          <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                            {currencySymbolLeft()}
                            {formatPrice(theSubTotal)}
                            {currencySymbolRight()}
                          </span>
                        ) : (
                          <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                            {currencySymbolLeft()}
                            {formatPrice(0)}
                            {currencySymbolRight()}
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text">
                        {_t(t("VAT"))} ({newSettings && newSettings.vat}%)
                      </span>
                    </div>
                    <div className="col text-center">:</div>
                    <div className="col text-right">
                      <span className="text-capitalize sm-text font-weight-bold">
                        {currencySymbolLeft()}
                        {formatPrice(theVat)}
                        {currencySymbolRight()}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-2">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text">
                        {" "}
                        {_t(t("Delivery"))}{" "}
                      </span>
                    </div>
                    <div className="col text-center">:</div>
                    <div className="col text-right">
                      <span className="text-capitalize sm-text font-weight-bold">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          className="text-capitalize xsm-text d-inline-block font-weight-bold form-control rounded-0 text-right"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              setOrderDetails({
                                ...orderDetails,
                                serviceCharge: parseFloat(
                                  e.target.value
                                ),
                              });
                              let totalPayable = 0;
                              let localCurrency = JSON.parse(
                                localStorage.getItem("currency")
                              );
                              let usdServiceCharge =
                                parseFloat(e.target.value) /
                                localCurrency.rate;
                              let usdDiscount =
                                parseFloat(
                                  orderDetails.discount
                                ) / localCurrency.rate;

                              totalPayable =
                                theSubTotal +
                                theVat +
                                parseFloat(orderDetails.propina_value) +
                                usdServiceCharge -
                                usdDiscount;
                              setTotalPaybale(totalPayable);
                              setReturnMoneyUsd(
                                totalPayable - paidMoney
                              );
                            } else {
                              setOrderDetails({
                                ...orderDetails,
                                serviceCharge: 0,
                              });
                              let totalPayable = 0;
                              let localCurrency = JSON.parse(
                                localStorage.getItem("currency")
                              );
                              let usdServiceCharge =
                                parseFloat(0) /
                                localCurrency.rate;
                              let usdDiscount =
                                parseFloat(
                                  orderDetails.discount
                                ) / localCurrency.rate;

                              totalPayable =
                                theSubTotal +
                                theVat +
                                parseFloat(orderDetails.propina_value) +
                                usdServiceCharge -
                                usdDiscount;
                              setTotalPaybale(totalPayable);
                              setReturnMoneyUsd(
                                totalPayable - paidMoney
                              );
                            }
                          }}
                          value={
                            orderDetails.serviceCharge !== 0 &&
                            orderDetails.serviceCharge
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text">
                        {" "}
                        {_t(t("discount"))}{" "}
                      </span>
                    </div>
                    <div className="col text-center">:</div>
                    <div className="col text-right">
                      <span className="text-capitalize sm-text font-weight-bold">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          className="text-capitalize xsm-text d-inline-block font-weight-bold form-control rounded-0 text-right"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              setOrderDetails({
                                ...orderDetails,
                                discount: parseFloat(
                                  e.target.value
                                ),
                              });
                              let totalPayable = 0;
                              let localCurrency = JSON.parse(
                                localStorage.getItem("currency")
                              );
                              let usdServiceCharge =
                                parseFloat(
                                  orderDetails.serviceCharge
                                ) / localCurrency.rate;
                              let usdDiscount =
                                parseFloat(e.target.value) /
                                localCurrency.rate;

                              totalPayable =
                                theSubTotal +
                                theVat +
                                parseFloat(orderDetails.propina_value) +
                                usdServiceCharge -
                                usdDiscount;

                              setTotalPaybale(totalPayable);
                              setReturnMoneyUsd(
                                totalPayable - paidMoney
                              );
                            } else {
                              setOrderDetails({
                                ...orderDetails,
                                discount: 0,
                              });
                              let totalPayable = 0;
                              let localCurrency = JSON.parse(
                                localStorage.getItem("currency")
                              );
                              let usdServiceCharge =
                                parseFloat(
                                  orderDetails.serviceCharge
                                ) / localCurrency.rate;
                              let usdDiscount =
                                parseFloat(0) /
                                localCurrency.rate;

                              totalPayable =
                                theSubTotal +
                                theVat +
                                parseFloat(orderDetails.propina_value) +
                                usdServiceCharge -
                                usdDiscount;
                              setTotalPaybale(totalPayable);
                              setReturnMoneyUsd(
                                totalPayable - paidMoney
                              );
                            }
                          }}
                          value={
                            orderDetails.discount !== 0 &&
                            orderDetails.discount
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text">
                        {" "}
                        {_t(t("Propina"))}{" "}
                      </span>
                    </div>
                    <div className="col text-center">:</div>
                    <div className="col text-right">
                      <span className="text-capitalize sm-text font-weight-bold">
                        <input
                          type="number"
                          step="0.01"
                          min="0"
                          className="text-capitalize xsm-text d-inline-block font-weight-bold form-control rounded-0 text-right"
                          onChange={(e) => {
                            if (e.target.value !== "") {
                              let totalPayable = 0;
                              let calpro = ((parseFloat(e.target.value) / 100) * theSubTotal).toFixed(2);
                              setOrderDetails({
                                ...orderDetails,
                                propina_: parseFloat(
                                  e.target.value
                                ),
                                propina_value: parseFloat(calpro)
                              });
                              let localCurrency = JSON.parse(
                                localStorage.getItem("currency")
                              );
                              let usdServiceCharge =
                                parseFloat(
                                  orderDetails.serviceCharge
                                ) / localCurrency.rate;
                              let usdDiscount =
                                parseFloat(orderDetails.discount) /
                                localCurrency.rate;

                              totalPayable =
                                theSubTotal +
                                theVat +
                                parseFloat(calpro) +
                                usdServiceCharge -
                                usdDiscount;

                              setTotalPaybale(totalPayable);
                              setReturnMoneyUsd(
                                totalPayable - paidMoney
                              );
                            } else {
                              let localCurrency = JSON.parse(
                                localStorage.getItem("currency")
                              );
                              setOrderDetails({
                                ...orderDetails,
                                propina_: 0,
                                propina_value: 0,
                              });
                              let usdServiceCharge =
                                parseFloat(
                                  orderDetails.serviceCharge
                                ) / localCurrency.rate;
                              let usdDiscount =
                                parseFloat(orderDetails.discount) /
                                localCurrency.rate;

                              let totalPayable = 0;
                              let calpro = ((parseFloat(0) / 100) * theSubTotal).toFixed(2);

                              totalPayable =
                                theSubTotal +
                                theVat +
                                parseFloat(calpro) +
                                usdServiceCharge -
                                usdDiscount;
                              setTotalPaybale(totalPayable);
                              setReturnMoneyUsd(
                                totalPayable - paidMoney
                              );
                            }
                          }}
                          value={
                            orderDetails.propina_ !== 0 &&
                            orderDetails.propina_
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text font-weight-bold">
                        {_t(t("total bill"))}
                      </span>
                    </div>
                    <div className="col text-center">:</div>
                    <div className="col text-right">
                      <span className="text-capitalize sm-text font-weight-bold">
                        {currencySymbolLeft()}
                        {formatPrice(totalPayable)}
                        {currencySymbolRight()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row" style={{ background: "#0cb98b" }}>
                <div className="col-12">
                  <div className="row">
                    <div className="col">
                      <span className="text-capitalize sm-text font-weight-bold">
                        {totalPayable ? (
                          <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "left" }}>
                            {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                            {formatPrice(currencyOperationSecondary() == "/" ? totalPayable / cajadetalle.tasa_cambio : totalPayable * cajadetalle.tasa_cambio)}
                          </span>
                        ) : (
                          <span className="text-capitalize font-weight-bold  text-light  d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "left" }}>
                            {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                            {formatPrice(0)}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="col text-center font-weight-bold text-capitalize" style={{ fontSize: "12px", color: "white" }}>Totales</div>
                    <div className="col text-right">
                      {totalPayable ? (
                        <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "right" }}>
                          {currencySymbolLeft() + " "}
                          {formatPrice(totalPayable)}
                          {currencySymbolRight()}
                        </span>
                      ) : (
                        <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "right" }}>
                          {currencySymbolLeft() + " "}
                          {formatPrice(0)}
                          {currencySymbolRight()}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row" style={totalPayable <= paidMoney ? { background: "#f64e60" } : { background: "#09c2de" }}>

                <div className="col-6 text-left" >

                  {totalPayable <= paidMoney ? (
                    <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                      {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                      {formatPrice(
                        Math.abs(currencyOperationSecondary() == "/" ? returnMoneyUsd / cajadetalle.tasa_cambio : returnMoneyUsd * cajadetalle.tasa_cambio)
                      )}
                      {currencySymbolRight()}
                    </span>
                  ) : (
                    <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                      {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                      {formatPrice(currencyOperationSecondary() == "/" ? (totalPayable - paidMoney) / cajadetalle.tasa_cambio : (totalPayable - paidMoney) * cajadetalle.tasa_cambio)}

                    </span>
                  )}

                </div>
                <div className="col-6  text-right">
                  {totalPayable <= paidMoney ? (
                    <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                      {_t(t("Cambio")) + " " + currencySymbolLeft() + " "}
                      {formatPrice(
                        Math.abs(returnMoneyUsd)
                      )}
                      {currencySymbolRight()}
                    </span>
                  ) : (
                    <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                      {_t(t("Saldo Pendiente")) + " " + currencySymbolLeft() + " "}
                      {formatPrice(totalPayable - paidMoney)}

                    </span>
                  )}
                </div>

              </div>

              <div className="row">
                {orderDetails.payment_type ? (

                  orderDetails.payment_type.map((item) => {
                    return (
                      <div className="row gx-2 align-items-center">
                        <div className="col-6 text-left">
                          {item.name == currencyNameSecondary() ?
                            orderDetails.payment_amount ? (
                              <span className="text-capitalize font-weight-bold d-block " style={{ fontSize: "12px" }}>
                                {item.name + "(" + currencySymbolLeftSecondary() + " " +
                                  (
                                    orderDetails.payment_amount[item.id] == undefined ?
                                      0
                                      :
                                      orderDetails.payment_amount[item.id]
                                  )
                                  + ")"}
                              </span>

                            ) : (
                              <span className="text-capitalize font-weight-bold d-block" style={{ fontSize: "12px" }}>
                                {item.name + "(" + currencySymbolLeftSecondary() + " 0)"}
                              </span>
                            )
                            :
                            <span className="text-capitalize font-weight-bold d-block" style={{ fontSize: "12px" }}>
                              {item.name}
                            </span>
                          }

                        </div>
                        <div className="col-6">
                          {orderDetails.payment_amount ?
                            orderDetails.payment_amount[item.id] == undefined ?
                              < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                {currencySymbolLeft()}
                                {formatPrice(
                                  Math.abs(0)
                                )}
                                {currencySymbolRight()}
                              </span>

                              :
                              item.name == currencyNameSecondary() ?
                                < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    Math.abs(currencyOperationSecondary() == "/" ? orderDetails.payment_amount[item.id] * cajadetalle.tasa_cambio : orderDetails.payment_amount[item.id] / cajadetalle.tasa_cambio)
                                  )}
                                  {currencySymbolRight()}
                                </span>


                                :
                                < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                  {currencySymbolLeft()}
                                  {formatPrice(
                                    Math.abs(orderDetails.payment_amount[item.id])
                                  )}
                                  {currencySymbolRight()}
                                </span>

                            :
                            < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                              {currencySymbolLeft()}
                              {formatPrice(
                                Math.abs(0)
                              )}
                              {currencySymbolRight()}
                            </span>
                          }
                        </div>
                      </div>
                    );
                  }
                  )


                ) : (
                  <span className="text-capitalize font-weight-bold d-block" style={{ fontSize: "12px" }}>
                    esperando
                  </span>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12" style={{ marginBottom: "5px" }}>
                <button
                  onClick={() => {
                    if (newOrder) {
                      if (!barcocina.cocina) {
                        if (barcocina.bar) {
                          handleOnlyPrint3();
                        }
                      } else {
                        handleOnlyPrint2();
                      }
                    } else {
                      toast.error(
                        `${_t(
                          t(
                            "Debe Agregar Productos"
                          )
                        )}`,
                        {
                          position: "bottom-center",
                          closeButton: false,
                          autoClose: 10000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          className:
                            "text-center toast-notification",
                        }
                      );
                    }
                  }}
                  className="w-100 t-heading-font btn btn-outline-danger font-weight-bold text-uppercase sm-text"
                >
                  {_t(t("Comanda"))}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-success xsm-text text-uppercase col-12"
                  onClick={!loading && handleSubmitOrder}
                >
                  {!loading ? 'En Curso' : _t(t("Please wait"))}
                </button>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-primary xsm-text text-uppercase ml-auto mr-1 col-12"
                  onClick={!loading && handleSettleOrder}
                >
                  {_t(t("Factura"))}
                </button>
              </div>

            </div>
            <div className="row" style={{ marginTop: "5px" }}>
              <div className="col-6">
                <button
                  className="btn btn-secondary xsm-text text-uppercase ml-auto mr-1 col-12"
                  data-dismiss="modal"
                  onClick={handleCancelConfirmation}
                >
                  {_t(t("Cancel"))}
                </button>
              </div>
              <div className="col-6">
                <button
                  type="button"
                  style={btnupdatesave == "update" ? { display: "block" } : { display: "none" }}
                  className="btn btn-success xsm-text text-uppercase col-12"
                  onClick={!loading && handleEditOrder}
                >
                  {!loading
                    ? _t(t("Editar Orden"))
                    : _t(t("Please wait"))}
                </button>
                <button
                  type="button"
                  style={btnupdatesave == "update" ? { display: "none" } : { display: "block" }}
                  className="btn btn-success xsm-text text-uppercase col-12"
                  onClick={!loading && handleSubmitOrder}
                >
                  {!loading
                    ? _t(t("Ordenar"))
                    : _t(t("Please wait"))}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Show Cart End */}

      {/* Extra Info  */}
      <div className="modal fade" id="extraInfo" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  {_t(t("additional information"))}
                </h5>
              </div>
              <button
                type="button"
                className="btn btn-success sm-text text-uppercase font-weight-bold"
                data-dismiss="modal"
                aria-label="Close"
              >Guardar</button>
            </div>
            <div className="modal-body">
              <ul className="t-list addons-list">
                {authUserInfo.details &&
                  authUserInfo.details.branch_id == 0 && (
                    <li className="addons-list__item mt-1 mx-1">
                      <Select
                        options={
                          branchForSearch && branchForSearch
                        }
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        classNamePrefix="select"
                        className="xsm-text"
                        onChange={handleSetBranch}
                        maxMenuHeight="200px"
                        placeholder={_t(t("Branch")) + ".."}
                      />
                    </li>
                  )}
                {!loading && (
                  <>
                    {orderDetails.branch !== null && (
                      <>
                        <li
                          className={`addons-list__item mx-1 ${authUserInfo.details &&
                            authUserInfo.details.user_type ===
                            "staff" &&
                            "mt-1"
                            }`}
                        >
                          <Select
                            options={
                              orderDetailUsers.theCustomers !==
                              null &&
                              orderDetailUsers.theCustomers
                            }
                            components={makeAnimated()}
                            getOptionLabel={(option) =>
                              option.name +
                              " (" +
                              option.phn_no +
                              ")"
                            }
                            getOptionValue={(option) =>
                              option.name
                            }
                            classNamePrefix="select"
                            className="xsm-text"
                            onChange={handleSetCustomer}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Customer")) + ".."}
                          />
                        </li>
                        <li className="addons-list__item mx-1 border border-2 rounded-lg">
                          <div className="btn-group w-100">
                            <button
                              type="button"
                              className="fk-right-nav__guest-btn btn w-100 t-bg-white dropdown-toggle new-customer-pos xsm-text pl-2"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              + {_t(t("Customer"))}?
                            </button>
                            <ul className="dropdown-menu w-100 border-0 pt-4 change-background">
                              <li>
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control font-10px rounded-lg"
                                  placeholder="Nombre"
                                  autoComplete="off"
                                  value={
                                    orderDetails.newCustomerInfo
                                      .name
                                  }
                                  onChange={handleNewCustomer}
                                />
                              </li>
                              <li className="pb-2">
                                <input
                                  type="text"
                                  name="number"
                                  className="form-control font-10px mt-2 rounded-lg"
                                  autoComplete="off"
                                  placeholder="Celular"
                                  value={
                                    orderDetails.newCustomerInfo
                                      .number
                                  }
                                  onChange={handleNewCustomer}
                                />
                              </li>
                              <li className="pb-2">
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control font-10px mt-2 rounded-lg"
                                  autoComplete="off"
                                  placeholder="Dirección"
                                  value={
                                    orderDetails.newCustomerInfo
                                      .address
                                  }
                                  onChange={handleNewCustomer}
                                />
                              </li>
                              <li className="pb-1 text-right">
                                <button
                                  className="btn t-bg-white text-dark xsm-text text-uppercase btn-sm py-0 px-2 mr-1"
                                  onClick={() => {
                                    setOrderDetails({
                                      ...orderDetails,
                                      newCustomer: false,
                                      newCustomerInfo: {
                                        name: "",
                                        number: "",
                                        address: "",
                                      },
                                    });
                                  }}
                                >
                                  {_t(t("Cancel"))}
                                </button>
                              </li>
                            </ul>
                          </div>
                        </li>

                        <li className="addons-list__item mx-1">
                          <Select
                            options={
                              deptTagForSearch && deptTagForSearch
                            }
                            components={makeAnimated()}
                            getOptionLabel={(option) =>
                              option.name
                            }
                            getOptionValue={(option) =>
                              option.name
                            }
                            classNamePrefix="select"
                            className="xsm-text"
                            onChange={handleSetDeptTag}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Tipo de Orden")) + ".."}
                          />
                        </li>
                        <li
                          className={`addons-list__item mx-1 payment-type-parent ${orderDetails.payment_type !== null &&
                            "mb-1"
                            }`}
                        >
                          <Select
                            options={
                              paymentTypeForSearch &&
                              paymentTypeForSearch
                            }
                            components={makeAnimated()}
                            getOptionLabel={(option) =>
                              option.name
                            }
                            getOptionValue={(option) =>
                              option.name
                            }
                            classNamePrefix="select"
                            className="xsm-text"
                            onChange={handleSetpaymentType}
                            maxMenuHeight="200px"
                            isMulti
                            backspaceRemovesValue={false}
                            clearIndicator={null}
                            placeholder={_t(t("Payments")) + ".."}
                          />
                        </li>
                        {orderDetails.payment_type !== null && (
                          <div className="border mt-0 mb-2 change-background mx-1 rounded-lg">
                            <div className="xsm-text text-center text-white pt-1">
                              {_t(t("Amount"))}
                            </div>
                            {orderDetails.payment_type.map(
                              (
                                eachPaymentType,
                                paymentTypeIndex
                              ) => {
                                return (
                                  <li className="addons-list__item mx-1 mb-1">
                                    <input
                                      type="number"
                                      min="0"
                                      step="0.01"
                                      name={eachPaymentType.id}
                                      autoComplete="off"
                                      className="form-control xsm-text pl-2"
                                      onChange={
                                        handlePaymentTypeAmount
                                      }
                                      placeholder={
                                        eachPaymentType.name
                                      }
                                      value={
                                        orderDetails.payment_amount &&
                                        orderDetails
                                          .payment_amount[
                                        eachPaymentType.id
                                        ]
                                      }
                                    />
                                  </li>
                                );
                              }
                            )}
                          </div>
                        )}

                        <li className="addons-list__item mx-1">
                          <Select
                            options={
                              orderDetailUsers.theTables !==
                              null && orderDetailUsers.theTables
                            }
                            components={makeAnimated()}
                            getOptionLabel={(option) =>
                              option.name
                            }
                            getOptionValue={(option) =>
                              option.name
                            }
                            classNamePrefix="select"
                            className="xsm-text"
                            onChange={handleSetTable}
                            maxMenuHeight="200px"
                            placeholder={_t(t("Table")) + ".."}
                          />
                        </li>
                        {/*<li className="addons-list__item mx-1">
                                        <Select
                                          options={
                                            orderDetailUsers.theWaiters !==
                                            null &&
                                            orderDetailUsers.theWaiters
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={handleSetWaiter}
                                          maxMenuHeight="200px"
                                          placeholder={_t(t("Waiter")) + ".."}
                                        />
                                        </li>*/}

                        <li
                          className="addons-list__item mx-1"
                        >
                          <input
                            type="number"
                            className="form-control xsm-text py-2 pl-2"
                            min="1"
                            onChange={handleTotalGuest}
                            placeholder={
                              _t(t("Total guest")) + ".."
                            }
                          />
                        </li>
                        <li
                          className={`addons-list__item mx-1 ${authUserInfo.details &&
                            authUserInfo.details.user_type === "staff" &&
                            "mt-1"
                            }`}
                        >
                          <Select
                            options={
                              orderspos.orderspos !== null &&
                              orderspos.orderspos
                            }
                            components={makeAnimated()}
                            getOptionLabel={(option) =>
                              option.customer_name + " (" + option.table_name + ")"
                            }
                            getOptionValue={(option) => option.id}
                            classNamePrefix="select"
                            className="xsm-text"
                            onChange={prevgetorderitems}
                            maxMenuHeight="200px"
                            placeholder={"Ordenes"}
                          />
                        </li>
                      </>
                    )}
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Extra Info End */}

      <main id="main" data-simplebar>
        {/* Mobile Screen Only   */}
        <div className="d-md-none t-mb-15">
          {/* Show start work period options here */}
          {newSettings && newSettings.workPeriod === null && (
            <div className="fk-left-overlay">
              <div className="fk-left-overlay__content text-center m-auto">
                <h5
                  className={`text-primary text-uppercase ${authUserInfo.details &&
                    authUserInfo.details.user_type !== "staff" &&
                    "mb-0"
                    }`}
                >
                  {authUserInfo.details &&
                    authUserInfo.details.user_type !== "staff"
                    ? _t(t("Select branch to active POS"))
                    : _t(t("start workperiod"))}
                </h5>
                {authUserInfo.details &&
                  authUserInfo.details.user_type !== "staff" && (
                    <>
                      <h6 className="mt-1 text-uppercase xsm-text mb-1">
                        {_t(t("Start workperiod if it is not started"))}
                      </h6>
                      <small className="d-flex justify-content-center text-lowercase xsm-text mt-0 mb-2">
                        (
                        {_t(
                          t("Use staff account not to see this multiple times")
                        )}
                        )
                      </small>
                    </>
                  )}
                <NavLink
                  to="/dashboard"
                  className="btn  btn-primary text-uppercase sm-text"
                >
                  {_t(t("Goto Dashboard"))}
                </NavLink>
              </div>
            </div>
          )}
          {/* Show start work period options here */}
          {foodGroupForSearch &&
            foodGroupForSearch.map((mobileGroup, mobileGroupIndex) => {
              let tempItems =
                foodForSearch &&
                foodForSearch.filter((tempItem) => {
                  return parseInt(tempItem.food_group_id) === mobileGroup.id;
                });
              return (
                <div
                  className="fk-sm-card t-mt-10"
                  id={`card-${mobileGroupIndex + 1}`}
                >
                  <h3 className="mt-0 t-mb-30 text-capitalize">
                    {mobileGroup.name}
                  </h3>
                  <ul className="t-list fk-sm-card-list">
                    {tempItems !== null && tempItems.length > 0
                      ? [
                        tempItems.map((mobileItem, mobileItemIndex) => {
                          return (
                            <li
                              className="fk-sm-card-list__item"
                              data-toggle="modal"
                              data-target="#menuAddons"
                              key={mobileItemIndex}
                              onClick={() => {
                                //set active item in order list
                                setActiveItemInOrder(mobileItemIndex);
                                // set variations, properties and selected items here
                                setFoodItem({
                                  ...foodItem,
                                  foodGroup: mobileGroup,
                                  selectedItem: mobileItem,
                                  variations:
                                    mobileItem &&
                                      parseInt(mobileItem.has_variation) === 1
                                      ? mobileItem.variations
                                      : null,
                                  properties:
                                    mobileItem &&
                                      parseInt(mobileItem.has_property) === 1
                                      ? mobileItem.properties
                                      : null,
                                });
                                handleOrderItem(mobileItem);
                              }}
                            >
                              <div className="fk-sm-card__container align-items-center">
                                <div className="fk-sm-card__content">
                                  <h6 className="text-capitalize fk-sm-card__title">
                                    {mobileItem.name}
                                  </h6>
                                  {mobileItem.has_variation === "0" && (
                                    <p className="t-mt-10 mb-0 sm-text text-uppercase t-text-dark--light-20">
                                      {currencySymbolLeft()}
                                      {formatPrice(
                                        parseFloat(mobileItem.price)
                                      )}
                                      {currencySymbolRight()}
                                    </p>
                                  )}
                                </div>
                                <div className="fk-sm-card__action">
                                  <div
                                    className="fk-sm-card__img fk-sm-card__img--1"
                                    style={{
                                      backgroundImage: `url(${mobileItem.image})`,
                                    }}
                                  ></div>
                                  <div className="fk-sm-card__cart">
                                    <i className="las la-plus"></i>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        }),
                      ]
                      : ""}
                  </ul>
                </div>
              );
            })}

          <div className="fk-sm-footnav" style={{ zIndex: 10 }}>
            <div className="fk-sm-footnav__container">
              <ul className="t-list fk-sm-footnav__list justify-content-center">
                <li
                  className="fk-sm-footnav__list-item"
                  data-toggle="modal"
                  data-target="#extraInfo"
                >
                  <span className="t-link fk-sm-footnav__link">
                    <i className="las la-edit"></i>
                  </span>
                </li>
                <li
                  className="fk-sm-footnav__list-item"
                  data-toggle="modal"
                  data-target="#showCart"
                >
                  <span className="t-link fk-sm-footnav__link">
                    <i className="las la-shopping-cart"></i>
                  </span>
                </li>
                <li className="fk-sm-footnav__list-item">
                  <NavLink
                    to="/dashboard/pos/submitted"
                    className="t-link fk-sm-footnav__link"
                  >
                    <i className="las la-share-square"></i>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Mobile Screen Only   */}

        {/* Show In Tab   */}
        <div className="fk-main d-none d-md-block t-mt-10">
          <div className="container-fluid">
            <div className="row gx-2">
              <div className="col-md-7">

                {/* Show start work period options here */}
                {newSettings && newSettings.workPeriod === null && (
                  <div className="fk-left-overlay">
                    <div className="fk-left-overlay__content text-center m-auto">
                      <h5
                        className={`text-primary text-uppercase ${authUserInfo.details &&
                          authUserInfo.details.user_type !== "staff" &&
                          "mb-0"
                          }`}
                      >
                        {authUserInfo.details &&
                          authUserInfo.details.user_type !== "staff"
                          ? _t(t("Select branch to active POS"))
                          : _t(t("start workperiod"))}
                      </h5>
                      {authUserInfo.details &&
                        authUserInfo.details.user_type !== "staff" && (
                          <>
                            <h6 className="mt-1 text-uppercase xsm-text mb-1">
                              {_t(t("Start workperiod if it is not started"))}
                            </h6>
                            <small className="d-flex justify-content-center text-lowercase xsm-text mt-0 mb-2">
                              (
                              {_t(
                                t(
                                  "Use staff account not to see this multiple times"
                                )
                              )}
                              )
                            </small>
                          </>
                        )}
                      <NavLink
                        to="/dashboard"
                        className="btn  btn-primary text-uppercase sm-text"
                      >
                        {_t(t("Goto Dashboard"))}
                      </NavLink>
                    </div>
                  </div>
                )}
                {/* Show start work period options here */}
                <div className="row gx-2">
                  {/* productos   */}
                  {/* Dish Addons   */}
                  <div className="col-md-6 col-xxl-7 order-xl-1 t-bg-white">
                    <div className="fk-right-nav p-2 p-xl-3" style={{ height: "calc(100vh - 120ypx)" }} data-simplebar>
                      <div className="col-md-12"
                        style={{
                          padding: "10px",
                          overflow: "auto"
                        }}
                      >
                        <ul className="t-list fk-pos-nav" style={{ whiteSpace: "nowrap" }}>
                          {/* Food groups */}
                          {foodGroupForSearch &&
                            foodGroupForSearch.map((groupItem, groupIndex) => {
                              return (
                                <li
                                  className="fk-pos-nav__list"
                                  key={groupIndex}
                                  style={{ display: "inline-block", marginRight: "1rem" }}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      // set active group, group items, initial item active, todo:: set variations and properties
                                      let tempItems =
                                        foodForSearch &&
                                        foodForSearch.filter((tempItem) => {
                                          return (
                                            parseInt(tempItem.food_group_id) ===
                                            groupItem.id
                                          );
                                        });

                                      if (tempItems && tempItems.length > 0) {
                                        setFoodItem({
                                          ...foodItem,
                                          foodGroup: groupItem,
                                          items: tempItems,
                                          selectedItem:
                                            tempItems && tempItems[0],
                                          variations:
                                            tempItems &&
                                              parseInt(
                                                tempItems[0].has_variation
                                              ) === 1
                                              ? tempItems[0].variations
                                              : null,
                                          properties:
                                            tempItems &&
                                              parseInt(
                                                tempItems[0].has_property
                                              ) === 1
                                              ? tempItems[0].properties
                                              : null,
                                        });
                                      } else {
                                        setFoodItem({
                                          foodGroup: groupItem,
                                          items: null,
                                          selectedItem: null,
                                          variations: null,
                                          properties: null,
                                        });
                                      }

                                      //set active item in order list
                                      setActiveItemInOrder(null);
                                    }}
                                    //set active or !
                                    className={`w-100 t-text-dark t-heading-font btn btn-outline-danger font-weight-bold text-uppercase ${foodItem.foodGroup &&
                                      foodItem.foodGroup.id === groupItem.id &&
                                      "active"
                                      }`}
                                  >
                                    {groupItem.name}
                                  </button>
                                </li>
                              );
                            })}
                          {/* Food groups */}
                        </ul>

                      </div>
                      <div className="col-md-12" style={{ paddingBottom: "10px" }}>
                        <div className="col-md-12">
                          <div className="input-group">
                            <button className="btn btn-primary" type="button">
                              <i className="fa fa-search" aria-hidden="true"></i>
                            </button>
                            <div className="form-file">
                              <input
                                type="text"
                                className="form-control border-0 form-control--light-2 rounded-0"
                                placeholder={_t(t("Search by name, group")) + ".."}
                                onChange={handleSearch}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row align-items-center gx-2">
                            <div className="col">
                              {window.location.pathname ===
                                "/dashboard/pos/settled" ? (
                                <NavLink
                                  to="/refresh"
                                  className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-success xsm-text text-uppercase text-center w-100"
                                >
                                  {_t(t("Settled"))}
                                </NavLink>
                              ) : (
                                <NavLink
                                  to="/dashboard/pos/settled"
                                  className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-success xsm-text text-uppercase text-center w-100"
                                >
                                  {_t(t("Settled"))}
                                </NavLink>
                              )}
                            </div>
                            <div className="col">
                              {window.location.pathname ===
                                "/dashboard/pos/submitted" ? (
                                <NavLink
                                  to="/refresh"
                                  className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100"
                                >
                                  {_t(t("En Curso"))}
                                </NavLink>
                              ) : (
                                <NavLink
                                  to="/dashboard/pos/submitted"
                                  className="t-link t-pt-8 t-pb-8 t-pl-12 t-pr-12 btn btn-primary xsm-text text-uppercase text-center w-100"
                                >
                                  {_t(t("En Curso"))}
                                </NavLink>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="list-group fk-dish row gx-2">
                        {foodItem.items &&
                          foodItem.items.map(
                            (tempFoodItem, tempFoodItemIndex) => {
                              return (
                                <div
                                  onClick={() => {
                                    // set variations, properties and selected items here
                                    setFoodItem({
                                      ...foodItem,
                                      selectedItem: tempFoodItem,
                                      variations:
                                        tempFoodItem &&
                                          parseInt(
                                            tempFoodItem.has_variation
                                          ) === 1
                                          ? tempFoodItem.variations
                                          : null,
                                      properties:
                                        tempFoodItem &&
                                          parseInt(
                                            tempFoodItem.has_property
                                          ) === 1
                                          ? tempFoodItem.properties
                                          : null,
                                    });
                                    handleOrderItem(tempFoodItem);
                                  }}
                                  className={`fk-dish__link t-mb-10 col-md-6 col-lg-4 col-xl-6 col-xxl-4 t-link border-0 pointer-cursor ${foodItem.selectedItem &&
                                    foodItem.selectedItem.id ===
                                    tempFoodItem.id &&
                                    "active"
                                    }`}
                                >
                                  <div className="fk-dish-card w-100">
                                    <div className="fk-dish-card__img w-100">
                                      <img
                                        src={tempFoodItem.image}
                                        alt=""
                                        className="img-fluid m-auto w-100"
                                      />
                                    </div>
                                    <span className="fk-dish-card__title text-center text-uppercase">
                                      {tempFoodItem.name}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xxl-5 order-xl-2">
                    <div className="fk-right-nav p-2 p-xl-3 t-bg-white">
                      <div className="" id="addons-1">
                        <div className="t-bg-white">
                          <div
                            className="fk-dish--scroll"
                            data-simplebar
                            style={{ height: "calc(100vh - 120px)" }}
                          >
                            {/* Variations */}
                            <div className="fk-addons-table">
                              <div className="fk-addons-table__head text-center">
                                {_t(t("bases del platillo"))}
                              </div>
                              {foodItem.variations ? (
                                <>
                                  <div className="fk-addons-table__info">
                                    <div className="row g-0">
                                      <div className="col-8 pl-3 border-right">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("name"))}
                                        </span>
                                      </div>
                                      <div className="col-4 text-center">
                                        <span className="fk-addons-table__info-text text-capitalize">
                                          {_t(t("price"))}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="fk-addons-table__body">
                                    {foodItem.variations.map(
                                      (variationItem) => {
                                        return (
                                          <div className="fk-addons-table__body-row">
                                            <div className="row g-0">
                                              <div className="col-8 border-right t-pl-10 t-pr-10">
                                                <label className="mx-checkbox" style={{ display: "block" }}>
                                                  <input
                                                    type="radio"
                                                    className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                    name="variation"
                                                    onChange={() => {
                                                      handleOrderItemVariation(
                                                        variationItem
                                                      );
                                                    }}
                                                    checked={checkChecked(
                                                      variationItem
                                                    )}
                                                  />
                                                  <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                                                    {
                                                      variationItem.variation_name
                                                    }
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="col-4 text-center">
                                                <span className="fk-addons-table__body-text sm-text">
                                                  {currencySymbolLeft()}
                                                  {formatPrice(
                                                    variationItem.food_with_variation_price
                                                  )}
                                                  {currencySymbolRight()}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div className="fk-addons-table__info py-4">
                                  <div className="row g-0">
                                    <div className="col-12 text-center border-right">
                                      <span className="fk-addons-table__info-text text-capitalize text-primary">
                                        {_t(t("No hay bases del platillo"))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* Variations end*/}
                            <div
                              className={
                                foodItem.properties ? "" : "d-none"
                              }
                            >
                              {/* Property group and items */}
                              {foodItem.properties && (
                                <>
                                  {foodItem.properties.length > 0 ? (
                                    foodItem.properties.map(
                                      (propertyItem) => {
                                        //property group
                                        let selectedGroup = null;
                                        //property group
                                        if (propertyItem.length > 0) {
                                          selectedGroup =
                                            propertyGroupForSearch &&
                                            propertyGroupForSearch.find(
                                              (
                                                singlePropertyGroup
                                              ) => {
                                                return (
                                                  singlePropertyGroup.id ===
                                                  parseInt(
                                                    propertyItem[0]
                                                      .property_group_id
                                                  )
                                                );
                                              }
                                            );
                                        }
                                        return (
                                          <div className="fk-addons-table">
                                            <div className="fk-addons-table__head text-center">
                                              {selectedGroup &&
                                                selectedGroup.name}
                                            </div>
                                            <div className="fk-addons-table__info">
                                              <div className="row g-0">
                                                <div className="col-5 pl-3 border-right">
                                                  <span className="fk-addons-table__info-text text-capitalize">
                                                    {_t(t("name"))}
                                                  </span>
                                                </div>
                                                <div className="col-3 text-center border-right">
                                                  <span className="fk-addons-table__info-text text-capitalize">
                                                    {_t(t("QTY"))}
                                                  </span>
                                                </div>
                                                <div className="col-4 text-center">
                                                  <span className="fk-addons-table__info-text text-capitalize">
                                                    {_t(
                                                      t("Unit price")
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="fk-addons-table__body">
                                              {propertyItem.map(
                                                (eachItem) => {
                                                  return (
                                                    <div className="fk-addons-table__body-row">
                                                      <div className="row g-0">
                                                        <div className="col-5 border-right t-pl-10 t-pr-10">
                                                          <label className="mx-checkbox" style={{ display: "block" }}>
                                                            <input
                                                              type="checkbox"
                                                              className="mx-checkbox__input mx-checkbox__input-solid mx-checkbox__input-solid--danger mx-checkbox__input-sm"
                                                              onChange={() => {
                                                                newOrder &&
                                                                  newOrder.map(
                                                                    (
                                                                      newOrderItem,
                                                                      index
                                                                    ) => {
                                                                      if (
                                                                        index ===
                                                                        activeItemInOrder
                                                                      ) {
                                                                        if (
                                                                          newOrderItem.properties
                                                                        ) {
                                                                          let theItem = newOrderItem.properties.find(
                                                                            (
                                                                              eachPropertyItem
                                                                            ) => {
                                                                              return (
                                                                                eachPropertyItem
                                                                                  .item
                                                                                  .id ===
                                                                                eachItem.id
                                                                              );
                                                                            }
                                                                          );

                                                                          if (
                                                                            theItem ===
                                                                            undefined
                                                                          ) {
                                                                            handleAddProperties(
                                                                              eachItem
                                                                            );
                                                                          } else {
                                                                            handleRemoveProperties(
                                                                              eachItem
                                                                            );
                                                                          }
                                                                        } else {
                                                                          handleAddProperties(
                                                                            eachItem
                                                                          );
                                                                        }
                                                                      }
                                                                    }
                                                                  );
                                                              }}
                                                              checked={checkCheckedProperties(
                                                                eachItem
                                                              )}
                                                            />
                                                            <span className="mx-checkbox__text text-capitalize t-text-heading t-ml-8 fk-addons-table__body-text">
                                                              {
                                                                eachItem.name
                                                              }
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="col-3 text-center border-right">
                                                          <div className="fk-qty justify-content-center t-pt-10 t-pb-10">
                                                            {eachItem.allow_multi_quantity ===
                                                              1 && (
                                                                <span
                                                                  className="fk-qty__icon fk-qty__deduct"
                                                                  onClick={() => {
                                                                    handlePropertyQty(
                                                                      eachItem,
                                                                      "-"
                                                                    );
                                                                  }}
                                                                >
                                                                  <i className="las la-minus"></i>
                                                                </span>
                                                              )}
                                                            {eachItem.allow_multi_quantity ===
                                                              1 ? (
                                                              <input
                                                                type="text"
                                                                value={checkCheckedPropertyQuantity(
                                                                  eachItem
                                                                )}
                                                                className="fk-qty__input t-bg-clear"
                                                                readOnly
                                                              />
                                                            ) : (
                                                              "-"
                                                            )}
                                                            {eachItem.allow_multi_quantity ===
                                                              1 && (
                                                                <span
                                                                  className="fk-qty__icon fk-qty__add"
                                                                  onClick={() => {
                                                                    handlePropertyQty(
                                                                      eachItem,
                                                                      "+"
                                                                    );
                                                                  }}
                                                                >
                                                                  <i className="las la-plus"></i>
                                                                </span>
                                                              )}
                                                          </div>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                          <span className="fk-addons-table__body-text sm-text">
                                                            {currencySymbolLeft()}
                                                            {formatPrice(
                                                              eachItem.extra_price
                                                            )}
                                                            {currencySymbolRight()}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                  ) : (
                                    <div className="fk-addons-table__info py-4">
                                      <div className="row g-0">
                                        <div className="col-12 text-center border-right">
                                          <span className="fk-addons-table__info-text text-capitalize text-primary">
                                            {_t(t("No properties"))}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            {/* Property group and items */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Side  */}
              <div className="col-md-5">
                <div className="row gx-2">
                  <div className="col-md-4 col-xl-3">
                    <div className="fk-right-nav">
                      <div className="row flex-column justify-content-between">
                        <div className="col-12 flex-grow-1">
                          <div className="fk-right-nav__scroll" data-simplebar>
                            <ul className="t-list addons-list">
                              {authUserInfo.details &&
                                authUserInfo.details.branch_id == 0 && (
                                  <li className="addons-list__item mt-1 mx-1">
                                    <Select
                                      options={
                                        branchForSearch && branchForSearch
                                      }
                                      components={makeAnimated()}
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) => option.name}
                                      classNamePrefix="select"
                                      className="xsm-text"
                                      onChange={handleSetBranch}
                                      maxMenuHeight="200px"
                                      placeholder={_t(t("Branch")) + ".."}
                                    />
                                  </li>
                                )}
                              {!loading && (
                                <>
                                  {orderDetails.branch !== null && (
                                    <>
                                      <li
                                        className={`addons-list__item mx-1 ${authUserInfo.details &&
                                          authUserInfo.details.user_type ===
                                          "staff" &&
                                          "mt-1"
                                          }`}
                                      >
                                        <Select
                                          options={
                                            orderDetailUsers.theCustomers !==
                                            null &&
                                            orderDetailUsers.theCustomers
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name +
                                            " (" +
                                            option.phn_no +
                                            ")"
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={handleSetCustomer}
                                          maxMenuHeight="200px"
                                          placeholder={_t(t("Customer")) + ".."}
                                        />
                                      </li>
                                      <li className="addons-list__item mx-1 border border-2 rounded-lg">
                                        <div className="btn-group w-100">
                                          <button
                                            type="button"
                                            className="fk-right-nav__guest-btn btn w-100 t-bg-white dropdown-toggle new-customer-pos xsm-text pl-2"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            + {_t(t("Customer"))}?
                                          </button>
                                          <ul className="dropdown-menu w-100 border-0 pt-4 change-background">
                                            <li>
                                              <input
                                                type="text"
                                                name="name"
                                                className="form-control font-10px rounded-lg"
                                                placeholder="Nombre"
                                                autoComplete="off"
                                                value={
                                                  orderDetails.newCustomerInfo
                                                    .name
                                                }
                                                onChange={handleNewCustomer}
                                              />
                                            </li>
                                            <li className="pb-2">
                                              <input
                                                type="text"
                                                name="number"
                                                className="form-control font-10px mt-2 rounded-lg"
                                                autoComplete="off"
                                                placeholder="Celular"
                                                value={
                                                  orderDetails.newCustomerInfo
                                                    .number
                                                }
                                                onChange={handleNewCustomer}
                                              />
                                            </li>
                                            <li className="pb-2">
                                              <input
                                                type="text"
                                                name="address"
                                                className="form-control font-10px mt-2 rounded-lg"
                                                autoComplete="off"
                                                placeholder="Dirección"
                                                value={
                                                  orderDetails.newCustomerInfo
                                                    .address
                                                }
                                                onChange={handleNewCustomer}
                                              />
                                            </li>
                                            <li className="pb-1 text-right">
                                              <button
                                                className="btn t-bg-white text-dark xsm-text text-uppercase btn-sm py-0 px-2 mr-1"
                                                onClick={() => {
                                                  setOrderDetails({
                                                    ...orderDetails,
                                                    newCustomer: false,
                                                    newCustomerInfo: {
                                                      name: "",
                                                      number: "",
                                                      address: "",
                                                    },
                                                  });
                                                }}
                                              >
                                                {_t(t("Cancel"))}
                                              </button>
                                            </li>
                                          </ul>
                                        </div>
                                      </li>

                                      <li className="addons-list__item mx-1">
                                        <Select
                                          options={
                                            deptTagForSearch && deptTagForSearch
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={handleSetDeptTag}
                                          maxMenuHeight="200px"
                                          placeholder={_t(t("Tipo de Orden")) + ".."}
                                        />
                                      </li>
                                      <li
                                        className={`addons-list__item mx-1 payment-type-parent ${orderDetails.payment_type !== null &&
                                          "mb-1"
                                          }`}
                                      >
                                        <Select
                                          options={
                                            paymentTypeForSearch &&
                                            paymentTypeForSearch
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={handleSetpaymentType}
                                          maxMenuHeight="200px"
                                          isMulti
                                          backspaceRemovesValue={false}
                                          clearIndicator={null}
                                          placeholder={_t(t("Payments")) + ".."}
                                        />
                                      </li>
                                      {orderDetails.payment_type !== null && (
                                        <div className="border mt-0 mb-2 change-background mx-1 rounded-lg">
                                          <div className="xsm-text text-center text-white pt-1">
                                            {_t(t("Amount"))}
                                          </div>
                                          {orderDetails.payment_type.map(
                                            (
                                              eachPaymentType,
                                              paymentTypeIndex
                                            ) => {
                                              return (
                                                <li className="addons-list__item mx-1 mb-1">
                                                  <input
                                                    type="number"
                                                    min="0"
                                                    step="0.01"
                                                    name={eachPaymentType.id}
                                                    autoComplete="off"
                                                    className="form-control xsm-text pl-2"
                                                    onChange={
                                                      handlePaymentTypeAmount
                                                    }
                                                    placeholder={
                                                      eachPaymentType.name
                                                    }
                                                    value={
                                                      orderDetails.payment_amount &&
                                                      orderDetails
                                                        .payment_amount[
                                                      eachPaymentType.id
                                                      ]
                                                    }
                                                  />
                                                </li>
                                              );
                                            }
                                          )}
                                        </div>
                                      )}

                                      <li className="addons-list__item mx-1">
                                        <Select
                                          options={
                                            orderDetailUsers.theTables !==
                                            null && orderDetailUsers.theTables
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={handleSetTable}
                                          maxMenuHeight="200px"
                                          placeholder={_t(t("Table")) + ".."}
                                        />
                                      </li>
                                      {/*<li className="addons-list__item mx-1">
                                        <Select
                                          options={
                                            orderDetailUsers.theWaiters !==
                                            null &&
                                            orderDetailUsers.theWaiters
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          getOptionValue={(option) =>
                                            option.name
                                          }
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={handleSetWaiter}
                                          maxMenuHeight="200px"
                                          placeholder={_t(t("Waiter")) + ".."}
                                        />
                                        </li>*/}

                                      <li
                                        className="addons-list__item mx-1"
                                      >
                                        <input
                                          type="number"
                                          className="form-control xsm-text py-2 pl-2"
                                          min="1"
                                          onChange={handleTotalGuest}
                                          placeholder={
                                            _t(t("Total guest")) + ".."
                                          }
                                        />
                                      </li>
                                      <li
                                        className={`addons-list__item mx-1 ${authUserInfo.details &&
                                          authUserInfo.details.user_type === "staff" &&
                                          "mt-1"
                                          }`}
                                      >
                                        <Select
                                          options={
                                            orderspos.orderspos !== null &&
                                            orderspos.orderspos
                                          }
                                          components={makeAnimated()}
                                          getOptionLabel={(option) =>
                                            option.customer_name + " (" + option.table_name + ")"
                                          }
                                          getOptionValue={(option) => option.id}
                                          classNamePrefix="select"
                                          className="xsm-text"
                                          onChange={prevgetorderitems}
                                          maxMenuHeight="200px"
                                          placeholder={"Ordenes"}
                                        />
                                      </li>
                                    </>
                                  )}
                                </>
                              )}
                            </ul>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="row">
                            <div className="col-12" style={{ marginBottom: "5px" }}>
                              <button
                                onClick={() => {
                                  if (newOrder) {
                                    if (!barcocina.cocina) {
                                      if (barcocina.bar) {
                                        handleOnlyPrint3();
                                      }
                                    } else {
                                      handleOnlyPrint2();
                                    }
                                  } else {
                                    toast.error(
                                      `${_t(
                                        t(
                                          "Debe Agregar Productos"
                                        )
                                      )}`,
                                      {
                                        position: "bottom-center",
                                        closeButton: false,
                                        autoClose: 10000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        className:
                                          "text-center toast-notification",
                                      }
                                    );
                                  }
                                }}
                                className="w-100 t-heading-font btn btn-outline-danger font-weight-bold text-uppercase sm-text"
                              >
                                {_t(t("Comanda"))}
                              </button>
                            </div>
                            <div className="col-12 t-mb-10">
                              <button
                                type="button"
                                className="w-100  btn btn-primary sm-text text-uppercase font-weight-bold"
                                onClick={!loading && handleSettleOrder}
                              >
                                {!loading
                                  ? _t(t("Facturar"))
                                  : _t(t("Please wait"))}
                              </button>
                            </div>
                            <div className="col-12 t-mb-10">
                              <button
                                type="button"
                                style={btnupdatesave == "update" ? { display: "block" } : { display: "none" }}
                                className="w-100 btn btn-success sm-text text-uppercase font-weight-bold"
                                onClick={!loading && handleEditOrder}
                              >
                                {!loading
                                  ? _t(t("Editar Orden"))
                                  : _t(t("Please wait"))}
                              </button>
                            </div>
                            <div className="col-12 t-mb-10">
                              <button
                                type="button"
                                style={btnupdatesave == "update" ? { display: "none" } : { display: "block" }}
                                className="w-100 btn btn-success sm-text text-uppercase font-weight-bold"
                                onClick={!loading && handleSubmitOrder}
                              >
                                {!loading
                                  ? _t(t("Ordenar"))
                                  : _t(t("Please wait"))}
                              </button>
                            </div>
                            <div className="col-12">
                              <button
                                className="w-100 t-heading-font btn btn-primary font-weight-bold text-uppercase sm-text"
                                onClick={handleCancelConfirmation}
                              >
                                {_t(t("cancel"))}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-xl-9">
                    <div className="fk-right-nav p-2 p-xl-3 t-bg-white">
                      {/* Disply Only Small Screen   */}
                      <div className="h-100 w-100 d-none" data-simplebar>
                        <div className="fk-receipt-content">
                          <div className="fk-receipt-body t-mt-10">
                            <div className="row g-0">
                              <div className="col-12">
                                <span className="sm-text font-weight-bold text-uppercase font-italic">
                                  {_t(t("Orden ticket"))}:{" "}
                                  {newOrder ? (
                                    <>
                                      #{formartid(orderDetails.token.id_order)} -{" "}
                                      <Moment format="LT">
                                        {orderDetails.token.time}
                                      </Moment>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="fk-sm-card__container t-mt-30">
                              <div className="fk-sm-card__content">
                                <h6 className="text-capitalize fk-sm-card__title t-mb-5">
                                  food items
                                </h6>
                                <p className="mb-0 xsm-text t-text-heading fk-sm-card__description text-capitalize">
                                  properties & bill
                                </p>
                              </div>
                              <span className="text-capitalize xxsm-text fk-badge fk-badge--dark">
                                total
                              </span>
                            </div>
                            <hr />
                            <ul className="t-list addons-list">
                              <li className="addons-list__item active">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="col">
                                    <span className=" sm-text text-capitalize">
                                      chicken burger
                                    </span>
                                    <span className=" xsm-text text-capitalize d-block">
                                      large
                                    </span>
                                  </div>
                                  <div className="col">
                                    <div className="fk-qty flex-grow-1 justify-content-end">
                                      <span className="fk-qty__icon fk-qty__deduct">
                                        <i className="las la-minus"></i>
                                      </span>
                                      <input
                                        type="text"
                                        value="0"
                                        className="fk-qty__input"
                                      />
                                      <span className="fk-qty__icon fk-qty__add">
                                        <i className="las la-plus"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col text-right">
                                    <span className=" text-uppercase sm-text flex-grow-1">
                                      +tk 100
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li className="addons-list__item">
                                <div className="d-flex align-items-center justify-content-between">
                                  <div className="col">
                                    <span className="t-text-heading sm-text text-capitalize">
                                      beef burger
                                    </span>
                                  </div>
                                  <div className="col">
                                    <div className="fk-qty flex-grow-1 justify-content-end">
                                      <span className="fk-qty__icon fk-qty__deduct">
                                        <i className="las la-minus"></i>
                                      </span>
                                      <input
                                        type="text"
                                        value="0"
                                        className="fk-qty__input"
                                      />
                                      <span className="fk-qty__icon fk-qty__add">
                                        <i className="las la-plus"></i>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col text-right">
                                    <span className="t-text-heading text-uppercase sm-text flex-grow-1">
                                      +tk 230
                                    </span>
                                  </div>
                                </div>
                              </li>
                            </ul>
                            <hr />
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col">
                                    <span className="text-capitalize sm-text">
                                      sub total
                                    </span>
                                  </div>
                                  <div className="col text-center">:</div>
                                  <div className="col text-right">
                                    <span className="text-capitalize sm-text font-weight-bold">
                                      {newOrder ? (
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {currencySymbolLeft()}
                                          {formatPrice(theSubTotal)}
                                          {currencySymbolRight()}
                                        </span>
                                      ) : (
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {currencySymbolLeft()}
                                          {formatPrice(0)}
                                          {currencySymbolRight()}
                                        </span>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col">
                                    <span className="text-capitalize sm-text">
                                      VAT ({newSettings && newSettings.vat}%)
                                    </span>
                                  </div>
                                  <div className="col text-center">:</div>
                                  <div className="col text-right">
                                    <span className="text-capitalize sm-text font-weight-bold">
                                      {currencySymbolLeft()}
                                      {formatPrice(theVat)}
                                      {currencySymbolRight()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col">
                                    <span className="text-capitalize sm-text">
                                      service
                                    </span>
                                  </div>
                                  <div className="col text-center">:</div>
                                  <div className="col text-right">
                                    <span className="text-capitalize sm-text font-weight-bold">
                                      20.00
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="row">
                                  <div className="col">
                                    <span className="text-capitalize sm-text">
                                      discount
                                    </span>
                                  </div>
                                  <div className="col text-center">:</div>
                                  <div className="col text-right">
                                    <span className="text-capitalize sm-text font-weight-bold">
                                      100.00
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-12">
                                <div className="row">
                                  <div className="col">
                                    <span className="text-capitalize sm-text font-weight-bold">
                                      total bill
                                    </span>
                                  </div>
                                  <div className="col text-center">:</div>
                                  <div className="col text-right">
                                    <span className="text-capitalize sm-text font-weight-bold">
                                      2000.00
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-6">
                                <button
                                  type="button"
                                  className="btn btn-primary w-100 xsm-text text-uppercase"
                                >
                                  settle
                                </button>
                              </div>
                              <div className="col-6">
                                <button
                                  type="button"
                                  className="btn btn-success w-100 xsm-text text-uppercase"
                                >
                                  submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Disply Only Small Screen End  */}

                      {/* Display Only Large Screen   */}
                      <div className="h-100 w-100 d-none d-md-block">
                        <div className="fk-price-table">
                          <div className="fk-price-table__head">
                            <div className="row gx-0 align-items-center">
                              <div className="col-12 text-right">
                                <span className="d-block sm-text font-weight-bold text-uppercase">
                                  {_t(t("Orden ticket"))}:{" "}
                                  {newOrder ? (
                                    <>

                                      <Moment format="LT">
                                        {orderDetails.token.time}
                                      </Moment>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="fk-price-table__body t-mt-10">
                            <div className="fk-price-table__body-top">
                              <div className="fk-table">
                                <div className="fk-table__head">
                                  <div className="row g-0 border">
                                    <div className="col-1 text-center border-right">
                                      <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                        {_t(t("S/L"))}
                                      </span>
                                    </div>
                                    <div className="col-6 text-center border-right">
                                      <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                        {_t(t("food item"))}
                                      </span>
                                    </div>
                                    <div className="col-2 text-center border-right">
                                      <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                        {_t(t("QTY"))}
                                      </span>
                                    </div>
                                    <div className="col-3 text-center">
                                      <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                        {_t(t("price"))}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="fk-table__body border-bottom"
                                  data-simplebar
                                >
                                  <div className="sky-is-blue reverse-this">
                                    {/* loop through order list items */}
                                    {newOrder && newOrder.length > 0 ? (
                                      newOrder.map(
                                        (orderListItem, orderListItemIndex) => {
                                          return (
                                            <>
                                              <div
                                                className={`fk-table-container-order ${orderListItemIndex ===
                                                  activeItemInOrder &&
                                                  "active"
                                                  } `}
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  //orderListItem's group wise all items
                                                  let tempItems =
                                                    foodForSearch &&
                                                    foodForSearch.filter(
                                                      (tempItem) => {
                                                        return (
                                                          tempItem.food_group_id ===
                                                          orderListItem.item
                                                            .food_group_id
                                                        );
                                                      }
                                                    );

                                                  //orderListItem's group
                                                  let foodGroup =
                                                    foodGroupForSearch &&
                                                    foodGroupForSearch.find(
                                                      (groupItem) => {
                                                        return (
                                                          groupItem.id ===
                                                          parseInt(
                                                            orderListItem.item
                                                              .food_group_id
                                                          )
                                                        );
                                                      }
                                                    );

                                                  // selected pos item
                                                  let selectedItemTemp =
                                                    tempItems &&
                                                    tempItems.find(
                                                      (tempSelectedItem) => {
                                                        return (
                                                          tempSelectedItem.id ===
                                                          orderListItem.item.id
                                                        );
                                                      }
                                                    );

                                                  // Set variations, properties, selected item
                                                  setFoodItem({
                                                    ...foodItem,
                                                    foodGroup: foodGroup,
                                                    items: tempItems,
                                                    selectedItem: selectedItemTemp,
                                                    variations:
                                                      selectedItemTemp &&
                                                        parseInt(
                                                          selectedItemTemp.has_variation
                                                        ) === 1
                                                        ? selectedItemTemp.variations
                                                        : null,
                                                    properties:
                                                      selectedItemTemp &&
                                                        parseInt(
                                                          selectedItemTemp.has_property
                                                        ) === 1
                                                        ? selectedItemTemp.properties
                                                        : null,
                                                  });

                                                  //set active order list index for background color of selected
                                                  setActiveItemInOrder(
                                                    orderListItemIndex
                                                  );
                                                }}
                                              >
                                                <div
                                                  className={`row g-0 border-top-0 border-bottom `}
                                                >
                                                  <div className="col-1 text-center border-left d-flex justify-content-center align-items-center">
                                                    {newOrder.length -
                                                      orderListItemIndex}
                                                  </div>
                                                  <div
                                                    className={`col-6 border-left border-right py-2`}
                                                  >
                                                    <div className="d-flex justify-content-between">
                                                      <span className="text-capitalize d-block t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold t-mr-8">
                                                        {
                                                          orderListItem.item
                                                            .name
                                                        }
                                                      </span>
                                                    </div>
                                                    <div className="row g-0">
                                                      {/* if item has variations show the selected in order list */}
                                                      {parseInt(
                                                        orderListItem.item
                                                          .has_variation
                                                      ) === 1 && (
                                                          <div className="col-12">
                                                            <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                              Bases del platillo :
                                                            </span>
                                                            <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md">
                                                              {orderListItem.variation
                                                                ? orderListItem
                                                                  .variation
                                                                  .variation_name
                                                                : "-"}
                                                            </span>
                                                          </div>
                                                        )}

                                                      {/* if item has properties show the selected in order list, loop here  */}
                                                      {orderListItem.properties &&
                                                        orderListItem.properties
                                                          .length > 0 &&
                                                        selectedPropertyGroup[
                                                        orderListItemIndex
                                                        ] !== undefined &&
                                                        selectedPropertyGroup[
                                                          orderListItemIndex
                                                        ].map((thisIsGroup) => {
                                                          let theGroup =
                                                            propertyGroupForSearch &&
                                                            propertyGroupForSearch.find(
                                                              (theItem) => {
                                                                return (
                                                                  theItem.id ===
                                                                  thisIsGroup
                                                                );
                                                              }
                                                            );
                                                          return (
                                                            <div className="col-12">
                                                              <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pr-5 t-pl-5">
                                                                {theGroup &&
                                                                  theGroup.name}{" "}
                                                                :
                                                              </span>
                                                              {orderListItem.properties.map(
                                                                (
                                                                  propertyName
                                                                ) => {
                                                                  if (
                                                                    parseInt(
                                                                      propertyName
                                                                        .item
                                                                        .property_group_id
                                                                    ) ===
                                                                    theGroup.id
                                                                  ) {
                                                                    return (
                                                                      <span className="text-capitalize xsm-text d-inline-block badge rounded-pill bg-warning text-dark font-weight-md mr-1">
                                                                        {
                                                                          propertyName
                                                                            .item
                                                                            .name
                                                                        }{" "}
                                                                        <span>
                                                                          {" "}
                                                                          {propertyName.quantity >
                                                                            1 &&
                                                                            "(" +
                                                                            propertyName.quantity +
                                                                            ")"}
                                                                        </span>
                                                                      </span>
                                                                    );
                                                                  } else {
                                                                    return true;
                                                                  }
                                                                }
                                                              )}
                                                            </div>
                                                          );
                                                        })}

                                                      {/* if item has properties show the selected in order list  */}
                                                    </div>
                                                  </div>
                                                  {/* Quantity */}
                                                  <div className="col-2 text-center border-right d-flex justify-content-center align-items-center">
                                                    <div className="fk-qty t-pt-5 t-pb-5 justify-content-center">
                                                      <span
                                                        className="fk-qty__icon"
                                                        onClick={() => {
                                                          handleOrderItemQty(
                                                            "-"
                                                          );
                                                        }}
                                                      >
                                                        <i className="las la-minus"></i>
                                                      </span>
                                                      <input
                                                        type="text"
                                                        value={
                                                          orderListItem.quantity
                                                        }
                                                        className="fk-qty__input t-bg-clear"
                                                        readOnly
                                                      />
                                                      <span
                                                        className="fk-qty__icon"
                                                        onClick={() => {
                                                          handleOrderItemQty(
                                                            "+"
                                                          );
                                                        }}
                                                      >
                                                        <i className="las la-plus"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  {/* Quantity */}

                                                  {/* Price */}
                                                  <div className="col-3 text-center border-right d-flex justify-content-center align-items-center">
                                                    <span className="text-capitalize sm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                                      {parseInt(
                                                        orderListItem.item
                                                          .has_variation
                                                      ) === 1 ? (
                                                        <>
                                                          {currencySymbolLeft()}

                                                          {showPriceOfEachOrderItem(
                                                            orderListItemIndex
                                                          )}
                                                          {currencySymbolRight()}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {currencySymbolLeft()}
                                                          {showPriceOfEachOrderItem(
                                                            orderListItemIndex
                                                          )}
                                                          {currencySymbolRight()}
                                                        </>
                                                      )}
                                                    </span>
                                                  </div>
                                                  {/* Price */}
                                                </div>
                                              </div>
                                              <span className="text-capitalize  t-pt-5 t-pb-5 t-pl-5 t-pr-5 sm-text font-weight-bold make-this-relative">
                                                <span
                                                  className="badge rounded-pill bg-secondary text-capitalize"
                                                  onClick={() => {
                                                    handleRemoveItemFromOrderList(
                                                      orderListItemIndex
                                                    );
                                                  }}
                                                >
                                                  <i className="fa fa-times"></i>
                                                </span>
                                              </span>
                                            </>
                                          );
                                        }
                                      )
                                    ) : (
                                      <div className="text-primary text-center font-weight-bold pt-5 xsm-text text-uppercase">
                                        {_t(
                                          t(
                                            "Select food item to add to the list"
                                          )
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="fk-price-table__body-bottom t-mt-10">
                              <div className="fk-table__head">
                                <div className="row g-0 border">
                                  <div className="col-6 text-center border-right">
                                    <div className="row g-0">
                                      <div className="col-6">
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {_t(t("sub total"))}
                                        </span>
                                      </div>
                                      <div className="col-6">
                                        {newOrder ? (
                                          <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                            {currencySymbolLeft()}
                                            {formatPrice(theSubTotal)}
                                            {currencySymbolRight()}
                                          </span>
                                        ) : (
                                          <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                            {currencySymbolLeft()}
                                            {formatPrice(0)}
                                            {currencySymbolRight()}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6 text-center">
                                    <div className="row g-0">
                                      <div className="col-6">
                                        <span className="text-uppercase xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {_t(t("VAT"))} (
                                          {newSettings && newSettings.vat}
                                          %)
                                        </span>
                                      </div>
                                      <div className="col-6">
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {currencySymbolLeft()}
                                          {formatPrice(theVat)}
                                          {currencySymbolRight()}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row g-0 border">
                                  <div className="col-4 text-center border-right">
                                    <div className="row g-0">
                                      <div className="col-6">
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {_t(t("Delivery"))}
                                        </span>
                                      </div>
                                      <div className="col-6">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                          onChange={(e) => {
                                            if (e.target.value !== "") {
                                              setOrderDetails({
                                                ...orderDetails,
                                                serviceCharge: parseFloat(
                                                  e.target.value
                                                ),
                                              });
                                              let totalPayable = 0;
                                              let localCurrency = JSON.parse(
                                                localStorage.getItem("currency")
                                              );
                                              let usdServiceCharge =
                                                parseFloat(e.target.value) /
                                                localCurrency.rate;
                                              let usdDiscount =
                                                parseFloat(
                                                  orderDetails.discount
                                                ) / localCurrency.rate;

                                              totalPayable =
                                                theSubTotal +
                                                theVat +
                                                parseFloat(orderDetails.propina_value) +
                                                usdServiceCharge -
                                                usdDiscount;
                                              setTotalPaybale(totalPayable);
                                              setReturnMoneyUsd(
                                                totalPayable - paidMoney
                                              );
                                            } else {
                                              setOrderDetails({
                                                ...orderDetails,
                                                serviceCharge: 0,
                                              });
                                              let totalPayable = 0;
                                              let localCurrency = JSON.parse(
                                                localStorage.getItem("currency")
                                              );
                                              let usdServiceCharge =
                                                parseFloat(0) /
                                                localCurrency.rate;
                                              let usdDiscount =
                                                parseFloat(
                                                  orderDetails.discount
                                                ) / localCurrency.rate;

                                              totalPayable =
                                                theSubTotal +
                                                theVat +
                                                parseFloat(orderDetails.propina_value) +
                                                usdServiceCharge -
                                                usdDiscount;
                                              setTotalPaybale(totalPayable);
                                              setReturnMoneyUsd(
                                                totalPayable - paidMoney
                                              );
                                            }
                                          }}
                                          value={
                                            orderDetails.serviceCharge !== 0 &&
                                            orderDetails.serviceCharge
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 text-center">
                                    <div className="row g-0">
                                      <div className="col-6">
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {_t(t("discount"))}
                                        </span>
                                      </div>
                                      <div className="col-6">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                          onChange={(e) => {
                                            if (e.target.value !== "") {
                                              setOrderDetails({
                                                ...orderDetails,
                                                discount: parseFloat(
                                                  e.target.value
                                                ),
                                              });
                                              let totalPayable = 0;
                                              let localCurrency = JSON.parse(
                                                localStorage.getItem("currency")
                                              );
                                              let usdServiceCharge =
                                                parseFloat(
                                                  orderDetails.serviceCharge
                                                ) / localCurrency.rate;
                                              let usdDiscount =
                                                parseFloat(e.target.value) /
                                                localCurrency.rate;

                                              totalPayable =
                                                theSubTotal +
                                                theVat +
                                                parseFloat(orderDetails.propina_value) +
                                                usdServiceCharge -
                                                usdDiscount;

                                              setTotalPaybale(totalPayable);
                                              setReturnMoneyUsd(
                                                totalPayable - paidMoney
                                              );
                                            } else {
                                              setOrderDetails({
                                                ...orderDetails,
                                                discount: 0,
                                              });
                                              let totalPayable = 0;
                                              let localCurrency = JSON.parse(
                                                localStorage.getItem("currency")
                                              );
                                              let usdServiceCharge =
                                                parseFloat(
                                                  orderDetails.serviceCharge
                                                ) / localCurrency.rate;
                                              let usdDiscount =
                                                parseFloat(0) /
                                                localCurrency.rate;

                                              totalPayable =
                                                theSubTotal +
                                                theVat +
                                                parseFloat(orderDetails.propina_value) +
                                                usdServiceCharge -
                                                usdDiscount;
                                              setTotalPaybale(totalPayable);
                                              setReturnMoneyUsd(
                                                totalPayable - paidMoney
                                              );
                                            }
                                          }}
                                          value={
                                            orderDetails.discount !== 0 &&
                                            orderDetails.discount
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 text-center">
                                    <div className="row g-0">
                                      <div className="col-6">
                                        <span className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5">
                                          {_t(t("Propina"))}
                                        </span>
                                      </div>
                                      <div className="col-6">
                                        <input
                                          type="number"
                                          step="0.01"
                                          min="0"
                                          className="text-capitalize xsm-text d-inline-block font-weight-bold t-pt-5 t-pb-5 form-control rounded-0 text-center"
                                          onChange={(e) => {
                                            if (e.target.value !== "") {
                                              let totalPayable = 0;
                                              let calpro = ((parseFloat(e.target.value) / 100) * theSubTotal).toFixed(2);
                                              setOrderDetails({
                                                ...orderDetails,
                                                propina_: parseFloat(
                                                  e.target.value
                                                ),
                                                propina_value: parseFloat(calpro)
                                              });
                                              let localCurrency = JSON.parse(
                                                localStorage.getItem("currency")
                                              );
                                              let usdServiceCharge =
                                                parseFloat(
                                                  orderDetails.serviceCharge
                                                ) / localCurrency.rate;
                                              let usdDiscount =
                                                parseFloat(orderDetails.discount) /
                                                localCurrency.rate;

                                              totalPayable =
                                                theSubTotal +
                                                theVat +
                                                parseFloat(calpro) +
                                                usdServiceCharge -
                                                usdDiscount;

                                              setTotalPaybale(totalPayable);
                                              setReturnMoneyUsd(
                                                totalPayable - paidMoney
                                              );
                                            } else {
                                              let localCurrency = JSON.parse(
                                                localStorage.getItem("currency")
                                              );
                                              setOrderDetails({
                                                ...orderDetails,
                                                propina_: 0,
                                                propina_value: 0,
                                              });
                                              let usdServiceCharge =
                                                parseFloat(
                                                  orderDetails.serviceCharge
                                                ) / localCurrency.rate;
                                              let usdDiscount =
                                                parseFloat(orderDetails.discount) /
                                                localCurrency.rate;

                                              let totalPayable = 0;
                                              let calpro = ((parseFloat(0) / 100) * theSubTotal).toFixed(2);

                                              totalPayable =
                                                theSubTotal +
                                                theVat +
                                                parseFloat(calpro) +
                                                usdServiceCharge -
                                                usdDiscount;
                                              setTotalPaybale(totalPayable);
                                              setReturnMoneyUsd(
                                                totalPayable - paidMoney
                                              );
                                            }
                                          }}
                                          value={
                                            orderDetails.propina_ !== 0 &&
                                            orderDetails.propina_
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="t-pl-10 t-pr-10" style={{ background: "#0cb98b" }}>
                                <div className="row">
                                  <div className="col-4 text-right">
                                    {totalPayable ? (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "left" }}>
                                        {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                                        {formatPrice(currencyOperationSecondary() == "/" ? totalPayable / cajadetalle.tasa_cambio : totalPayable * cajadetalle.tasa_cambio)}
                                      </span>
                                    ) : (
                                      <span className="text-capitalize font-weight-bold  text-light  d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "left" }}>
                                        {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                                        {formatPrice(0)}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-4 text-center" >
                                    <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                                      Totales
                                    </span>
                                  </div>
                                  <div className="col-4">
                                    {totalPayable ? (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "right" }}>
                                        {currencySymbolLeft() + " "}
                                        {formatPrice(totalPayable)}
                                        {currencySymbolRight()}
                                      </span>
                                    ) : (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", textAlign: "right" }}>
                                        {currencySymbolLeft() + " "}
                                        {formatPrice(0)}
                                        {currencySymbolRight()}
                                      </span>
                                    )}
                                  </div>

                                </div>
                              </div>
                              <div className="t-pl-10 t-pr-10" style={totalPayable <= paidMoney ? { background: "#f64e60" } : { background: "#09c2de" }}>

                                <div className="row">

                                  <div className="col-6 text-left" >

                                    {totalPayable <= paidMoney ? (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                                        {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                                        {formatPrice(
                                          Math.abs(currencyOperationSecondary() == "/" ? returnMoneyUsd / cajadetalle.tasa_cambio : returnMoneyUsd * cajadetalle.tasa_cambio)
                                        )}
                                        {currencySymbolRight()}
                                      </span>
                                    ) : (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                                        {currencyNameSecondary() + " " + currencySymbolLeftSecondary() + " "}
                                        {formatPrice(currencyOperationSecondary() == "/" ? (totalPayable - paidMoney) / cajadetalle.tasa_cambio : (totalPayable - paidMoney) * cajadetalle.tasa_cambio)}

                                      </span>
                                    )}

                                  </div>
                                  <div className="col-6  text-right">
                                    {totalPayable <= paidMoney ? (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                                        {_t(t("Cambio")) + " " + currencySymbolLeft() + " "}
                                        {formatPrice(
                                          Math.abs(returnMoneyUsd)
                                        )}
                                        {currencySymbolRight()}
                                      </span>
                                    ) : (
                                      <span className="text-capitalize font-weight-bold text-light d-block t-pt-8 t-pb-10" style={{ fontSize: "12px", color: "white" }}>
                                        {_t(t("Saldo Pendiente")) + " " + currencySymbolLeft() + " "}
                                        {formatPrice(totalPayable - paidMoney)}

                                      </span>
                                    )}
                                  </div>

                                </div>
                              </div>
                              <div className="t-pl-10 t-pr-10">
                                {orderDetails.payment_type ? (

                                  orderDetails.payment_type.map((item) => {
                                    return (
                                      <div className="row gx-2 align-items-center">
                                        <div className="col-6 text-left">
                                          {item.name == currencyNameSecondary() ?
                                            orderDetails.payment_amount ? (
                                              <span className="text-capitalize font-weight-bold d-block " style={{ fontSize: "12px" }}>
                                                {item.name + "(" + currencySymbolLeftSecondary() +
                                                  (
                                                    orderDetails.payment_amount[item.id] == undefined ?
                                                      0
                                                      :
                                                      orderDetails.payment_amount[item.id]
                                                  )
                                                  + ")"}
                                              </span>

                                            ) : (
                                              <span className="text-capitalize font-weight-bold d-block" style={{ fontSize: "12px" }}>
                                                {item.name + "(" + currencySymbolLeftSecondary() + "0)"}
                                              </span>
                                            )
                                            :
                                            <span className="text-capitalize font-weight-bold d-block" style={{ fontSize: "12px" }}>
                                              {item.name}
                                            </span>
                                          }

                                        </div>
                                        <div className="col-6">
                                          {orderDetails.payment_amount ?
                                            orderDetails.payment_amount[item.id] == undefined ?
                                              < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                                {currencySymbolLeft()}
                                                {formatPrice(
                                                  Math.abs(0)
                                                )}
                                                {currencySymbolRight()}
                                              </span>

                                              :
                                              item.name == currencyNameSecondary() ?
                                                < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                                  {currencySymbolLeft()}
                                                  {formatPrice(
                                                    Math.abs(currencyOperationSecondary() == "/" ? orderDetails.payment_amount[item.id] * cajadetalle.tasa_cambio : orderDetails.payment_amount[item.id] / cajadetalle.tasa_cambio)
                                                  )}
                                                  {currencySymbolRight()}
                                                </span>


                                                :
                                                < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                                  {currencySymbolLeft()}
                                                  {formatPrice(
                                                    Math.abs(orderDetails.payment_amount[item.id])
                                                  )}
                                                  {currencySymbolRight()}
                                                </span>

                                            :
                                            < span className="text-capitalize font-weight-bold d-block  text-right" style={{ fontSize: "12px" }}>
                                              {currencySymbolLeft()}
                                              {formatPrice(
                                                Math.abs(0)
                                              )}
                                              {currencySymbolRight()}
                                            </span>
                                          }
                                        </div>
                                      </div>
                                    );
                                  }
                                  )


                                ) : (
                                  <span className="text-capitalize font-weight-bold d-block" style={{ fontSize: "12px" }}>
                                    esperando
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Display Only Large Screen End  */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Right Side End */}
            </div>
          </div>
        </div>
        {/* Show In Tab   */}
      </main>
    </>
  );
};

export default Pos;
