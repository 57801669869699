import React, { useEffect, useContext, useState } from "react";
import { NavLink, withRouter, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FoodContext } from "../../../contexts/Food";
//importing context consumer here
import { UserContext } from "../../../contexts/User";
import { SettingsContext } from "../../../contexts/Settings";
import { BASE_URL } from "../../../BaseUrl";


//functions
import {
  _t,
  restaurantMenuLink,
  deleteCookie,
  getSystemSettings,
} from "../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import Skeleton from "react-loading-skeleton";

const RestaurantHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  let { getcaja } = useContext(
    FoodContext
  );
  //getting context values here
  let {
    //common
    loading,
    setLoading,
    generalSettings,
  } = useContext(SettingsContext);
  const { authUserInfo } = useContext(UserContext);

  //useEffect == componentDidMount()
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    getcaja();
  }, []);

  //logout
  const handleLogout = () => {
    deleteCookie();
  };
  return (
    <>
      <Helmet>
        <title>
          {generalSettings && getSystemSettings(generalSettings, "siteName")}
        </title>
      </Helmet>
      <main>
        <div className="fk-scroll--index t-mt-15 t-mb-15" data-simplebar>
          <div className="container">
            <div className="row gx-3">
              {!loading ? (
                <>
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Purchases")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/compras.gif",
                      [_t(t("Compras"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Compras"))],
                      "/dashboard/purchases"
                    )
                    : null}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Purchases")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/desechos.jpeg",
                      [_t(t("Desperdicios"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Desperdicios"))],
                      "/dashboard/desperdicios"
                    )
                    : null}


                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Purchases")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/gastos.jpeg",
                      [_t(t("Gastos"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Gastos"))],
                      "/dashboard/gastos"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Inventory")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/proveedores.gif",
                      [_t(t("Proveedores/cuentas"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Proveedores/cuentas"))],
                      "/dashboard/inventory"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Inventory")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/cuentasporcobrar.gif",
                      [_t(t("Clientes/cuentas"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Cuentas por Cobrar"))],
                      "/dashboard/clients"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Bar")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/panelbarra.gif",
                      [_t(t("Panel de Barra"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Panel de Barra"))],
                      "/dashboard/bar"
                    )
                    : null}

                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Box")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/caja.gif",
                      [_t(t("Caja"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Caja"))],
                      "/dashboard/box"
                    )
                    : null}

                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Waiter")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/panelcamarero.gif",
                      [_t(t("POS Camarero"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("POS Camarero"))],
                      "/dashboard/waiter"
                    )
                    : null}


                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Work period")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/controldeasistencia.gif",
                      [_t(t("Control de Asistencias"))],
                      "fa fa-clock-o",
                      "t-text-alpha",
                      [_t(t("Time"))],
                      [_t(t("Control de Asistencias"))],
                      "/dashboard/work-periods"
                    )
                    : null}

                  {authUserInfo.permissions !== null &&
                    authUserInfo.details.user_type != "barra" &&
                    authUserInfo.details.user_type != "cocina" &&
                    authUserInfo.permissions.includes("POS")
                    ? authUserInfo.permissions.includes("Waiter")
                      ?
                      null : restaurantMenuLink(
                        `${BASE_URL}` + "/assets/img/poscajero.gif",
                        [_t(t("Pos Cajero"))],
                        "fa fa-cart-plus",
                        "t-text-gamma",
                        [_t(t("Pos"))],
                        [_t(t("Pos Cajero"))],
                        "/dashboard/pos"
                      )
                    : null}


                  {authUserInfo.details !== null && authUserInfo.details.user_type == "superAdmin" &&
                    authUserInfo.permissions.includes("POS")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/poscajero.gif",
                      [_t(t("Pos Cajero"))],
                      "fa fa-cart-plus",
                      "t-text-gamma",
                      [_t(t("Pos"))],
                      [_t(t("Pos Cajero"))],
                      "/dashboard/pos"
                    )
                    : null
                  }


                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Order history")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/historialdepedidos.gif",
                      [_t(t("Historial de Pedidos"))],
                      "fa fa-pencil",
                      "t-text-delta",
                      [_t(t("Orders"))],
                      [_t(t("Historial de Pedidos"))],
                      "/dashboard/orders"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Customer")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/clientes.gif",
                      [_t(t("Clientes"))],
                      "fa fa-user-circle-o",
                      "t-text-primary",
                      [_t(t("Clientes"))],
                      [_t(t("Clientes"))],
                      "/dashboard/customers"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Kitchen")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/paneldecocina.gif",
                      [_t(t("Panel de Cocina"))],
                      "fa fa-coffee",
                      "t-text-epsilon",
                      [_t(t("Panel de Cocina"))],
                      [_t(t("Panel de Cocina"))],
                      "/dashboard/kitchen"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Report")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/informes.gif",
                      [_t(t("Informes"))],
                      "fa fa-clock-o",
                      "t-text-kappa",
                      [_t(t("Informes"))],
                      [_t(t("Informes"))],
                      "/dashboard/reports"
                    )
                    : null}
                  {/* image, imgAltTxt, smallInfoIcon, infoTextColorName, infoText, title, redirectToUrl */}
                  {authUserInfo.permissions !== null &&
                    authUserInfo.permissions.includes("Manage")
                    ? restaurantMenuLink(
                      `${BASE_URL}` + "/assets/img/configuracion.gif",
                      [_t(t("Configuracion"))],
                      "fa fa-clock-o",
                      "t-text-zeta",
                      [_t(t("Configuracion"))],
                      [_t(t("Configuracion"))],
                      "/dashboard/manage/food/add-new"
                    )
                    : null}

                  <div className="col-md-6 col-lg-4 t-mb-15">
                    <button
                      onClick={handleLogout}
                      className="t-link product-card t-bg-white pb-2 border-0 text-left"
                    >
                      <div className="product-card__head">
                        <img
                          src={`${BASE_URL}` + "/assets/img/logout.gif"}
                          alt={_t(t("Logout"))}
                          className="img-fluid"
                        />
                      </div>
                      <div className="product-card__body">
                        <div className="product-card__add">
                          <span className="product-card__add-icon">
                            <span className="las la-plus"></span>
                          </span>
                        </div>
                        <span
                          className={`product-card__sub-title t-text-alpha text-uppercase`}
                        >
                          <span className="fa fa-clock-o"></span>{" "}
                          {_t(t("Cerrar Sesion"))}
                        </span>
                        <span className="product-card__title text-capitalize">
                          {_t(t("Cerrar Sesion"))}
                        </span>
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                  <div className="col-md-6 col-lg-4 t-mb-30">
                    <Skeleton
                      style={{ height: "250px" }}
                      className="bg-white"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(RestaurantHome);
