import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory, NavLink } from "react-router-dom";
import Moment from "react-moment";
//pages & includes
//import ManageSidebar from "../ManageSidebar";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  getSystemSettings,
  currencySymbolLeft,
  currencySymbolRight,
  currencyNameSecondary,
  currencyOperationSecondary,
  currencySymbolLeftSecondary,
  currencyName,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import { SettingsContext } from "../../../../contexts/Settings";
//3rd party packages
import { Helmet } from "react-helmet";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { UserContext } from "../../../../contexts/User";
import { useReactToPrint } from "react-to-print";
//context consumer
import { FoodContext } from "../../../../contexts/Food";
const Box = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    //common
    generalSettings,
  } = useContext(SettingsContext);
  //getting context values here
  let {
    //common
    loading,
    setLoading,
    cajadetalle,
    setCaja,
    getcaja
  } = useContext(FoodContext);
  const {
    authUserInfo,
  } = useContext(UserContext);

  const [poscash, setposcash] = useState({
    id: 0,
    user_id: 0,
    cash_in_hand_cordobas: 0,
    cash_in_hand_cor_dol_full: 0,
    cash_data_cordobas: 0,
    total_cash_submitted_cordobas: 0,
    total_cash_submitted_data_cordobas: 0,
    cash_in_hand_dolares: 0,
    cash_data_dolares: 0,
    total_cash_submitted_dolares: 0,
    total_cash_submitted_data_dolares: 0,
    tasa_cambio: 0,
    branch_id: 0
  });
  const [poscashcierre, setposcashcierre] = useState({
    id: 0,
    user_id: 0,
    cash_in_hand_cordobas: 0,
    cash_data_cordobas: 0,
    total_cash_submitted_cordobas: 0,
    total_cash_submitted_data_cordobas: 0,
    cash_in_hand_dolares: 0,
    cash_data_dolares: 0,
    total_cash_submitted_dolares: 0,
    total_cash_submitted_data_dolares: 0,
    tasa_cambio: 0,
    branch_id: 0,
    verified_by: 0,
    closed_by: 0,
    note: ""
  });
  const [datadetallecaja, setdatadetallecaja] = useState({
    id_caja: 0,
    pago_clientes: 0,
    pago_proveedores: 0,
    saldo_inicial_cordobas: 0,
    saldo_inicial_dolares: 0,
    tasa_cambio: 0,
    total_tarjetas: 0,
    total_ventas: 0,
    total_dolares: 0,
    total_cambio: 0
  });
  const [datausers, setdatausers] = useState([]);
  const [datadetallecajaprint, setdatadetallecajaprint] = useState(
    {
      aperturado_por: "Bagents",
      cash_data_cordobas: [{ "name": "C$ - 1000", "value": 1000, "valuefron": "1", "total": 1000 }, { "name": "C$ - 500", "value": 500, "valuefron": 0, "total": 0 }, { "name": "C$ - 200", "value": 200, "valuefron": 0, "total": 0 }, { "name": "C$ - 100", "value": 100, "valuefron": 0, "total": 0 }, { "name": "C$ - 50", "value": 50, "valuefron": 0, "total": 0 }, { "name": "C$ - 20", "value": 20, "valuefron": 0, "total": 0 }, { "name": "C$ - 10", "value": 10, "valuefron": 0, "total": 0 }, { "name": "C$ - 5", "value": 5, "valuefron": 0, "total": 0 }, { "name": "C$ - 1", "value": 1, "valuefron": 0, "total": 0 }],
      cash_data_dolares: [{ "name": "$ - 1000", "value": 1000, "valuefron": "1", "total": 1000 }, { "name": "$ - 500", "value": 500, "valuefron": 0, "total": 0 }, { "name": "$ - 200", "value": 200, "valuefron": 0, "total": 0 }, { "name": "$ - 100", "value": 100, "valuefron": 0, "total": 0 }, { "name": "$ - 50", "value": 50, "valuefron": 0, "total": 0 }, { "name": "$ - 20", "value": 20, "valuefron": 0, "total": 0 }, { "name": "$ - 10", "value": 10, "valuefron": 0, "total": 0 }, { "name": "$ - 5", "value": 5, "valuefron": 0, "total": 0 }, { "name": "$ - 1", "value": 1, "valuefron": 0, "total": 0 }],
      cerrado_por: "Bagents",
      fecha_creada: "2021-03-15 16:13:50",
      id_caja: 11,
      note: "test note cerrado",
      pago_clientes: null,
      pago_proveedores: null,
      saldo_inicial_cordobas: 1000,
      saldo_inicial_dolares: 200,
      tasa_cambio: 35.5,
      total_dolares: null,
      total_gastos: 130,
      total_tarjetas: null,
      total_ventas: 102,
      total_cambio: 0,
      verificado_por_id: "Luis Martinez"
    }
  );

  const [datacashcordobas, setdatacashcordobas] = useState({
    datacashcordobas: [
      { name: 'C$-1000', value: 1000, valuefron: 0, total: 0 },
      { name: 'C$-500', value: 500, valuefron: 0, total: 0 },
      { name: 'C$-200', value: 200, valuefron: 0, total: 0 },
      { name: 'C$-100', value: 100, valuefron: 0, total: 0 },
      { name: 'C$-50', value: 50, valuefron: 0, total: 0 },
      { name: 'C$-20', value: 20, valuefron: 0, total: 0 },
      { name: 'C$-10', value: 10, valuefron: 0, total: 0 },
      { name: 'C$-5', value: 5, valuefron: 0, total: 0 },
      { name: 'C$-1', value: 1, valuefron: 0, total: 0 },
      { name: 'C$-0.50', value: 0.5, valuefron: 0, total: 0 },
    ]
  });
  const [datacashdolares, setdatacashdolares] = useState({
    datacashdolares: [
      { name: '$-1000', value: 1000, valuefron: 0, total: 0 },
      { name: '$-500', value: 500, valuefron: 0, total: 0 },
      { name: '$-200', value: 200, valuefron: 0, total: 0 },
      { name: '$-100', value: 100, valuefron: 0, total: 0 },
      { name: '$-50', value: 50, valuefron: 0, total: 0 },
      { name: '$-20', value: 20, valuefron: 0, total: 0 },
      { name: '$-10', value: 10, valuefron: 0, total: 0 },
      { name: '$-5', value: 5, valuefron: 0, total: 0 },
      { name: '$-1', value: 1, valuefron: 0, total: 0 },
      { name: '$-0.50', value: 0.5, valuefron: 0, total: 0 },
    ]
  });
  //useEffect == componentDidMount
  useEffect(() => {
    console.log("hola desde caja")
    if (cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined) {
      //history.replace("/dashboard/box");
      toast.error(`${_t(t("Debe abrir caja"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });

    } else {
      toast.success(`${_t(t("Caja abierta"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
      getcajadetalle();
    }

  }, []);
  const handleSetposcash = (e) => {
    setposcash({
      ...poscash, [e.target.name]: e.target.value,
      branch_id: authUserInfo.details.branch_id,
      user_id: authUserInfo.details.id,
    });
  };
  const handleonchancashcordobas = (e) => {
    e.preventDefault();
    if (datacashcordobas.datacashcordobas) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      let total = 0;
      let total_dol_cor = 0;
      datacashcordobas.datacashcordobas.map((newOrderItem, index) => {
        if (newOrderItem.name == e.target.name) {
          total = total + (e.target.value * newOrderItem.value)

          ingredientTemp = {
            ...newOrderItem,
            valuefron: e.target.value,
            total: e.target.value * newOrderItem.value
          };
          ingredient.push(ingredientTemp);

        } else {
          total = total + (newOrderItem.valuefron * newOrderItem.value)
          ingredient.push(newOrderItem);
        }
      });
      total_dol_cor = total + (currencyOperationSecondary() == "/" ? poscash.cash_in_hand_dolares * poscash.tasa_cambio : poscash.cash_in_hand_dolares / poscash.tasa_cambio);
      setdatacashcordobas({ ...datacashcordobas, datacashcordobas: ingredient });
      cashcordobastotal(total, ingredient, parseFloat(total_dol_cor).toFixed(2));
    }
  };
  const cashcordobastotal = (total, datacashcordobas, total_dol_cor) => {
    if (cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined) {
      setposcash({
        ...poscash,
        cash_in_hand_cordobas: total,
        cash_in_hand_cor_dol_full: total_dol_cor,
        cash_data_cordobas: datacashcordobas,
        branch_id: authUserInfo.details.branch_id,
        user_id: authUserInfo.details.id,
      });
    } else {
      setposcashcierre({
        ...poscashcierre,
        id: cajadetalle.id,
        tasa_cambio: cajadetalle.tasa_cambio,
        cash_in_hand_cordobas: total,
        cash_data_cordobas: datacashcordobas,
        branch_id: authUserInfo.details.branch_id,
        user_id: authUserInfo.details.id,
      });
    }
  };
  const handleonchancashdolares = (e) => {
    e.preventDefault();

    if (poscash.tasa_cambio) {
      if (datacashdolares.datacashdolares) {
        let ingredientTemp = null; //to edit selected item
        let ingredient = []; //array to push order items
        let total = 0;
        let total_dol_cor = 0;
        datacashdolares.datacashdolares.map((newOrderItem, index) => {
          if (newOrderItem.name == e.target.name) {
            total = total + (e.target.value * newOrderItem.value)

            ingredientTemp = {
              ...newOrderItem,
              valuefron: e.target.value,
              total: e.target.value * newOrderItem.value
            };
            ingredient.push(ingredientTemp);

          } else {
            total = total + (newOrderItem.valuefron * newOrderItem.value)
            ingredient.push(newOrderItem);
          }
        });
        total_dol_cor = (currencyOperationSecondary() == "/" ? total * poscash.tasa_cambio : total / poscash.tasa_cambio) + poscash.cash_in_hand_cordobas;

        setdatacashdolares({ ...datacashdolares, datacashdolares: ingredient });
        cashdolarestotal(total, ingredient, parseFloat(total_dol_cor).toFixed(2));
      }
    } else {
      toast.error(`${_t(t("Ingresar primero tasa de cambio"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    }


  };
  const cashdolarestotal = (total, datacashdolares, total_dol_cor) => {
    if (cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined) {
      setposcash({
        ...poscash,
        cash_in_hand_dolares: total,
        cash_in_hand_cor_dol_full: total_dol_cor,
        cash_data_dolares: datacashdolares,
        branch_id: authUserInfo.details.branch_id,
        user_id: authUserInfo.details.id,
      });
    } else {
      setposcashcierre({
        ...poscashcierre,
        id: cajadetalle.id,
        cash_in_hand_dolares: total,
        cash_data_dolares: datacashdolares,
        branch_id: authUserInfo.details.branch_id,
        user_id: authUserInfo.details.id,
      });
    }

  };
  const handlesavecaja = (e) => {
    e.preventDefault();
    /*if (poscash.cash_in_hand_cordobas == 0 || poscash.cash_in_hand_dolares == 0) {
      toast.error(`${_t(t("Debe agregar desglose de monedas cordobas y dolares"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {*/
    if (poscash.tasa_cambio == 0 || poscash.tasa_cambio == "") {
      toast.error(`${_t(t("Ingrese tasa de cambio"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {

      const foodUnitUrl = BASE_URL + `/settings/post-box`;

      return axios
        .post(foodUnitUrl, poscash, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setposcash({
            id: 0,
            user_id: 0,
            cash_in_hand_cordobas: 0,
            cash_data_cordobas: 0,
            total_cash_submitted_cordobas: 0,
            total_cash_submitted_data_cordobas: 0,
            cash_in_hand_dolares: 0,
            cash_data_dolares: 0,
            total_cash_submitted_dolares: 0,
            total_cash_submitted_data_dolares: 0,
            tasa_cambio: 0,
            branch_id: 0
          });
          setposcashcierre({
            id: 0,
            user_id: 0,
            cash_in_hand_cordobas: 0,
            cash_data_cordobas: 0,
            total_cash_submitted_cordobas: 0,
            total_cash_submitted_data_cordobas: 0,
            cash_in_hand_dolares: 0,
            cash_data_dolares: 0,
            total_cash_submitted_dolares: 0,
            total_cash_submitted_data_dolares: 0,
            tasa_cambio: 0,
            branch_id: 0,
            verified_by: 0,
            closed_by: 0,
            note: ""
          });
          setdatadetallecaja({
            id_caja: 0,
            pago_clientes: 0,
            pago_proveedores: 0,
            saldo_inicial_cordobas: 0,
            saldo_inicial_dolares: 0,
            tasa_cambio: 0,
            total_tarjetas: 0,
            total_ventas: 0
          });
          setdatacashcordobas({
            datacashcordobas: [
              { name: 'C$-1000', value: 1000, valuefron: 0, total: 0 },
              { name: 'C$-500', value: 500, valuefron: 0, total: 0 },
              { name: 'C$-200', value: 200, valuefron: 0, total: 0 },
              { name: 'C$-100', value: 100, valuefron: 0, total: 0 },
              { name: 'C$-50', value: 50, valuefron: 0, total: 0 },
              { name: 'C$-20', value: 20, valuefron: 0, total: 0 },
              { name: 'C$-10', value: 10, valuefron: 0, total: 0 },
              { name: 'C$-5', value: 5, valuefron: 0, total: 0 },
              { name: 'C$-1', value: 1, valuefron: 0, total: 0 },
            ]
          });
          setdatacashdolares({
            datacashdolares: [
              { name: '$-100', value: 100, valuefron: 0, total: 0 },
              { name: '$-50', value: 50, valuefron: 0, total: 0 },
              { name: '$-20', value: 20, valuefron: 0, total: 0 },
              { name: '$-10', value: 10, valuefron: 0, total: 0 },
              { name: '$-5', value: 5, valuefron: 0, total: 0 },
              { name: '$-1', value: 1, valuefron: 0, total: 0 }
            ]
          });
          getcaja();
          getcajadetalle();
          toast.success(`${_t(t("Caja creada con exito"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          history.replace("/dashboard");
        })
        .catch((error) => {

        });
    }
    //}

  };
  const handlecerrarcaja = (e) => {
    e.preventDefault();
    /*if (poscashcierre.cash_in_hand_cordobas == 0 || poscashcierre.cash_in_hand_dolares == 0) {
      toast.error(`${_t(t("Debe agregar desglose de monedas cordobas y dolares"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {*/
    if (poscashcierre.verified_by == 0) {
      toast.error(`${_t(t("Seleccionar persona que verifica"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {

      const foodUnitUrl = BASE_URL + `/settings/post-box-cerrar`;

      return axios
        .post(foodUnitUrl, poscashcierre, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          setCaja(null);
          setdatadetallecajaprint(res.data[0]);
          setposcash({
            id: 0,
            user_id: 0,
            cash_in_hand_cordobas: 0,
            cash_data_cordobas: 0,
            total_cash_submitted_cordobas: 0,
            total_cash_submitted_data_cordobas: 0,
            cash_in_hand_dolares: 0,
            cash_data_dolares: 0,
            total_cash_submitted_dolares: 0,
            total_cash_submitted_data_dolares: 0,
            tasa_cambio: 0,
            branch_id: 0
          });
          setposcashcierre({
            id: 0,
            user_id: 0,
            cash_in_hand_cordobas: 0,
            cash_data_cordobas: 0,
            total_cash_submitted_cordobas: 0,
            total_cash_submitted_data_cordobas: 0,
            cash_in_hand_dolares: 0,
            cash_data_dolares: 0,
            total_cash_submitted_dolares: 0,
            total_cash_submitted_data_dolares: 0,
            tasa_cambio: 0,
            branch_id: 0,
            verified_by: 0,
            closed_by: 0,
            note: ""
          });
          setdatadetallecaja({
            id_caja: 0,
            pago_clientes: 0,
            pago_proveedores: 0,
            saldo_inicial_cordobas: 0,
            saldo_inicial_dolares: 0,
            tasa_cambio: 0,
            total_tarjetas: 0,
            total_ventas: 0,
            total_cambio: 0
          });
          setdatacashcordobas({
            datacashcordobas: [
              { name: 'C$-1000', value: 1000, valuefron: 0, total: 0 },
              { name: 'C$-500', value: 500, valuefron: 0, total: 0 },
              { name: 'C$-200', value: 200, valuefron: 0, total: 0 },
              { name: 'C$-100', value: 100, valuefron: 0, total: 0 },
              { name: 'C$-50', value: 50, valuefron: 0, total: 0 },
              { name: 'C$-20', value: 20, valuefron: 0, total: 0 },
              { name: 'C$-10', value: 10, valuefron: 0, total: 0 },
              { name: 'C$-5', value: 5, valuefron: 0, total: 0 },
              { name: 'C$-1', value: 1, valuefron: 0, total: 0 },
            ]
          });
          setdatacashdolares({
            datacashdolares: [
              { name: '$-100', value: 100, valuefron: 0, total: 0 },
              { name: '$-50', value: 50, valuefron: 0, total: 0 },
              { name: '$-20', value: 20, valuefron: 0, total: 0 },
              { name: '$-10', value: 10, valuefron: 0, total: 0 },
              { name: '$-5', value: 5, valuefron: 0, total: 0 },
              { name: '$-1', value: 1, valuefron: 0, total: 0 }
            ]
          });
          handlebaucher();
          toast.success(`${_t(t("Caja Cerrada con exito"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });

        })
        .catch((error) => {

        });
      // }
    }

  };
  const getcajadetalle = () => {
    setLoading(true);
    let formData = {
      branch_id: authUserInfo.details.branch_id,
      user_id: authUserInfo.details.id,
    };
    const customerUrl = BASE_URL + "/settings/get-caja-detalle";
    return axios
      .get(customerUrl, {

        params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },

        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setdatadetallecaja(res.data[0][0]);
        setdatadetallecajaprint(res.data[0][0]);
        setdatausers(res.data[1]);
        setposcash({
          ...poscash,
          tasa_cambio: res.data[0][0].tasa_cambio
        });
        setLoading(false);
      });
  };
  //CIERRE
  const handlesetverify = (row) => {
    setposcashcierre({
      ...poscashcierre,
      verified_by: row.id,
      closed_by: authUserInfo.details.id,
      id: cajadetalle.id,
      branch_id: authUserInfo.details.branch_id,
      user_id: authUserInfo.details.id,
    });

  };
  const handleSetposcashcierre = (e) => {
    setposcashcierre({ ...poscashcierre, [e.target.name]: e.target.value });
  };

  const componentRef = useRef();

  const handlebaucher = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      /* if (getSystemSettings(generalSettings, "print_kitchen_bill") === "1") {
         handleOnlyPrint2();
       }*/
    },
  });

  return (
    <>
      <Helmet>
        <title>{_t(t("Caja"))}</title>
      </Helmet>
      <div className="d-none">
        <div ref={componentRef}>
          {
            <div className="fk-print t-pt-30 t-pb-30">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <span className="d-block fk-print-text fk-print-text--bold text-uppercase fk-print__title text-center mb-2">
                      {getSystemSettings(generalSettings, "siteName")}
                    </span>
                    <p className="mb-0 xsm-text fk-print-text text-center text-capitalize">
                      {getSystemSettings(generalSettings, "address")}
                    </p>
                    <p className="mb-0 xsm-text fk-print-text text-center text-capitalize">
                      {_t(t("call"))}:{" "}
                      {getSystemSettings(generalSettings, "phnNo")}
                    </p>
                    <hr className="mb-0" />
                    <hr className="m-0" />
                    {datadetallecajaprint ?
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                        <div className="col-md-2">
                        </div>
                        <div className="col-md-8">
                          <div className="table-responsive">
                            <table className="table table-bordered table-hover min-table-height mt-4">
                              <thead className="align-middle">
                                <tr>
                                  <th
                                    scope="col"
                                    colSpan="2"
                                    className="text-capitalize text-center"

                                  >
                                    Caja Creada: &nbsp;
                                    < Moment format="DD-MM-YYYY hh:mm:ss">{datadetallecajaprint && datadetallecajaprint.fecha_creada}</Moment>
                                  </th>
                                </tr>
                                <tr>
                                  <th
                                    scope="col"
                                    className="text-capitalize"

                                  >
                                    {_t(t("Descripcion"))}
                                  </th>

                                  <th
                                    scope="col"
                                    className="text-capitalize"

                                  >
                                    {_t(t("Valor"))}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"

                                >
                                  <td className="d-block">Efectivo Inicial {currencyName()}</td>
                                  <td className="xsm-text align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.saldo_inicial_cordobas)}
                                    {currencySymbolRight()}

                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"

                                >
                                  <td className="d-block">Inicial Caja {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " + formatPrice(datadetallecajaprint && (datadetallecajaprint.saldo_inicial_dolares)) + ")"}  </td>
                                  <td className="xsm-text align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio))}
                                    {currencySymbolRight()}

                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"

                                >
                                  <td className="d-block">Inicial Caja ({currencyName()} + {currencyNameSecondary()}) </td>
                                  <td className="xsm-text align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.saldo_inicial_cordobas + (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio))}
                                    {currencySymbolRight()}
                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Inicial Caja</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.saldo_inicial_cordobas)}
                                    {currencySymbolRight()}
                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Total de Ventas {currencyName()}</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && (datadetallecaja.pago_clientes + datadetallecajaprint.total_ventas - datadetallecajaprint.total_cambio))}
                                    {currencySymbolRight()}
                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Total de Ventas en {currencySymbolLeftSecondary() + " (" + currencySymbolLeftSecondary() + " " + (currencyOperationSecondary() == "/" ? datadetallecajaprint.total_dolares / datadetallecajaprint.tasa_cambio : datadetallecajaprint.total_dolares * datadetallecajaprint.tasa_cambio) + ")"}</td>
                                  <td className="xsm-text text-capitalize align-middle">

                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.total_dolares)}
                                    {currencySymbolRight()}


                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Total Pago Proveedores</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.pago_proveedores)}
                                    {currencySymbolRight()}
                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Total Tarjetas</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.total_tarjetas)}
                                    {currencySymbolRight()}

                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Total Gastos</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecajaprint.total_gastos)}
                                    {currencySymbolRight()}


                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"

                                >
                                  <td className="d-block">Efectivo Final {currencyName()}</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && datadetallecaja.pago_clientes + datadetallecajaprint.saldo_inicial_cordobas + datadetallecajaprint.total_ventas - datadetallecajaprint.total_cambio)}
                                    {currencySymbolRight()}

                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"

                                >
                                  <td className="d-block">Final {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " + formatPrice(datadetallecajaprint && (datadetallecajaprint.saldo_inicial_dolares) + (currencyOperationSecondary() == "/" ? datadetallecajaprint.total_dolares / datadetallecajaprint.tasa_cambio : datadetallecajaprint.total_dolares * datadetallecajaprint.tasa_cambio)) + ")"}  </td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {formatPrice(datadetallecajaprint && (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio) + datadetallecajaprint.total_dolares)}
                                    {currencySymbolRight()}

                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Total Operacion {currencyName()}</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {
                                      datadetallecajaprint ?


                                        formatPrice(
                                          datadetallecajaprint.saldo_inicial_cordobas +
                                          (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio) +
                                          datadetallecajaprint.total_ventas +
                                          datadetallecajaprint.total_dolares +
                                          datadetallecajaprint.pago_clientes -
                                          datadetallecajaprint.pago_proveedores -
                                          datadetallecajaprint.total_gastos - datadetallecajaprint.total_cambio) : 0
                                    }
                                    {currencySymbolRight()}
                                  </td>
                                </tr>
                                <tr
                                  scope="row"
                                  className="xsm-text align-middle"
                                >
                                  <td className="d-block">Diferencia de Efectivo {currencyName()}</td>
                                  <td className="xsm-text text-capitalize align-middle">
                                    {currencySymbolLeft()}
                                    {
                                      datadetallecajaprint ?
                                        formatPrice(
                                          (
                                            datadetallecajaprint.saldo_inicial_cordobas +
                                            (currencyOperationSecondary() == "/" ? datadetallecajaprint.saldo_inicial_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.saldo_inicial_dolares / datadetallecajaprint.tasa_cambio) +
                                            datadetallecajaprint.total_ventas +
                                            datadetallecajaprint.total_dolares +
                                            datadetallecajaprint.pago_clientes -
                                            datadetallecajaprint.pago_proveedores -
                                            datadetallecajaprint.total_gastos - datadetallecajaprint.total_cambio
                                          ) - datadetallecajaprint.total_cash_submitted_cordobas - (currencyOperationSecondary() == "/" ? datadetallecajaprint.total_cash_submitted_dolares * datadetallecajaprint.tasa_cambio : datadetallecajaprint.total_cash_submitted_dolares / datadetallecajaprint.tasa_cambio)
                                        ) : 0
                                    }
                                    {currencySymbolRight()}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="col-md-2">
                        </div>
                      </div>
                      : null}
                    <div className="row">

                      {datadetallecajaprint.cash_data_cordobas != undefined && datadetallecajaprint.cash_data_cordobas.length > 0 ?
                        datadetallecajaprint.cash_data_cordobas.map((item, index) => {
                          return (
                            <div className="col-md-6">
                              <label className="control-label">
                                {item.name + " * " + item.valuefron + " = " + item.total}
                              </label>
                            </div>
                          )
                        })
                        :
                        null
                      }
                      <hr />
                      {datadetallecajaprint.cash_data_dolares != undefined && datadetallecajaprint.cash_data_dolares.length > 0 ?
                        datadetallecajaprint.cash_data_dolares.map((item, index) => {
                          return (
                            <div className="col-md-6">
                              <label className="control-label">
                                {item.name + " * " + item.valuefron + " = " + item.total}
                              </label>
                            </div>
                          )
                        })
                        :
                        null
                      }

                    </div>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col-md-12">
                        {datadetallecajaprint.note}
                      </div>

                    </div>
                    <div className="row" style={{ marginTop: "30px" }}>
                      <div className="col-md-12" style={{ textAlign: "center" }}>
                        <span className="d-block">_________________________</span>
                        <span className="d-block">Aperturado Por</span>
                        <span className="d-block">{datadetallecajaprint.aperturado_por}</span>
                      </div>

                    </div>
                    <div className="row" style={{ marginTop: "30px" }}>
                      <div className="col-md-12" style={{ textAlign: "center" }}>
                        <span className="d-block">_________________________</span>
                        <span className="d-block">Cerrado Por:</span>
                        <span className="d-block">{datadetallecajaprint.cerrado_por}</span>
                      </div>

                    </div>
                    <div className="row" style={{ marginTop: "30px" }}>
                      <div className="col-md-12" style={{ textAlign: "center" }}>
                        <span className="d-block">_________________________</span>
                        <span className="d-block">Verificado Por:</span>
                        <span className="d-block">{datadetallecajaprint.verificado_por}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar <ManageSidebar />*/}
            <div className="col-md-12">
              <div className="t-bg-white">
                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                  <div className="col-12 t-mb-15">
                    <ul className="t-list fk-breadcrumb">
                      <li className="fk-breadcrumb__list">
                        <span className="t-link fk-breadcrumb__link text-capitalize">
                          {_t(t("Apertura de Caja"))}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-6 col-lg-5">
                  </div>
                  <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0">
                    <ul className="t-list fk-sort align-items-center justify-content-center text-right">
                      <li className="fk-sort__list mb-0 flex-grow-1">
                        <button
                          className="btn btn-success sm-text text-uppercase text-right"
                          type="buttom"
                          onClick={() => {
                            handlebaucher()
                          }}
                        >
                          {_t(t("voucher"))}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
                {cajadetalle ?
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                colSpan="2"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{ background: "#0bab80", color: "white" }}
                              >
                                Caja Creada: &nbsp;
                                < Moment format="DD-MM-YYYY hh:mm:ss">{datadetallecaja && datadetallecaja.fecha_creada}</Moment>
                              </th>
                            </tr>
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{ background: "#F64E60", color: "white" }}
                              >
                                {_t(t("Descripcion"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                style={{ background: "#F64E60", color: "white" }}
                              >
                                {_t(t("Valor"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                              style={{ background: "#158df7", color: "white" }}
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border">Efectivo Inicial {currencyName()}</td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && datadetallecaja.saldo_inicial_cordobas)}
                                {currencySymbolRight()}

                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                              style={{ background: "#158df7", color: "white" }}
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border">Inicial {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " + formatPrice(datadetallecaja && (datadetallecaja.saldo_inicial_dolares)) + ")"}  </td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio))}
                                {currencySymbolRight()}

                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                              style={{ background: "#158df7", color: "white" }}
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border">Inicial Caja ( {currencyName() + " + " + currencyNameSecondary()})</td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && datadetallecaja.saldo_inicial_cordobas + (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio))}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border  text-success">Total de Ventas {currencyName()}</td>
                              <td className="xsm-text text-capitalize align-middle text-center  text-success">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && (datadetallecaja.pago_clientes + datadetallecajaprint.total_ventas - datadetallecajaprint.total_cambio))}
                                {currencySymbolRight()}


                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border  text-success">Total de Ventas {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " + (currencyOperationSecondary() == "/" ? parseFloat(datadetallecaja.total_dolares / datadetallecaja.tasa_cambio).toFixed(2) : parseFloat(datadetallecaja.total_dolares * datadetallecaja.tasa_cambio).toFixed(2)) + ")"}</td>
                              <td className="xsm-text text-capitalize align-middle text-center  text-success">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && datadetallecaja.total_dolares)}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border  text-success">Total Tarjetas</td>
                              <td className="xsm-text text-capitalize align-middle text-center text-success">
                                {currencySymbolLeft()}

                                {formatPrice(datadetallecaja && datadetallecaja.total_tarjetas)}
                                {currencySymbolRight()}


                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border  text-primary">Total Pago Proveedores</td>
                              <td className="xsm-text text-capitalize align-middle text-center  text-primary">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && datadetallecaja.pago_proveedores)}
                                {currencySymbolRight()}

                              </td>
                            </tr>

                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border  text-primary">Total Gastos</td>
                              <td className="xsm-text text-capitalize align-middle text-center  text-primary">

                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && datadetallecaja.total_gastos)}
                                {currencySymbolRight()}

                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                              style={{ background: "#158df7", color: "white" }}
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border">Final Efectivo {currencyName()}</td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && datadetallecaja.pago_clientes + datadetallecaja.saldo_inicial_cordobas + datadetallecaja.total_ventas - datadetallecaja.total_cambio)}
                                {currencySymbolRight()}

                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                              style={{ background: "#158df7", color: "white" }}
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border">Final {currencyNameSecondary() + " (" + currencySymbolLeftSecondary() + " " +
                                formatPrice(datadetallecaja && (datadetallecaja.saldo_inicial_dolares) + (currencyOperationSecondary() == "/" ? datadetallecaja.total_dolares / datadetallecaja.tasa_cambio : datadetallecaja.total_dolares * datadetallecaja.tasa_cambio)) + ")"}  </td>

                              <td className="xsm-text text-capitalize align-middle text-center">
                                {currencySymbolLeft()}
                                {formatPrice(datadetallecaja && (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio) + datadetallecaja.total_dolares)}
                                {currencySymbolRight()}
                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border text-secondary">Total Operacion {currencyName()}</td>
                              <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                {currencySymbolLeft()}


                                {
                                  datadetallecaja ?
                                    formatPrice(
                                      datadetallecaja.saldo_inicial_cordobas +
                                      (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio) +
                                      datadetallecaja.total_ventas +
                                      datadetallecaja.total_dolares +
                                      datadetallecaja.pago_clientes -
                                      datadetallecaja.pago_proveedores -
                                      datadetallecaja.total_gastos - datadetallecaja.total_cambio) : 0
                                }
                                {currencySymbolRight()}
                              </td>
                            </tr>
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                            >
                            </tr>

                          </tbody>
                        </table>
                      </div>
                      <div className="table-responsive" style={{ marginTop: "50px" }}>
                        <table className="table table-bordered table-hover">
                          <thead className="align-middle">
                          </thead>
                          <tbody className="align-middle">
                            <tr
                              scope="row"
                              className="xsm-text align-middle text-center"
                              style={
                                formatPrice(
                                  (
                                    datadetallecajaprint.saldo_inicial_cordobas +
                                    (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio) +
                                    datadetallecajaprint.total_ventas +
                                    datadetallecajaprint.total_dolares +
                                    datadetallecajaprint.pago_clientes -
                                    datadetallecajaprint.pago_proveedores -
                                    datadetallecajaprint.total_gastos - datadetallecaja.total_cambio) - poscashcierre.cash_in_hand_cordobas - (currencyOperationSecondary() == "/" ? poscashcierre.cash_in_hand_dolares * datadetallecaja.tasa_cambio : poscashcierre.cash_in_hand_dolares / datadetallecaja.tasa_cambio)
                                ) < 0 ? { background: "#0dd19d", color: "white" } : { background: "#f75b60", color: "white", fontWeight: "bold" }
                              }
                            >
                              <td className="sm-text text-capitalize align-middle text-left border-1 border">
                                {
                                  formatPrice(
                                    (
                                      datadetallecajaprint.saldo_inicial_cordobas +
                                      (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio) +
                                      datadetallecajaprint.total_ventas +
                                      datadetallecajaprint.total_dolares +
                                      datadetallecajaprint.pago_clientes -
                                      datadetallecajaprint.pago_proveedores -
                                      datadetallecajaprint.total_gastos - datadetallecaja.total_cambio) - poscashcierre.cash_in_hand_cordobas - (currencyOperationSecondary() == "/" ? poscashcierre.cash_in_hand_dolares * datadetallecaja.tasa_cambio : poscashcierre.cash_in_hand_dolares / datadetallecaja.tasa_cambio)
                                  ) <= 0 ? "Diferencia de Efectivo (Sobrante)" : "Diferencia de Efectivo (Faltante)"
                                }
                              </td>
                              <td className="xsm-text text-capitalize align-middle text-center">
                                {currencySymbolLeft()}
                                {
                                  datadetallecaja ?
                                    formatPrice(
                                      (
                                        datadetallecaja.saldo_inicial_cordobas +
                                        (currencyOperationSecondary() == "/" ? datadetallecaja.saldo_inicial_dolares * datadetallecaja.tasa_cambio : datadetallecaja.saldo_inicial_dolares / datadetallecaja.tasa_cambio) +
                                        datadetallecaja.total_ventas +
                                        datadetallecaja.total_dolares +
                                        datadetallecaja.pago_clientes -
                                        datadetallecaja.pago_proveedores -
                                        datadetallecaja.total_gastos - datadetallecaja.total_cambio) - poscashcierre.cash_in_hand_cordobas - (currencyOperationSecondary() == "/" ? poscashcierre.cash_in_hand_dolares * datadetallecaja.tasa_cambio : poscashcierre.cash_in_hand_dolares / datadetallecaja.tasa_cambio)
                                    ) : 0
                                }
                                {currencySymbolRight()}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-2">
                    </div>
                  </div>
                  : null}

                {cajadetalle ?
                  <div className="row">
                    <form className="mx-2 sm-text" onSubmit={cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined ? handlesavecaja : handlecerrarcaja}>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                        <div className="col-md-12">

                          <div className="t-pl-15 t-pr-15">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {_t(t("Efectivo en mano cordobas"))}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cash_in_hand"
                                  name="cash_in_hand"
                                  onChange={handleSetposcash}
                                  value={cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined ? poscash.cash_in_hand_cordobas : poscashcierre.cash_in_hand_cordobas}
                                  placeholder=""
                                  required
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="row">
                              {datacashcordobas.datacashcordobas != undefined && datacashcordobas.datacashcordobas.length > 0 ?
                                datacashcordobas.datacashcordobas.map((item, index) => {
                                  return (
                                    <div className="col-md-6">
                                      <div className="row">

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <div className="mb-2">
                                              <label className="control-label">
                                                {currencySymbolLeft() + "-" + item.value}
                                              </label>
                                            </div>
                                            <div className="mb-2">
                                              <input
                                                key={index}
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                id={item.name}
                                                name={item.name}
                                                onChange={handleonchancashcordobas}
                                                value={item.valuefron}
                                                placeholder="Numero de Factura"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 xsm-text text-capitalize align-middle">

                                          <span
                                            className="btn btn-transparent btn-success xsm-text text-capitalize"
                                            style={{ marginTop: "30px" }}
                                          >

                                            {currencySymbolLeft()}
                                            {formatPrice(item.total)}
                                            {currencySymbolRight()}

                                          </span>

                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                :
                                null
                              }
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {currencyNameSecondary()}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cash_in_hand"
                                  name="cash_in_hand"
                                  //onChange={handleSetposcash}
                                  value={cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined ? poscash.cash_in_hand_dolares : poscashcierre.cash_in_hand_dolares}
                                  placeholder="Numero de Factura"
                                  required
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {_t(t("Tasa de Cambio"))}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tasa_cambio"
                                  name="tasa_cambio"
                                  onChange={handleSetposcash}
                                  value={datadetallecaja && datadetallecaja.tasa_cambio}
                                  placeholder="Tasa de cambio"
                                  required
                                  disabled={cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined ? false : true}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {datacashdolares.datacashdolares != undefined && datacashdolares.datacashdolares.length > 0 ?
                              datacashdolares.datacashdolares.map((item, index) => {
                                return (
                                  <div className="col-md-6">
                                    <div className="row">

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <div className="mb-2">
                                            <label className="control-label">
                                              {currencySymbolLeftSecondary() + "-" + item.value}
                                            </label>
                                          </div>
                                          <div className="mb-2">
                                            <input
                                              key={index}
                                              type="number"
                                              min="0"
                                              className="form-control"
                                              id={item.name}
                                              name={item.name}
                                              onChange={handleonchancashdolares}
                                              value={item.valuefron}
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 xsm-text text-capitalize align-middle">

                                        <span
                                          className="btn btn-transparent btn-success xsm-text text-capitalize"
                                          style={{ marginTop: "30px" }}
                                        >
                                          {currencySymbolLeftSecondary() + " "}
                                          {formatPrice(item.total)}

                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                              :
                              null
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="mb-2">
                              <label className="control-label">
                                {_t(t("Aperturado Por"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"

                                className="form-control"
                                id={'aperturado_por'}
                                name={'aperturado_por'}
                                onChange={handleonchancashdolares}
                                value={datadetallecaja && datadetallecaja.aperturado_por}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="mb-2">
                              <label className="control-label">
                                {_t(t("Cerrado Por"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <input
                                type="text"

                                className="form-control"
                                id={'cerrado_por'}
                                name={'cerrado_por'}
                                onChange={handleonchancashdolares}
                                value={datadetallecaja && datadetallecaja.cerrado_por}
                                placeholder=""
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="mb-2">
                              <label className="control-label">
                                {_t(t("Verificado Por"))}
                              </label>
                            </div>
                            <div className="mb-2">
                              <Select
                                options={datausers}
                                components={makeAnimated()}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.name}
                                classNamePrefix="select"
                                className="xsm-text"
                                onChange={handlesetverify}
                                maxMenuHeight="200px"
                                placeholder={_t(t("Usuarios")) + ".."}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">

                        </div>
                      </div>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="mb-12">
                              <label className="control-label">
                                {_t(t("Observacion"))}
                              </label>
                            </div>

                            <textarea className="form-control"
                              name="note"
                              onChange={handleSetposcashcierre}
                              value={poscashcierre.note}
                            >
                            </textarea>
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                        <div className="col-md-6 col-lg-5">

                        </div>
                        <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0">
                          <div className="row gx-2 align-items-center">
                            <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    className="w-100 btn btn-success sm-text text-uppercase"
                                    type="submit"
                                  >
                                    {cajadetalle == null || cajadetalle.status == 1 || cajadetalle.status == undefined ? _t(t("Abrir Caja")) : _t(t("Cerrar Caja"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  :
                  <div className="row">
                    <form className="mx-2 sm-text" onSubmit={handlesavecaja}>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                        <div className="col-md-12">

                          <div className="t-pl-15 t-pr-15">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {_t(t("Efectivo Total (Efectivo, " + currencyNameSecondary() + ")"))}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cash_in_hand_full"
                                  name="cash_in_hand_full"
                                  onChange={handleSetposcash}
                                  value={poscash.cash_in_hand_cor_dol_full}
                                  placeholder=""
                                  required
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {_t(t("Efectivo"))}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cash_in_hand"
                                  name="cash_in_hand"
                                  onChange={handleSetposcash}
                                  value={poscash.cash_in_hand_cordobas}
                                  placeholder=""
                                  required
                                  disabled
                                />
                              </div>
                            </div>

                            <div className="row">
                              {datacashcordobas.datacashcordobas != undefined && datacashcordobas.datacashcordobas.length > 0 ?
                                datacashcordobas.datacashcordobas.map((item, index) => {
                                  return (
                                    <div className="col-md-6">
                                      <div className="row">

                                        <div className="col-md-6">
                                          <div className="form-group">
                                            <div className="mb-2">
                                              <label className="control-label">
                                                {currencySymbolLeft() + "-" + item.value}
                                              </label>
                                            </div>
                                            <div className="mb-2">
                                              <input
                                                key={index}
                                                type="number"
                                                min="0"
                                                className="form-control"
                                                id={item.name}
                                                name={item.name}
                                                onChange={handleonchancashcordobas}
                                                value={item.valuefron}
                                                placeholder="Numero de Factura"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 xsm-text text-capitalize align-middle">

                                          <span
                                            className="btn btn-transparent btn-success xsm-text text-capitalize"
                                            style={{ marginTop: "30px" }}
                                          >
                                            {currencySymbolLeft()}
                                            {formatPrice(item.total)}
                                            {currencySymbolRight()}

                                          </span>

                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                                :
                                null
                              }
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {currencyNameSecondary()}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="cash_in_hand"
                                  name="cash_in_hand"
                                  //onChange={handleSetposcash}
                                  value={poscash.cash_in_hand_dolares}
                                  placeholder="Numero de Factura"
                                  required
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="mb-2">
                                  <label className="control-label">
                                    {_t(t("Tasa de Cambio"))}
                                  </label>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="tasa_cambio"
                                  name="tasa_cambio"
                                  onChange={handleSetposcash}
                                  value={poscash.tasa_cambio}
                                  placeholder="Tasa de cambio"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {datacashdolares.datacashdolares != undefined && datacashdolares.datacashdolares.length > 0 ?
                              datacashdolares.datacashdolares.map((item, index) => {
                                return (
                                  <div className="col-md-6">
                                    <div className="row">

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <div className="mb-2">
                                            <label className="control-label">
                                              {currencySymbolLeftSecondary() + "-" + item.value}
                                            </label>
                                          </div>
                                          <div className="mb-2">
                                            <input
                                              key={index}
                                              type="number"
                                              min="0"
                                              className="form-control"
                                              id={item.name}
                                              name={item.name}
                                              onChange={handleonchancashdolares}
                                              value={item.valuefron}
                                              placeholder=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 xsm-text text-capitalize align-middle">

                                        <span
                                          className="btn btn-transparent btn-success xsm-text text-capitalize"
                                          style={{ marginTop: "30px" }}
                                        >
                                          {currencySymbolLeftSecondary() + " "}
                                          {formatPrice(item.total)}
                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                              :
                              null
                            }
                          </div>
                        </div>
                      </div>

                      <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                        <div className="col-md-6 col-lg-5">

                        </div>
                        <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0">
                          <div className="row gx-2 align-items-center">
                            <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                              <ul className="t-list fk-sort align-items-center justify-content-center">
                                <li className="fk-sort__list mb-0 flex-grow-1">
                                  <button
                                    className="w-100 btn btn-success sm-text text-uppercase"
                                    type="submit"
                                  >
                                    {_t(t("Abrir Caja"))}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>

                  </div>
                }
              </div>
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default Box;
