import React, { useEffect, useContext, useState } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  currencySymbolLeft,
  formatPrice,
  currencySymbolRight,
  pageLoading,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";

const Purchases = () => {
  //getting context values here
  const {
    //common
    loading,
    setLoading,
  } = useContext(SettingsContext);

  const {
    authUserInfo,
    //pagination
    dataPaginating,
  } = useContext(UserContext);

  const {
  } = useContext(RestaurantContext);

  const { t } = useTranslation();

  // States hook here
  //new customer
  let [newCustomer, setNewCustomer] = useState({
    name: "",
    email: "",
    phn_no: "",
    address: "",
    branch: null,
    selectedBranch: null,
    edit: false,
    editSlug: null,
    uploading: false,
  });


  const [allingredients, setallingredients] = useState({
    allingredients: []
  });
  const [allsupliers, setallsupliers] = useState({
    allsupliers: []
  });
  const [purchasecar, setpurchasecar] = useState({
    purchasecar: []
  });
  const [pagodata, setpagodata] = useState({
    pagodata: {
      branch_id: 0,
      user_id: 0,
      supplier_id: 0,
      total: 0,
      comment: "",
      reference: "",
      payment_type: 0,
      amount_tendered: 0
    }
  });
  const [carttotal, setcarttotal] = useState(0);
  const [listtypepago, setlisttypepago] = useState({
    listtypepago: [
      { name: 'Efectivo', value: 1 },
      { name: 'Cheque', value: 2 },
      { name: 'Transferencia', value: 3 },
      { name: 'Tarjeta', value: 4 },
      { name: 'Deudado', value: 5 }
    ]
  });
  //useEffect == componentDidMount
  useEffect(() => {

    handlegetingredients();
    handlegetsuppliers();
  }, []);
  //optener todos los ingredientes
  const handlegetingredients = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-ingredient-all";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallingredients({ ...allingredients, allingredients: res.data });
        setLoading(false);
      });
  };
  //agregar ingrediente a la tabla
  const handleaddingredien = (row) => {
    let newitemm = {
      id: 0,
      ingredient_id: row.id,
      name: row.name,
      price: row.price,
      cost: row.cost,
      stock: row.stock,
      quantity: 0
    }
    let total = 0;
    if (purchasecar.purchasecar != undefined > purchasecar.purchasecar.length > 0) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items

      purchasecar.purchasecar.map((newOrderItem, index) => {
        if (index == 0) {
          ingredient.push(newitemm);
        };

        ingredientTemp = {
          ...newOrderItem
        };

        ingredient.push(newOrderItem);
        total = total + (newOrderItem.cost * newOrderItem.quantity)
      });

      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
    } else {
      let ingredient = []; //array to push order items
      ingredient.push(newitemm);
      total = total + (newitemm.quantity * newitemm.cost)
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
    }
    handlecartotal(total);
  };
  const handleonchangeprice = (e) => {
    if (purchasecar.purchasecar) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      purchasecar.purchasecar.map((newOrderItem, index) => {
        if (index == e.target.name) {
          ingredientTemp = {
            ...newOrderItem,
            price: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          // set other items as it was which are not selected to edit
          ingredient.push(newOrderItem);
        }
      });
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
    }
  };
  const handleonchangecost = (e) => {
    if (purchasecar.purchasecar) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items
      let total = 0;
      purchasecar.purchasecar.map((newOrderItem, index) => {
        if (index == e.target.name) {
          total = total + (newOrderItem.quantity * e.target.value)

          ingredientTemp = {
            ...newOrderItem,
            cost: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          total = total + (newOrderItem.cost * newOrderItem.quantity)
          ingredient.push(newOrderItem);
        }
      });
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
      handlecartotal(total);
    }
  };
  const handleonchangequantity = (e) => {
    if (purchasecar.purchasecar) {
      let ingredientTemp = null; //to edit selected item
      let ingredient = []; //array to push order items

      let total = 0;
      purchasecar.purchasecar.map((newOrderItem, index) => {

        if (index == e.target.name) {
          total = total + (newOrderItem.cost * e.target.value)
          ingredientTemp = {
            ...newOrderItem,
            quantity: e.target.value,
          };
          ingredient.push(ingredientTemp);

        } else {
          total = total + (newOrderItem.cost * newOrderItem.quantity)
          ingredient.push(newOrderItem);
        }
      });
      setpurchasecar({ ...purchasecar, purchasecar: ingredient });
      handlecartotal(total);
    }
  };
  const handleDeleteingredient = (removeIndex) => {
    //setLoading(true);

    let oldOrderItems = []; //array to push order items
    purchasecar.purchasecar.map((newOrderItem, index) => {
      if (index !== removeIndex) {
        //push item to orderlist
        oldOrderItems.push(newOrderItem);
      } else {
        if (newOrderItem.id != 0) {
          /*const url = BASE_URL + `/settings/delete-ingredientscon/${newOrderItem.id}`;
          return axios
            .get(url, {
              headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
              setLoading(false);
              toast.success(`${_t(t("Item has been deleted successfully"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
              //getingredients();
            })
            .catch(() => {
              setLoading(false);
              toast.error(`${_t(t("Please try again"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
              });
            });*/
        }

      }
    });
    setpurchasecar({ ...purchasecar, purchasecar: oldOrderItems });
  };
  const handlecartotal = (total) => {
    setpagodata({
      supplier_id: 0,
      total: total,
      comment: "",
      reference: "",
      payment_type: 0,
      amount_tendered: 0
    });
  };
  const handleSetNewpago = (e) => {
    setpagodata({ ...pagodata, [e.target.name]: e.target.value });
  };
  const handlesavepago = (e) => {
    e.preventDefault();
    let dt = {
      branch_id: authUserInfo.details.branch_id,
      user_id: authUserInfo.details.id,
      supplier_id: pagodata.supplier_id,
      comment: pagodata.comment,
      reference: pagodata.reference,
      payment_type: pagodata.payment_type,
      amount_tendered: pagodata.amount_tendered,
      items: purchasecar.purchasecar,
      total: pagodata.total
    }
    if (dt.payment_type == 0 || dt.supplier_id == 0) {
      toast.error(`${_t(t("Seleccionar metodo de Pago y proveedor"))}`, {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        className: "text-center toast-notification",
      });
    } else {
      const foodUnitUrl = BASE_URL + `/settings/post-purchase`;

      return axios
        .post(foodUnitUrl, dt, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
          toast.success(`${_t(t("Compra almacenada"))}`, {
            position: "bottom-center",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            className: "text-center toast-notification",
          });
          setpurchasecar({
            purchasecar: []
          });
        })
        .catch((error) => {

        });
    }

  };
  const handleselectsuplier = (row) => {
    setpagodata({ ...pagodata, supplier_id: row.id });
  };
  const handleselecttypepayment = (row) => {
    setpagodata({ ...pagodata, payment_type: row.value });
  };
  const handlegetsuppliers = () => {
    setLoading(true);
    const variationUrl = BASE_URL + "/settings/get-suppliers";
    return axios
      .get(variationUrl, {
        //params: formData,
        headers: { Authorization: `Bearer ${getCookie()}` },
      })
      .then((res) => {
        setallsupliers({ ...allsupliers, allsupliers: res.data });
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>{_t(t("Compras"))}</title>
      </Helmet>

      <div className="modal fade" id="addCustomer" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div className="fk-sm-card__content">
                <h5 className="text-capitalize fk-sm-card__title">
                  Pago de Compra
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {purchasecar.purchasecar != undefined && purchasecar.purchasecar.length > 0 ?
                <form onSubmit={handlesavepago} className="mx-2 sm-text">
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                    <div className="col-12 t-mb-15">
                      <Select
                        options={allsupliers.allsupliers}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        onChange={handleselectsuplier}
                        maxMenuHeight="200px"
                        placeholder={
                          _t(t("Seleccione Proveedor")) + ".."
                        }
                      />
                    </div>
                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                      <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                        <label htmlFor="Total" className="form-label">
                          {_t(t("Total"))}{" "}
                        </label>
                      </div>
                      <div className="col-6 t-mb-15" style={{ textAlign: "right" }}>
                        <label htmlFor="t" className="form-label">

                          {currencySymbolLeft()}
                          {formatPrice(pagodata.total)}
                          {currencySymbolRight()}

                        </label>
                      </div>
                    </div>
                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                      <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                        <textarea
                          className="form-control"
                          placeholder="Comentario"
                          name="comment"
                          value={pagodata && pagodata.comment}
                          onChange={handleSetNewpago}>
                        </textarea>
                      </div>
                    </div>
                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                      <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                        <div className="form-group">
                          <div className="mb-2">
                            <label className="control-label">
                              {_t(t("Tipo de Pago"))}
                            </label>
                          </div>
                          <Select
                            options={listtypepago.listtypepago}
                            components={makeAnimated()}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.value}
                            classNamePrefix="select"
                            onChange={handleselecttypepayment}
                            maxMenuHeight="200px"
                            placeholder={
                              _t(t("Seleccione tipo de pago")) + ".."
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                        <div className="form-group">
                          <div className="mb-2">
                            <label className="control-label">
                              {_t(t("Factura"))}
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            id="reference"
                            name="reference"
                            onChange={handleSetNewpago}
                            value={pagodata && pagodata.reference}
                            placeholder="Numero de Factura"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                      <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                        <div className="form-group">
                          <div className="mb-2">
                            <label className="control-label">
                              {_t(t("Cantidad Recibida"))}
                            </label>
                          </div>
                          <input
                            type="number"
                            step="0.01"
                            min="0"
                            className="form-control"
                            id="recibido"
                            name="amount_tendered"
                            onChange={handleSetNewpago}
                            value={pagodata && pagodata.amount_tendered}
                            placeholder="cantidad recibida"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-6">

                        </div>
                        <div className="col-6">
                          <button
                            type="submit"
                            className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                          >
                            Guardar
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                :
                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                  <div className="col-12 t-mb-15" style={{ textAlign: "center" }}>
                    <label htmlFor="t" className="form-label">
                      Favor Seleccionar Ingredientes
                      </label>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {/* Add modal Ends*/}

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container">
          <div className="row t-mt-10 gx-2">
            <div className="col-12 t-mb-30 mb-lg-0">
              {newCustomer.uploading === true || loading === true ? (
                pageLoading()
              ) : (
                <div className="t-bg-white ">
                  {/* next page data spin loading */}
                  <div className={`${dataPaginating && "loading"}`}></div>
                  {/* spin loading ends */}
                  <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                    <div className="col-12 t-mb-15">
                      <ul className="t-list fk-breadcrumb">
                        <li className="fk-breadcrumb__list">
                          <span className="t-link fk-breadcrumb__link text-capitalize">
                            {_t(t("Compras"))}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6 col-lg-5">
                      <Select
                        options={allingredients.allingredients}
                        components={makeAnimated()}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        classNamePrefix="select"
                        onChange={handleaddingredien}
                        maxMenuHeight="200px"
                        placeholder={
                          _t(t("Seleccione el ingrediente")) + ".."
                        }
                      />
                    </div>
                    <div className="col-md-6 col-lg-7 t-mb-15 mb-md-0">
                      <div className="row gx-2 align-items-center">
                        <div className="col-12 col-md-5 ml-auto mt-2 mt-md-0">
                          <ul className="t-list fk-sort align-items-center justify-content-center">
                            <li className="fk-sort__list mb-0 flex-grow-1">
                              <button
                                className="w-100 btn btn-secondary sm-text text-uppercase"
                                data-toggle="modal"
                                data-target="#addCustomer"
                              >
                                {_t(t("Agregar Pago"))}
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="fk-scroll--order-history" data-simplebar>
                    <div className="t-pl-15 t-pr-15">
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover min-table-height mt-4">
                          <thead className="align-middle">
                            <tr>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("S/L"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Producto"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Costo"))}
                              </th>
                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Cantidad"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Total"))}
                              </th>

                              <th
                                scope="col"
                                className="sm-text text-capitalize align-middle text-center border-1 border"
                              >
                                {_t(t("Action"))}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="align-middle">
                            {purchasecar.purchasecar && purchasecar.purchasecar.length > 0 ? (
                              [
                                purchasecar.purchasecar.map((item, index) => {
                                  return (
                                    <tr
                                      scope="row"
                                      className="xsm-text align-middle text-center"
                                    >
                                      <td className="xsm-text text-capitalize align-middle text-center">
                                        {index + 1}
                                      </td>
                                      <td className="xsm-text text-capitalize align-middle text-center">
                                        {item.name}
                                      </td>
                                      <td className="xsm-text text-capitalize align-middle text-center">
                                        <input
                                          type="number"
                                          min="0"
                                          style={{ width: "50px" }}
                                          name={index}
                                          value={item.cost}
                                          onChange={(e) => {
                                            handleonchangecost(e);
                                          }}
                                          className="form-control xsm-text text-center variation-min-price-input-width"
                                          placeholder="Ingrese Costo"
                                        />
                                      </td>
                                      <td className="xsm-text text-capitalize align-middle text-center">
                                        <input
                                          type="number"
                                          style={{ width: "50px" }}
                                          min="0"
                                          name={index}
                                          value={item.quantity}
                                          onChange={(e) => {
                                            handleonchangequantity(e);
                                          }}
                                          className="form-control xsm-text text-center variation-min-price-input-width"
                                          placeholder="Ingrese cantidad"
                                        />
                                      </td>
                                      <td className="xsm-text text-capitalize align-middle text-center">
                                        {item.cost * item.quantity}
                                      </td>
                                      <td className="xsm-text text-capitalize align-middle text-center">
                                        <button
                                          className="btn t-bg-clear t-text-dark--light-40"
                                          type="button"
                                          onClick={() =>
                                            handleDeleteingredient(index)
                                          }
                                        >
                                          <i className="fa fa-trash-o"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }),
                              ]
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Purchases;
