import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";

//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ReportSidebar from "./ReportSidebar";
const Gastos = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    const {

        dataPaginating,
    } = useContext(RestaurantContext);

    const { t } = useTranslation();


    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });

    //search result
    const [searchedOrder, setSearchedOrder] = useState({
        list: null,
        searched: false,
    });

    const [dataget, setdataget] = useState(null);
    const [datagetForSearch, setdatagetForSearch] = useState(
        null
    );
    const [totaldata, settotaldata] = useState(0);
    //useEffect == componentDidMount
    useEffect(() => { getdataget() }, []);
    const settotal = (data) => {
        let total = 0;
        data.map((propertyItem, thisIndex) => {
            total = parseFloat(total) + parseFloat(propertyItem.amount) ;
        })
        settotaldata(total);
    };
    const getdataget = () => {
        setLoading(true);
        const url = BASE_URL + "/settings/report-get-getExpenses";
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdataget(res.data);
                settotal(res.data);
                setdatagetForSearch(res.data);
                setLoading(false);
            });
    };

    //search submitted orders here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedOrder({ ...searchedOrder, searched: false });
        } else {
            let searchedList = dataget.filter((item) => {
                //token
                let lowerCaseItemToken = JSON.stringify(item.id);

                //customer
                let lowerCaseItemCustomer = item.charged_to_name.toLowerCase();

                //table
                //let lowerCaseItemTable = item.table_name.toLowerCase();

                //branch
                //let lowerCaseItemBranch = item.branch_name.toLowerCase();
                return (
                    lowerCaseItemToken.includes(searchInput) ||
                    lowerCaseItemCustomer.includes(searchInput)
                    //lowerCaseItemTable.includes(searchInput) ||
                    //(lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
                );
            });
            setSearchedOrder({
                ...searchedOrder,
                list: searchedList,
                searched: true,
            });
        }
    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Ordenes"))}</title>
            </Helmet>

            {/* Settle modal */}
            <div className="modal fade" id="orderDetails" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    {/* show order token on modal header */}
                                    {_t(t("Order details, Compra"))}: #
                  {checkOrderDetails.item && checkOrderDetails.item.id}
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        {/* if loading true show loading effect */}
                        {loading ? (
                            <div className="modal-body">{modalLoading(5)}</div>
                        ) : (
                            <div className="modal-body">
                                {checkOrderDetails.item &&
                                    //show this if order is cancelled
                                    parseInt(checkOrderDetails.item.due) == 0 && (
                                        <div className="text-center bg-secondary text-white py-2">
                                            {_t(t("Esta compra a sido cancelada"))}
                                        </div>
                                    )}
                                {checkOrderDetails.item &&
                                    //show this if order is cancelled
                                    parseInt(checkOrderDetails.item.due) != 0 && (
                                        <div className="text-center bg-primary text-white py-2">
                                            {_t(t("Esta compra no a sido cancelada"))}
                                        </div>
                                    )}
                                {/* show this if order settle is not true, if true show payment input field */}

                                <div className="col-12 filtr-item">
                                    <div className="fk-order-token t-bg-white">
                                        <div className="fk-order-token__body">
                                            <div className="fk-addons-table">
                                                <div className="fk-addons-table__head text-center">
                                                    {_t(t("Compra "))}: #{checkOrderDetails.item && checkOrderDetails.item.id}
                                                </div>
                                                <div className="fk-addons-table__info">
                                                    <div className="row g-0">
                                                        <div className="col-2 text-center border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("S/L"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-4 text-center border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Ingrediente"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-left pl-2 border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Costo"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-2 text-center border-right">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Cantidad"))}
                                                            </span>
                                                        </div>
                                                        <div className="col-1 text-center">
                                                            <span className="fk-addons-table__info-text text-capitalize">
                                                                {_t(t("Total"))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.orderedItems.map(
                                                        (thisItem, indexThisItem) => {
                                                            return (
                                                                <div className="fk-addons-table__body-row">
                                                                    <div className="row g-0">
                                                                        <div className="col-2 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {indexThisItem + 1}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-4 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {thisItem.ingredient_name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-3 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {thisItem.cost}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-2 text-center border-right d-flex">
                                                                            <span className="fk-addons-table__info-text text-capitalize m-auto">
                                                                                {thisItem.quantity_purchase}
                                                                            </span>
                                                                        </div>

                                                                        <div className="col-1 text-center d-flex">
                                                                            <label className="mx-checkbox mx-checkbox--empty m-auto">
                                                                                <span className="mx-checkbox__text text-capitalize t-text-heading fk-addons-table__body-text">
                                                                                    {thisItem.quantity_purchase * thisItem.cost}
                                                                                </span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <table className="table table-striped table-sm text-center mt-3">
                                    <thead className="bg-info text-white text-uppercase">
                                        <tr>
                                            <th scope="col" colSpan="2">
                                                {_t(t("Detalle de la Compra"))}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-capitalized">
                                                {_t(t("Elaborada por"))}
                                            </td>
                                            <td>
                                                {checkOrderDetails.item &&
                                                    checkOrderDetails.item.user_name}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-capitalized">{_t(t("Total"))}</td>
                                            <td>
                                                {checkOrderDetails.item && (
                                                    <>
                                                        {currencySymbolLeft()}
                                                        {formatPrice(checkOrderDetails.item.total)}
                                                        {currencySymbolRight()}
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* Settle modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>
                        <div className="col-9 t-mb-30 mb-lg-0">
                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {!searchedOrder.searched
                                                            ? _t(t("Reportes de gastos"))
                                                            : _t(t("Search Result"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-lg-5 t-mb-15 mb-md-0">
                                            <ul className="t-list fk-sort align-items-center">
                                                <div className="input-group col">
                                                    <div className="form-file">
                                                        <input
                                                            type="text"
                                                            className="form-control border-0 form-control--light-1 rounded-0"
                                                            placeholder={
                                                                _t(t("Busqueda por id, cliente")) +
                                                                ".."
                                                            }
                                                            onChange={handleSearch}
                                                        />
                                                    </div>
                                                    <button className="btn btn-primary" type="button">
                                                        <i className="fa fa-search" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-lg-7">

                                        </div>
                                    </div>
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                            <div className="col-12 t-mb-15  text-right">
                                                <ReactHTMLTableToExcel
                                                    id="test-table-xls-button"
                                                    className="btn  btn-secondary text-uppercase sm-text"
                                                    table="table-to-xls"
                                                    filename="tablexls"
                                                    sheet="tablexls"
                                                    buttonText="Exportar a XLS" />
                                            </div>

                                        </div>
                                    <div className="fk-scroll--order-history" data-simplebar>
                                        <div className="t-pl-15 t-pr-15">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("#"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Elaborado por"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Total"))}
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                            >
                                                                {_t(t("Nota"))}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">
                                                        {/* loop here, logic === !search && haveData && haveDataLegnth > 0*/}
                                                        {!searchedOrder.searched
                                                            ? [
                                                                dataget && [
                                                                    dataget.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        dataget.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={index}
                                                                                >
                                                                                    <th
                                                                                        scope="row"
                                                                                        className="xsm-text text-capitalize align-middle text-center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </th>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.charged_to_name}
                                                                                    </td>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center">
                                                                                        <span className="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                                                            {item.amount}
                                                                                        </span>
                                                                                    </td>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.nota}
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ),
                                                                ],
                                                            ]
                                                            : [
                                                                /* searched data, logic === haveData*/
                                                                searchedOrder && [
                                                                    searchedOrder.list.length === 0 ? (
                                                                        <tr className="align-middle">
                                                                            <td
                                                                                scope="row"
                                                                                colSpan="8"
                                                                                className="xsm-text align-middle text-center"
                                                                            >
                                                                                {_t(t("No data available"))}
                                                                            </td>
                                                                        </tr>
                                                                    ) : (
                                                                        searchedOrder.list.map((item, index) => {
                                                                            return (
                                                                                <tr
                                                                                    className="align-middle"
                                                                                    key={index}
                                                                                >
                                                                                    <th
                                                                                        scope="row"
                                                                                        className="xsm-text text-capitalize align-middle text-center"
                                                                                    >
                                                                                        {index + 1}
                                                                                    </th>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.charged_to_name}
                                                                                    </td>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center">
                                                                                        <span className="btn btn-transparent btn-primary xsm-text text-capitalize px-3">
                                                                                            {item.amount}
                                                                                        </span>
                                                                                    </td>

                                                                                    <td className="xsm-text text-capitalize align-middle text-center text-secondary">
                                                                                        {item.nota}
                                                                                    </td>

                                                                                </tr>
                                                                            );
                                                                        })
                                                                    ),
                                                                ],
                                                            ]}
                                                    </tbody>
                                                </table>
                                            </div>
                                                                                        <div className="row t-mt-10 gx-2">
                                                {/* left Sidebar */}
                                                <div className="col-lg-4  t-shadow">

                                                </div>
                                                <div className="col-lg-4  t-shadow">

                                                </div>
                                                <div className="col-lg-4  t-shadow">
                                                    <div className="product-card__body">
                                                        <div className="product-card__add" style={{ top: "0px" }}>
                                                            <span className="product-card__add-icon">
                                                                <span><i className="fa fa-money"></i></span>
                                                            </span>
                                                        </div>
                                                        <span
                                                            className={`product-card__sub-title t-text-alpha text-uppercase`}
                                                        >
                                                            <span className="fa fa-clock-o"></span>{" "}
                                                            {_t(t("Total Deuda")) + " " + totaldata}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* pagination loading effect */}
                            {checkOrderDetails.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedOrder.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}

                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                                                <span className="d-inline-block sm-text">
                                                                    {showingData(dataget)}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() =>
                                                                    setSearchedOrder({
                                                                        ...searchedOrder,
                                                                        searched: false,
                                                                    })
                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                                            <span className="d-inline-block sm-text">
                                                                {searchedShowingData(
                                                                    searchedOrder,
                                                                    datagetForSearch
                                                                )}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Gastos;
