import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
//functions
import {
    _t,
    getCookie,
    currencySymbolLeft,
    formatPrice,
    currencySymbolRight,
    modalLoading,
    pageLoading,
    paginationLoading,
    pagination,
    showingData,
    searchedShowingData,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Moment from "react-moment";
import DatePicker from "react-datepicker";
//importing context consumer here
import { UserContext } from "../../../../contexts/User";
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import ReportSidebar from "./ReportSidebar";
const EstadoResultado = () => {
    //getting context values here
    const {
        //common
        loading,
        setLoading,
    } = useContext(SettingsContext);

    const {

        dataPaginating,
    } = useContext(RestaurantContext);

    const { t } = useTranslation();


    //settle order
    const [checkOrderDetails, setCheckOrderDetails] = useState({
        item: null,
        settle: false,
        uploading: false,
        payment_type: null,
        payment_amount: null,
    });
    const [posform, setposform] = useState({
        id: 0,
        user_id: "",
        total: 0,
        note: 0,
    });
    //search result
    const [searchedOrder, setSearchedOrder] = useState({
        list: null,
        searched: false,
    });
    let { adminStaffForSearch, authUserInfo } = useContext(UserContext);
    const [dataget, setdataget] = useState(null);
    const [datagetForSearch, setdatagetForSearch] = useState(
        null
    );
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [theUser, setTheUser] = useState(null);
    const [posabono, setposabono] = useState({
        branch_id: 0,
        user_id: 0,
        id: 0,
        amount: 0,
        total: 0
    });
    //useEffect == componentDidMount
    useEffect(() => { }, []);

    const getdataget = () => {
        setLoading(true);
        const url = BASE_URL + "/settings/get-result";
        return axios
            .get(url, {
                headers: { Authorization: `Bearer ${getCookie()}` },
            })
            .then((res) => {
                setdataget(res.data[0][0]);
                setdatagetForSearch(res.data);
                setLoading(false);
            });
    };
    const getdataget2 = () => {
        if (startDate !== null && endDate !== null) {
            setLoading(true);
            var fromDate = startDate.toISOString();
            var toDate = endDate.toISOString();
            let formData = {
                fromDate,
                toDate,
                //user_id: theUser.id,
            };
            const url = BASE_URL + "/settings/get-result";
            return axios
                .post(url, formData, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    setdataget(res.data[0][0]);
                    setdatagetForSearch(res.data);
                    setLoading(false);
                });
        } else {
            toast.error(
                `${_t(t("Seleccione rango de fecha y usuario"))}`,
                {
                    position: "bottom-center",
                    closeButton: false,
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    className: "text-center toast-notification",
                }
            );
        }
    };
    const handleUser = (user) => {
        setTheUser(user);
    };
    //search submitted orders here
    const handleSearch = (e) => {
        let searchInput = e.target.value.toLowerCase();
        if (searchInput.length === 0) {
            setSearchedOrder({ ...searchedOrder, searched: false });
        } else {
            let searchedList = dataget.filter((item) => {
                //token
                let lowerCaseItemToken = JSON.stringify(item.id);

                //customer
                let lowerCaseItemCustomer = item.charged_to_name.toLowerCase();

                //table
                //let lowerCaseItemTable = item.table_name.toLowerCase();

                //branch
                //let lowerCaseItemBranch = item.branch_name.toLowerCase();
                return (
                    lowerCaseItemToken.includes(searchInput) ||
                    lowerCaseItemCustomer.includes(searchInput)
                    //lowerCaseItemTable.includes(searchInput) ||
                    //(lowerCaseItemBranch && lowerCaseItemBranch.includes(searchInput))
                );
            });
            setSearchedOrder({
                ...searchedOrder,
                list: searchedList,
                searched: true,
            });
        }
    };
    const handlesaveabono = (e) => {
        e.preventDefault();
        if (posabono.amount == 0) {
            toast.error(`${_t(t("El abono debe ser mayor a cero"))}`, {
                position: "bottom-center",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                className: "text-center toast-notification",
            });
        } else {
            const foodUnitUrl = BASE_URL + `/settings/post-abono-wasted`;

            return axios
                .post(foodUnitUrl, posabono, {
                    headers: { Authorization: `Bearer ${getCookie()}` },
                })
                .then((res) => {
                    toast.success(`${_t(t("Abono almacenado"))}`, {
                        position: "bottom-center",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        className: "text-center toast-notification",
                    });
                    getdataget();
                    //handlesetsupplier(selectsuplier);
                    //handlegetcuentas(selectsuplier);
                    //handlesetabono(null, false)
                })
                .catch((error) => {

                });
        }

    };
    const handleonchanguedeudapos = (e) => {
        setposabono({ ...posabono, [e.target.name]: e.target.value });
    };
    return (
        <>
            <Helmet>
                <title>{_t(t("Ordenes"))}</title>
            </Helmet>

            {/* Settle modal */}
            <div className="modal fade" id="cuentaspor" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header align-items-center">
                            <div className="fk-sm-card__content">
                                <h5 className="text-capitalize fk-sm-card__title">
                                    Cuentas por Cobrar
                                </h5>
                            </div>
                            <button
                                type="button"
                                className="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handlesaveabono} className="mx-2 sm-text">
                                <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-6 t-mb-15" style={{ textAlign: "left" }}>
                                            <label htmlFor="Total" className="form-label">
                                                {_t(t("Total"))}{" "}
                                            </label>
                                        </div>
                                        <div className="col-6 t-mb-15" style={{ textAlign: "right" }}>
                                            <label htmlFor="t" className="form-label">
                                                {currencySymbolLeft()}
                                                {formatPrice(posabono.total)}
                                                {currencySymbolRight()}
                                            </label>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="col-12 t-mb-15" style={{ textAlign: "left" }}>
                                        <div className="form-group">
                                            <div className="mb-2">
                                                <label className="control-label">
                                                    {_t(t("Abono"))}
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="amount"
                                                name="amount"
                                                type="number"
                                                step="0.01"
                                                min="0"
                                                onChange={handleonchanguedeudapos}
                                                value={posabono && posabono.amount}
                                                placeholder="Numero de Factura"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <button
                                                type="button"
                                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                            >
                                                Cancelar
                        </button>
                                        </div>
                                        <div className="col-6">
                                            <button
                                                type="submit"
                                                className="btn btn-success w-100 xsm-text text-uppercase t-width-max"
                                            >
                                                Guardar
                        </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
            {/* Settle modal Ends*/}

            {/* main body */}
            <main id="main" data-simplebar>
                <div className="container">
                    <div className="row t-mt-10 gx-2">
                        {/* left Sidebar */}
                        <div className="col-lg-3 col-xxl-2 t-mb-30 mb-lg-0">
                            <ReportSidebar />
                        </div>
                        <div className="col-9 t-mb-30 mb-lg-0">
                            {checkOrderDetails.uploading === true || loading === true ? (
                                pageLoading()
                            ) : (
                                <div className="t-bg-white ">
                                    {/* next page data spin loading */}
                                    <div className={`${dataPaginating && "loading"}`}></div>
                                    {/* spin loading ends */}
                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15 t-shadow">
                                        <div className="col-12 t-mb-15">
                                            <ul className="t-list fk-breadcrumb">
                                                <li className="fk-breadcrumb__list">
                                                    <span className="t-link fk-breadcrumb__link text-capitalize">
                                                        {!searchedOrder.searched
                                                            ? _t(t("Estado de Resultado"))
                                                            : _t(t("Search Result"))}
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="row gx-2 mt-2">
                                            <div className="col-12 col-md-2 d-md-block">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control sm-text py-2 t-mb-15 mb-md-0"
                                                    placeholderText={_t(t("From date"))}
                                                    shouldCloseOnSelect={false}
                                                />
                                            </div>
                                            <div className="col-12 col-md-2 t-mb-15 mb-md-0">
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={(date) => setEndDate(date)}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    className="form-control sm-text py-2"
                                                    placeholderText={_t(t("To date"))}
                                                    shouldCloseOnSelect={false}
                                                />
                                            </div>

                                            <div className="col-4 t-mb-15 mb-md-0 d-none d-md-block text-right">
                                                <button
                                                    className="btn btn-block btn-primary text-uppercase sm-text py-2"
                                                    onClick={getdataget2}
                                                >
                                                    {_t(t("Generate Report"))}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                            <div className="col-12 t-mb-15  text-right">
                                                <ReactHTMLTableToExcel
                                                    id="test-table-xls-button"
                                                    className="btn  btn-secondary text-uppercase sm-text"
                                                    table="table-to-xls"
                                                    filename="tablexls"
                                                    sheet="tablexls"
                                                    buttonText="Exportar a XLS" />
                                            </div>

                                        </div>
                                    </div>

                                    <div className="row gx-2 align-items-center t-pt-15 t-pb-15 t-pl-15 t-pr-15">
                                        <div className="col-md-2">
                                        </div>
                                        <div className="col-md-8">
                                            <div className="table-responsive">
                                                <table className="table table-bordered table-hover min-table-height mt-4" id="table-to-xls">
                                                    <thead className="align-middle">
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                style={{ background: "#F64E60", color: "white" }}
                                                            >
                                                                {_t(t("Descripcion"))}
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="sm-text text-capitalize align-middle text-center border-1 border"
                                                                style={{ background: "#F64E60", color: "white" }}
                                                            >
                                                                {_t(t("Valor"))}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="align-middle">

                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border  text-success">Ventas</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center  text-success">
                                                                {currencySymbolLeft()}
                                                                {formatPrice(dataget && dataget.total_ventas)}
                                                                {currencySymbolRight()}
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border  text-success">Abono Clientes</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center  text-success">
                                                                {currencySymbolLeft()}
                                                                {formatPrice(dataget && dataget.total_clientes)}
                                                                {currencySymbolRight()}
                                                            </td>
                                                        </tr>



                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border  text-primary">Compras</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center  text-primary">
                                                                {currencySymbolLeft()}
                                                                {formatPrice(dataget && dataget.total_compras)}
                                                                {currencySymbolRight()}
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border  text-primary">Total Gastos</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center  text-primary">

                                                                {currencySymbolLeft()}
                                                                {formatPrice(dataget && dataget.total_gastos)}
                                                                {currencySymbolRight()}


                                                            </td>
                                                        </tr>
                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border text-primary">Abono Proveedores</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center  text-primary">
                                                                {currencySymbolLeft()}
                                                                {formatPrice(dataget && dataget.total_proveedores)}
                                                                {currencySymbolRight()}
                                                            </td>
                                                        </tr>
                                                      
                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border  text-primary">Desechos</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center text-primary">
                                                                {currencySymbolLeft()}
                                                                {formatPrice(dataget && dataget.total_desperdicios)}
                                                                {currencySymbolRight()}
                                                            </td>
                                                        </tr>
                                                        <tr
                                                            scope="row"
                                                            className="xsm-text align-middle text-center"
                                                        >
                                                            <td className="sm-text text-capitalize align-middle text-left border-1 border  text-secondary">Beneficios</td>
                                                            <td className="xsm-text text-capitalize align-middle text-center  text-primary">
                                                                {currencySymbolLeft()}
                                                                {
                                                                    dataget ?
                                                                        formatPrice(
                                                                            (
                                                                                (dataget.total_ventas +
                                                                                    dataget.total_clientes) -
                                                                                (dataget.total_compras +
                                                                                    dataget.total_desperdicios +
                                                                                    dataget.total_gastos +
                                                                                    dataget.total_proveedores)
                                                                            )
                                                                        ) : 0
                                                                }
                                                                {currencySymbolRight()}


                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        </div>
                                    </div>

                                </div>
                            )}
                            {/* pagination loading effect */}
                            {checkOrderDetails.uploading === true || loading === true
                                ? paginationLoading()
                                : [
                                    // logic === !searched
                                    !searchedOrder.searched ? (
                                        <div key="fragment4">
                                            <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                                <div className="row align-items-center t-pl-15 t-pr-15">
                                                    <div className="col-md-7 t-mb-15 mb-md-0">
                                                        {/* pagination function */}

                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                            <li className="t-list__item">
                                                                <span className="d-inline-block sm-text">
                                                                    {showingData(dataget)}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        // if searched
                                        <div className="t-bg-white mt-1 t-pt-5 t-pb-5">
                                            <div className="row align-items-center t-pl-15 t-pr-15">
                                                <div className="col-md-7 t-mb-15 mb-md-0">
                                                    <ul className="t-list d-flex">
                                                        <li className="t-list__item no-pagination-style">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() =>
                                                                    setSearchedOrder({
                                                                        ...searchedOrder,
                                                                        searched: false,
                                                                    })
                                                                }
                                                            >
                                                                {_t(t("Clear Search"))}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-5">
                                                    <ul className="t-list d-flex justify-content-md-end align-items-center">
                                                        <li className="t-list__item">
                                                            <span className="d-inline-block sm-text">
                                                                {searchedShowingData(
                                                                    searchedOrder,
                                                                    datagetForSearch
                                                                )}
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                ]}
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default EstadoResultado;
